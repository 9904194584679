import React from "react";
import {
  IonAvatar,
  IonLabel,
  IonIcon,
  IonText,
  IonBadge,
  IonItem,
  IonNote
  
  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  timeOutline,
  footballOutline
} from "ionicons/icons";

import MatchPosition from "../Match/MatchPosition";

import voteToColor from "../../helpers/voteToColor";

import { useTranslation } from "react-i18next";

function leagueStatus(t, data) {

  let round = "";
  let rank = "";


  if(data && data.league && data.league.l_1 && data.league.l_1.s_2022) {
    
    if(data.league.l_1.s_2022.groupStage) {
      round = data.league.l_1.s_2022.groupStage.standing.group.replace("Group", "");
      round = t('Group') + " " + round;

      rank = data.league.l_1.s_2022.groupStage.standing.rank;
      
      if(rank === 1) rank = rank + t('st');
      else if(rank === 2) rank = rank + t('nd');
      else if(rank === 3) rank = rank + t('rd');
      else rank = rank + t('th');

      let txt = rank + " " + round
      return txt;
    } else if(data.league.l_1.s_2022.qualified) {
      return "qualified";
    }
    

    //  Group Stage

    
  }

}

function showNote(player, wichNote) {
  var note = 0;
  var totalNote = 0;
  var nbNote = 0;
  if(wichNote === "ratingSorare" && player && player.league && player.league.l_1 && player.league.l_1.s_2022) {
    if(!player.league.l_1.s_2022.ratingSorare) return -1;
    return player.league.l_1.s_2022.ratingSorare.rating.toFixed(1);
  }
  else if(wichNote === "ratingMedias" && player && player.league && player.league.l_1 && player.league.l_1.s_2022) {
    if(!player.league.l_1.s_2022.ratingMedias) return -1;
    return player.league.l_1.s_2022.ratingMedias.rating.toFixed(1);
  }
  else if(wichNote === "ratingAverage" && player && player.league && player.league.l_1 && player.league.l_1.s_2022) {
    if(!player.league.l_1.s_2022.ratingAverage) return -1;
    return player.league.l_1.s_2022.ratingAverage.toFixed(1);
  }
  /* else if(wichNote === "medias") {
    if(!player.mediasRating) return -1;
    if(player.mediasRating.averageRating >= 10) note = 10; else note = player.mediasRating.averageRating.toFixed(1);  
    return note;
  } */
  
}

const PlayerStatus = (player, wichNote) => {


  //if(data && data.league && data.league.l_1 && data.league.l_1.s_2022) {
    //console.log(data.league.l_1.s_2022)

    
    return <>
    {(wichNote === "ratingSorare" || wichNote === "ratingMedias") && player.league.l_1.s_2022[wichNote] && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                 {player.league.l_1.s_2022[wichNote].totalCount}
                </IonText>
                <IonIcon
                  icon={footballOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                
                </IonText>}
    
    {showNote(player, wichNote) !== -1 ? <IonBadge className="square" color={voteToColor(showNote(player, wichNote), 1)}>{showNote(player, wichNote)}</IonBadge> : <IonBadge className="square" color="light">-</IonBadge>}
    </>



  //}

}

const PlayerItem = ({ player, index, showNotes, url, browser, bg, wichNote }) => {
  const { t } = useTranslation();

  return (
          <IonItem routerLink={url} onClick={browser} button>
            {player && player.position && <MatchPosition marginLeft={0} background={player.colors && player.colors.nation && player.colors.nation.color} color={player.colors && player.colors.nation && player.colors.nation.color_text} position={t(player.position)} 
            //background={data.player.color_text} 
            //color={data.player.color} 
            />}

            <IonAvatar slot="start">  
              <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.id+".png"} />
            </IonAvatar>
            <IonLabel>{player.player.name}</IonLabel>
            {PlayerStatus(player, wichNote)}
          </IonItem>
  );
};

export default PlayerItem;
