import React, { useState, useEffect, useRef } from 'react';
import useForm from "../hooks/useForm";

import { doc, getDoc } from "firebase/firestore";
import { collection, query, where, orderBy, getDocs, onSnapshot, getFirestore } from "firebase/firestore";
import firebase from "../firebase";

import { Browser } from '@capacitor/browser';
import UserContext from "../contexts/UserContext";
import {
  IonPage,
  IonContent, IonSkeletonText,
 IonHeader, IonMenuButton, IonBackButton,  
  IonButton, IonToolbar, IonTitle, IonButtons, IonInfiniteScrollContent, IonInfiniteScroll,
  IonItem, IonAvatar, IonItemOptions, IonItemSliding, IonItemOption, 
  IonLabel, IonList, IonSelect, IonSelectOption, IonBadge, IonNote, IonText, IonIcon, useIonToast

} from "@ionic/react";
import {
  personCircleOutline,
  listOutline,
  globe,
  keypadOutline
} from "ionicons/icons";

import { useTranslation } from "react-i18next";
import TeamItem from "../components/Team/TeamItem";
import PlayerMatchsList from "../components/Player/PlayerMatchsList";


const Player = (props) => {
  const { t } = useTranslation();

  const { user } = React.useContext(UserContext);
  const [player, setPlayer] = React.useState(null);

  const [matchs, setMatchs] = React.useState([]);

  const elementRef = React.useRef();

  const playerId = props.match.params.playerId;

  React.useEffect(() => {
  
    const unsubscribe = onSnapshot(doc(getFirestore(), "players", "p_" + playerId), (snapshot) => {
  
        const data = snapshot.data();

        if(data && data.colors) {

          Object.keys(data.colors).map((color, index) => {
      
            if(index===0) {
              const divElement = elementRef.current;
              divElement.style.setProperty('--background', data.colors[color].color);
              divElement.style.setProperty('--color', data.colors[color].color_text);  
            }
            return null;
          });
        }
  
        setPlayer({ ...data, id: snapshot.id });
  
    });

    analytics("player_view", playerId);

    return () => unsubscribe();

  }, [playerId]);

  async function analytics(page, id) {
    try {
      firebase.log(page, id);
    } catch (err) {
      console.error("analytics Error", err);
    }
  }

  return (
    <IonPage>
      <IonHeader >
      <IonToolbar ref={elementRef}>

<IonButtons slot="start">
          <IonBackButton defaultHref="/players" />
            {player && player.player ? <IonAvatar style={{
                    width: "40px",
                    height: "40px",
                    padding: "2px",
                    backgroundColor: "#fff"
                    //paddingRight: "10px",
                  }}> 
                  <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.id+".png"} />
                  </IonAvatar> : <IonAvatar style={{height: "40px",width:"40px"}}><IonSkeletonText animated={true}></IonSkeletonText></IonAvatar> }
        </IonButtons>


        <IonTitle style={{
          paddingInline: "12px"
        }}>
                {player && player.player ? player.player.name : <IonSkeletonText animated={true} style={{width:"100px", height:"20px"}}></IonSkeletonText>} 
                
        </IonTitle>


    <IonButtons slot="end">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
      </IonToolbar>
    </IonHeader>
      <IonContent>

      {/* {team && <IonList lines="none" style={{
      //paddingBottom: 0,
      paddingTop: 0,
    }}>        
      <TeamItem
          showNotes={false}
          //url={`/match/${match.id}`}
          data={team}

        />
        
        </IonList>} */}


<IonList lines="none" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      paddingTop: 0,
    }}>


     {/* <IonToolbar color={"local-team"} className="match-toolbar-3">
      
      <IonTitle>OK</IonTitle> 

      <IonButtons slot="end">
        
          <IonButton 
          fill="clear" 
          //onClick={() => actionLineup()}
          ><IonIcon
          size="large"

            icon={listOutline}
          /></IonButton>
      </IonButtons>

    </IonToolbar> */}

<PlayerMatchsList player_id={playerId}/>

</IonList>
        
      </IonContent>
      
    </IonPage>
  );
};

export default Player;
