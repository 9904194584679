const firebaseConfig = {
  apiKey: "AIzaSyD8XyoSSo_YxOkW7sD2ApZOICI6e_UdXpg",
  authDomain: "mvp-futbol.firebaseapp.com",
  projectId: "mvp-futbol",
  storageBucket: "mvp-futbol.appspot.com",
  messagingSenderId: "874593151602",
  appId: "1:874593151602:web:022e1e588cecdac986f0eb",
  measurementId: "G-WGW24Q60C4",
  databaseURL: "https://mvp-futbol-default-rtdb.europe-west1.firebasedatabase.app",
};

const firebaseConfigjj = {
  apiKey: "AIzaSyDiH5_OH912F3nhUZYJNxcc62OAcXdRU24",
  authDomain: "mvp-futbol-dev.firebaseapp.com",
  projectId: "mvp-futbol-dev",
  storageBucket: "mvp-futbol-dev.appspot.com",
  messagingSenderId: "525205141291",
  appId: "1:525205141291:web:da653f86cf7a6f814ef76e",
  databaseURL: "https://mvp-futbol-dev-default-rtdb.europe-west1.firebasedatabase.app"
};

  /* apiKey: "AIzaSyCI1Hb4wMvlL56WStFECeBDw5fxVugVUyc",
  authDomain: "cdm-2018-f941f.firebaseapp.com",
  databaseURL: "https://cdm-2018-f941f.firebaseio.com",
  projectId: "cdm-2018-f941f",
  storageBucket: "cdm-2018-f941f.appspot.com",
  messagingSenderId: "832080279207",
  appId: "1:832080279207:web:814f698062b6e52c7dcd82" */

export default firebaseConfig;