import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
  IonAvatar,
} from "@ionic/react";

const MatchHeaderColor = ({ teams, goals, colors }) => {
  const elementRefHome = React.useRef();
  const elementRefAway = React.useRef();

  React.useEffect(() => {
    elementRefHome.current.style.setProperty('--background', colors.home.color);
    elementRefHome.current.style.setProperty('--color', colors.home.color_text);

    elementRefAway.current.style.setProperty('--background', colors.away.color);
    elementRefAway.current.style.setProperty('--color', colors.away.color_text);
  }, [colors]);

  return (
    <div style={{
      position: "relative",
     
    }}>
      <IonToolbar ref={elementRefHome} className="match-toolbar-left">

      
          
      
        <IonButtons slot="start">
      
        
        <IonAvatar style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "8px",
                    padding: "2px",
                    backgroundColor: "#fff"
                  }}>
              <img alt="o" src={teams.home.logo} />
            </IonAvatar>
        </IonButtons>
        
        <IonTitle slot="end" style={{
                    paddingInline: "12px",
                   
                  }}>{teams.home.code}</IonTitle>
        <IonButton className="positiondd" color={"light"} slot="end" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      marginRight: "15px",
    }}>{goals.home}</IonButton>

      </IonToolbar>
      <IonToolbar ref={elementRefAway} className="match-toolbar-right">
      
      <IonButton className="positiondd" color={"light"} slot="start" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      marginLeft: "15px",
    }}>{goals.away}</IonButton>

        <IonTitle style={{
                    paddingInline: "12px",
                   
                  }}
        >{teams.away.code}</IonTitle>

        <IonButtons slot="end">
        
        <IonAvatar style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "8px",
                    padding: "2px",
                    backgroundColor: "#fff"
                  }}>
              <img alt="o" src={teams.away.logo} />
            </IonAvatar>
        </IonButtons>
      </IonToolbar>
    </div>
  );
};

export default MatchHeaderColor;
