import React from "react";
import {
  IonPage,
  IonContent,
  IonText,
  IonAvatar,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonRow,
  IonCol,
  IonButton,
  IonGrid,
  useIonToast
} from "@ionic/react";
import SmallHeader from "../../components/Header/SmallHeader";
import LargeHeader from "../../components/Header/LargeHeader";
import firebase from "../../firebase";
import UserContext from "../../contexts/UserContext";
import { personCircleOutline, mailOutline, logoTwitter } from "ionicons/icons";

import { useTranslation } from "react-i18next";


const Profile = (props) => {
  const { t } = useTranslation();

  const { user } = React.useContext(UserContext);

  const [busy, setBusy] = React.useState(false);
  const [showMail, setShowMail] = React.useState(false);

  const [present] = useIonToast();

  React.useEffect(() => {
    firebase.log("profil_view", 0);

    //console.log(user.isAnonymous)

    // eslint-disable-next-line
  }, []);

  React.useEffect( () => () => {
    console.log("unmount")
  }, [] );

  const toast = (message) => {
    present({
      message: message,
      duration: 1500,
      position: 'bottom',
      color: 'secondary'
    });
  }

  async function logoutUser() {
    try {
      await firebase.logout();
      props.history.push("/");
      toast("You have logged out successfully.");
    } catch (err) {
      console.error("Logout Error", err);
      toast(err.message);
    }
  }

  async function authenticateTwitter() {
    setBusy(true);
    //const { email, password } = values;
    try {
      await firebase.loginTwitter();
      toast("Bienvenue!");
      props.history.push("/");
    } catch (err) {
      console.error("Authentication Error", err);
      toast(err.message);
    }
    setBusy(false);
  }

  const signInTwitter = () => {
    /* const provider = new TwitterAuthProvider();

    signInWithPopup(auth, provider)
      .then((res) => {
        props.history.push("/");
      })
      .catch((err) => {
        
      toast(err.message);

        console.log(err)}); */
  };

  return (
    <IonPage >
      <LargeHeader title="MVP Football" backButton={true} />

      <IonContent fullscreen>
        {user && !user.isAnonymous ? (
          <>
            
                <IonList lines="none">
                  <IonItem>
                    <IonIcon icon={personCircleOutline} slot="start"></IonIcon>
                    <IonLabel>
                      <strong>{user.displayName}</strong>
                      <p>{t('Pseudo')}</p>
                    </IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonIcon icon={mailOutline} slot="start"></IonIcon>
                    <IonLabel>
                      <strong>{user.email}</strong>
                      <p>{t('Email')}</p>
                    </IonLabel>
                  </IonItem>
                </IonList>
              
            <IonRow>
                <IonButton
                  routerLink={"/edit-profile"}
                  color="primary"
                  fill="outline"
                >
                  {t('Edit')}
                </IonButton>
            
                <IonButton onClick={logoutUser}>
                {t('Logout')}
                </IonButton>
            </IonRow>
          </>
        ) : (
          <IonRow style={{ marginTop: '120px', flexDirection: 'column', justifyContent: "center" }}>

          {/* <IonAvatar style={{ alignSelf: 'center', width: 150, height: 150 }} >
        <img src="https://api.lorem.space/image/fashion?w=150&h=150" />
      </IonAvatar> */}


      <IonText style={{ fontWeight: "bold", fontSize: 25, alignSelf: 'center' }}>MVP</IonText>
      <IonText style={{ fontWeight: "bold", fontSize: 25, alignSelf: 'center', marginBottom: "80px" }}>FOOTBALL</IonText>
      <IonText style={{ color: 'grey', fontSize: 15, alignSelf: 'center' }}>{t('Log in to rate')}</IonText>



            <IonGrid  style={{ marginTop: '80px'}}>
                <IonRow>
                


                  <IonCol >
                    <IonButton
                      onClick={() => authenticateTwitter()}
                      fill="outline"
                      color="twitter"
                    >
                    <IonIcon color="twitter" icon={logoTwitter} slot="end"></IonIcon>
                      {t('Login with Twitter')}
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol align={"center"}>
                    
                    <IonButton
                      onClick={() => setShowMail(true)}
                      fill="outline"
                      color="light"
                    >
                    <IonIcon color="light" icon={mailOutline} slot="end"></IonIcon>
                      {t('Login with mail')}
                    </IonButton>

                    {showMail && 
              <div>
              <IonButton
                    routerLink={"/register"}
                    color="primary"
                  >
                    {t('Sign up')}
                  </IonButton>


                  <IonButton
                    routerLink={"/login"}
                    color="light"
                    fill="outline"
                  >
                    {t('Login')}
                  </IonButton>
               
              </div>}
                  </IonCol>
                </IonRow>
              </IonGrid>
          



          


          </IonRow>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
