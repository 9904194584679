import React, { useState, useEffect, useRef } from 'react';
import useForm from "../hooks/useForm";

import { doc, getDoc, addDoc, collection, query, where, getDocs, onSnapshot, setDoc, getFirestore } from "firebase/firestore";
import { ref, onValue, runTransaction, update, child, get, getDatabase} from "firebase/database";

import firebase from "../firebase";

import { Browser } from '@capacitor/browser';
import UserContext from "../contexts/UserContext";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonRow,IonModal, IonInput,
  IonCol, IonSlides, IonSlide, IonRefresher, IonRefresherContent,
  IonButton, IonToolbar, IonTitle, IonButtons, IonInfiniteScrollContent, IonInfiniteScroll,
  IonItem, IonAvatar, IonItemOptions, IonItemSliding, IonItemOption, 
  IonLabel, IonList, IonSelect, IonSelectOption, IonBadge, IonNote, IonText, IonIcon, useIonToast

} from "@ionic/react";
import {
  personCircleOutline,
  logoTwitter,
  listOutline,
  globe,
  ribbon,
  paw,
  returnUpForward,
  returnUpBack,
  football,
  tabletPortrait,


  keypadOutline
} from "ionicons/icons";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import moment from 'moment-timezone'
import { useTranslation } from "react-i18next";

import validateUrl from "../validators/validateUrl";


import MatchBookmakers from "../components/Match/MatchBookmakers";

import NavHeader from "../components/Header/NavHeader";
import MatchHeader from "../components/Header/MatchHeader";
import MatchHeader2 from "../components/Header/MatchHeader2";
import MatchHeader3 from "../components/Header/MatchHeader3";


import MatchEvents from "../components/Match/MatchEvents";
import MatchFormation from "../components/Match/MatchFormation";

import pitchImage from "./pitch.svg"
import MatchFormationPlayer from "../components/Match/MatchFormationPlayer";

import { trashOutline } from "ionicons/icons";
import LinkItem from "../components/Link/LinkItem";
import CommentModal from "../components/Match/CommentModal";
import PlayerModal from "../components/Match/PlayerModal";


import LinkComment from "../components/Link/LinkComment";
import MatchItem from "../components/Match/MatchItem";

import providerList from "../helpers/provider";
import formationList from "../helpers/formation";

import voteToColor from "../helpers/voteToColor";


const Social = (props) => {
  const { t, i18n } = useTranslation();

  const { user } = React.useContext(UserContext);
  const [match, setMatch] = React.useState(null);

  const [lineup, setLineup] = React.useState([]);


  const [showModal, setShowModal] = React.useState(false);
  const [dataModal, setDataModal] = React.useState(false);
  const [voteFor, setVoteFor] = React.useState('home');
  const [showVote, setShowVote] = React.useState(false);
  const [wichNote, setWichNote] = React.useState('spectatorsmediasstats');
  const [myVote, setMyVote] = React.useState([]);
  const [voteAS, setVoteAs] = React.useState("");

  const [showMatch, setShowMatch] = React.useState(true);


  const [showLineup, setShowLineup] = React.useState(true);

  
  const elementRef = React.useRef();

  
  const matchId = props.match.params.matchId;

  const linkRef = doc(getFirestore(), "matchs", "m_" + matchId);


  const [present] = useIonToast();


  const hoursToNote = 1500000;
  let formationTime = 0;




  // a ref variable to handle the current slider
  const slider = useRef();
  // a state value to bind segment value
  const [value, setValue] = useState("0");

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: false,
    pagination: {
      el: null
    },
  
  };


  React.useEffect(() => {
  
    const unsubscribe = onSnapshot(doc(getFirestore(), "matchs", "m_" + matchId), (snapshot) => {
  
        const data = snapshot.data();

        if(data.colors && data.colors.away) {
          document.documentElement.style.setProperty("--backgroundVisitorTeam", data.colors.away.color);
          document.documentElement.style.setProperty("--colorVisitorTeam", data.colors.away.color_text);
        }
        if(data.colors && data.colors.home) {
          document.documentElement.style.setProperty("--backgroundLocalTeam", data.colors.home.color);
          document.documentElement.style.setProperty("--colorLocalTeam", data.colors.home.color_text);
        }
  
        setMatch({ ...data, id: data.id });
  
    });


    return () => unsubscribe();
    //return unsubscribe;
    
  }, [matchId]);

  React.useEffect(() => {
    const q = ref(getDatabase(), 'matchs_players/m_' + matchId);

    const unsubscribe = onValue(q, (snapshot) => {

      if(!snapshot.val()) return;

      const allLineup = Object.keys(snapshot.val()).map((doc) => {
        return { id: doc, ...snapshot.val()[doc] };
      });
      setLineup(allLineup);
    });

    return () => unsubscribe();
    //return unsubscribe;
  }, [matchId]);

  React.useEffect(() => {
  i18n.changeLanguage('fr');// en


    // eslint-disable-next-line
  }, [matchId]);

  async function getMatch() {

    onSnapshot(linkRef, (doc) => {

      setMatch({ ...doc.data(), id: doc.id });

  if(doc.data() && doc.data().colors && doc.data().colors.away) {
    document.documentElement.style.setProperty("--backgroundVisitorTeam", doc.data().colors.away.color);
    document.documentElement.style.setProperty("--colorVisitorTeam", doc.data().colors.away.color_text);
    //document.documentElement.style.setProperty("--highlightColorFocused", doc.data().colors.away.color);
  }
  if(doc.data() && doc.data().colors && doc.data().colors.home) {
    document.documentElement.style.setProperty("--backgroundLocalTeam", doc.data().colors.home.color);
    document.documentElement.style.setProperty("--colorLocalTeam", doc.data().colors.home.color_text);
  }


    });

  }

  async function getLineup() {

    const q = ref(getDatabase(), 'matchs_players/m_' + matchId);

    onValue(q, (snapshot) => {
      if(!snapshot.val()) return;

      const allLineup = Object.keys(snapshot.val()).map((doc) => {
        return { id: doc, ...snapshot.val()[doc] };
      });
      setLineup(allLineup);
    });

    return "ref";
  }
  

  function actionLineup() {
    setShowLineup(!showLineup);
  }

  function handleVoteFor(team) {
    setVoteFor(team);
    //const y = elementRef.current.querySelector('.match-toolbar-3').offsetTop;
      //elementRef.current && 
      
      elementRef.current.scrollToPoint(0, 0, 0);
  }

  function handleShowMatch(ev) {
    ev.target.complete();

    setShowMatch(!showMatch)
  }

  

  function handleShowVote() {  
    setShowVote(!showVote);
  }

  

  function handleOpenModal(player) {
    setShowModal(true);
    setDataModal(player)
  }
  
  function handleCloseModal() {
    setShowModal(false);
  }


  

  const handleSelectWichNote = (note) => {

    setWichNote(note.target.value);

  }

  function eventsPlayerList(player) {

    return <>

    {player.events && player.events.subst && player.events.subst
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .map((subst, index) => {
      //return <IonBadge key={index} className="square">ok</IonBadge>
      return <IonIcon key={index}
                        icon={subst.player.id === player.player.id ? returnUpBack : returnUpForward}
                        color={subst.player.id === player.player.id ? "danger" : "success"}
                        style={{
                          marginLeft: "6px",
                          //verticalAlign: "middle",
                        }}
                      />
      })
      
      
      }  
      
      
      {player.events && player.events.Card && player.events.Card
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .map((card, index) => {
      //return <IonBadge key={index} className="square">ok</IonBadge>
      if(card.detail === 'Yellow Card')
      return <IonIcon 
                        icon={tabletPortrait}
                        color={"jaune"}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                        }}
                      />
                      
      if(card.detail === 'Second Yellow card')
      return <IonIcon key={index}
                        icon={tabletPortrait}
                        color={"jaune"}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                        }}
                        
                      />
      if(card.detail === 'Red Card')
      return <IonIcon key={index}
                        icon={tabletPortrait}
                        color={"rouge"}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                        }}
                        
                      />
      })
      
      
      }  
      
      {player.events && player.events.Goal && player.events.Goal.filter((d) => d.detail === "Own Goal" || d.detail === "Normal Goal" || d.detail === "Penalty").length > 0 && <IonText style={{
                          //color: "#777777",
                          marginLeft: "6px",
                        }}><IonText
                        //size={"small"}
                        style={{
                          verticalAlign: "middle",
                          paddingRight: "1px",
                        }}
                      >
                      {player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length > 1 && player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length}
                      </IonText>
                      <IonIcon 
                        icon={football}
                        color={player.events.Goal.filter((d) => d.detail === "Own Goal").length > 0 ? "danger" : player.events.Goal.filter((d) => d.detail === "Penalty").length > 0 && "warning"}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      </IonText>}
      
      {player.events && player.events.Assist && player.events.Assist.length > 0 && <IonText style={{
                          //color: "#777777",
                          marginLeft: "6px",
                        }}><IonText
                        //size={"small"}
                        style={{
                          verticalAlign: "middle",
                          paddingRight: "1px",
                        }}
                      >
                      {player.events.Assist.length > 1 && player.events.Assist.length}
                      </IonText>
                      <IonIcon 
                        icon={paw}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      </IonText>}
      
      
      
      {/* {player.events && player.events.Assist.length > 1 && <IonIcon key={index}
                        icon={ribbon}
                        color={"jaune"}
                        style={{
                          marginLeft: "6px",
                          //verticalAlign: "middle",
      
                        }}
                      />
      
      
      
      }   */}
      </>

  }

  

  


  function showNote(player) {
    var note = 0;
    var totalNote = 0;
    var nbNote = 0;
    if(wichNote === "spectators" && player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
      if(player.usersRating.averageRating >= 10) note = 10; else note = player.usersRating.averageRating.toFixed(1);  
      return note;
    }
    else if(wichNote === "medias" && player.mediasRating) {
      if(player.mediasRating.averageRating >= 10) note = 10; else note = player.mediasRating.averageRating.toFixed(1);  
      return note;
    }
    else if(wichNote === "spectatorsmediasstats") {

      if(player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
        totalNote = totalNote + player.usersRating.averageRating;
        nbNote++;
      }
      if(player.mediasRating && (player.mediasRating.averageRating || player.mediasRating.averageRating === 0)) {
        totalNote = totalNote + player.mediasRating.averageRating;
        nbNote++;
      }
      if(player.stats && (player.stats["sorare"] || player.stats["sorare"].note ===0 )) {
        totalNote = totalNote + player.stats["sorare"].note;
        nbNote++;
      }

      if(nbNote === 0) return "-";

      if((totalNote / nbNote).toFixed(1) >= 10) note = 10; else note = (totalNote / nbNote).toFixed(1);  

      return note;

    }
    else if(wichNote === "mynotes" && myVote[player.player_id]) return myVote[player.player_id].note;
    else if(wichNote === "stats") {
      if(!player.stats) return -1; // && player.stats["sorare"] 
      else return player.stats["sorare"].note * 10;
    }
    else if (player.medias && player.medias[wichNote]) return player.medias[wichNote].note;
    else return "-";

  }

  const loadData = (ev) => {
    

    setTimeout(() => {
     // console.log(voteFor);
    setVoteFor(voteFor === 'home' ? 'visitor' : 'home')

      ev.target.complete();

      const y = elementRef.current.querySelector('.match-toolbar-3').offsetTop;
      elementRef.current && elementRef.current.scrollToPoint(0, y-8, 500);
      
    }, 1000);
  }  

  function teamLineupFormation() {
    formationTime = match.formations.home[formationTime] ? formationTime : 5;

    return <>
    <div className={`pitch f_${voteFor === 'home' && match.formations ? match.formations.home[formationTime] : match.formations.away[formationTime]}`}>
        {
        //Object.keys(match.lineup)
        lineup
        .filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )

        .sort((a, b) => a.formation[formationTime].formation_position > b.formation[formationTime].formation_position ? 1 : -1)
        .slice(0, 11)

        .map((player, index) => {
  
          //player = match.lineup[player]
          const customAlertOptions = {
            header: player.player.player_name,
            subHeader: t('Your_rating_of_his_match'),
            //message: 'Choose only one',
            translucent: false
          };
  
          var DoShowVote = false;
  
  
  
  
          return <IonItem key={index} className={"g_" + player.formation[formationTime].formation} button={(!DoShowVote || myVote[player.player_id]) && voteAS === ""} onClick={() => (!DoShowVote || myVote[player.player_id]) && voteAS === "" && handleOpenModal(player)}>
    <div className="pitch-player">
    <IonAvatar style={{
        width: "40px",
        height: "40px",
        fontSize: "14px",
        margin: "auto",
      }} >

  
  
{player.events && player.events.subst && player.events.subst
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((subst, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonIcon key={index}
                  //icon={subst.player.id === player.player.id ? returnUpBack : returnUpForward}
                  //color={subst.player.id === player.player.id ? "danger" : "success"}
                  icon={returnUpBack}
                  color={"danger"}
                  style={{
                    position: "absolute",
                    top: "-4px",
                    left: "18px",
                  }}
                />
})


}  


{player.events && player.events.Card && player.events.Card
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((card, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
if(card.detail === 'Yellow Card')
return <IonIcon key={index}
                  icon={tabletPortrait}
                  color={"jaune"}
                  style={{
                    position: "absolute",
                    top: "22px",
                    left: "17px",
                  }}
                />
if(card.detail === 'Second Yellow card')
return <IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "18px",
                  }}
                />

if(card.detail === 'Red Card')
return <IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "18px",
                  }}
                />
})



}  

{player.events && player.events.Goal && player.events.Goal.filter((d) => d.detail === "Own Goal" || d.detail === "Normal Goal" || d.detail === "Penalty").length > 0 && <IonText style={{
                    position: "absolute",
                    top: "-4px",
                    left: "70px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "1px",
                  }}
                >
                {player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length > 1 && player.events.Goal.filter((d) => d.detail === "Normal Goal").length}
                </IonText>
                <IonIcon 
                  key={index}
                  color={player.events.Goal.filter((d) => d.detail === "Own Goal").length > 0 ? "danger" : player.events.Goal.filter((d) => d.detail === "Penalty").length > 0 && "warning"}
                  icon={football}
                  style={{
                    verticalAlign: "middle",
                  }}
                />
                </IonText>}




{player.events && player.events.Assist && player.events.Assist.length > 0 && 
  <IonText style={{
                    position: "absolute",
                    top: "22px",
                    left: "72px",
                  }}>
                  {player.events.Assist.length > 1 && player.events.Assist.length}
                  
                  <IonIcon key={index}
                  icon={paw}
                  color={"mvp"}
                  style={{
                    //verticalAlign: "middle",
                    //marginRight: 0,
                    //paddingRight: 0,
                  }}
                /></IonText>}

{/* <img alt="o" src={player.img} />
 */}
{/* {player.events && player.events.Assist && player.events.Assist
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((player, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonIcon key={index}
                  icon={ribbon}
                  color={"jaune"}
                  style={{
                    position: "absolute",
                    top: "-13px",
                    left: "44px",
                  }}
                />
})}   */}

{(voteAS === "" && !DoShowVote || myVote[player.player_id]) && showNote(player) >= 0 ? <IonBadge className="round" color={voteToColor(showNote(player), wichNote === "stats" ? 10 : 1)}>{showNote(player)}</IonBadge> : <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.image+".png"} />}
{/* {!showNote(player) && !DoShowVote && <img alt="o" src={"https://media.api-sports.io/football/players/"+player.image+".png"} />}
 */}      </IonAvatar>
      <IonLabel>{player.player.player_name}</IonLabel>
      
    </div>
  </IonItem>
        })}
        <img className="Outlines" src={pitchImage} alt="Pitch outlines"/>
  
      </div>
  </>
  }

  function teamLineupListAll() {
    formationTime = match.formations.home[formationTime] ? formationTime : 5;

    return <IonList lines={"full"}  style={{paddingTop: 0}}>
    
    {lineup
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .sort((a, b) => b.player.player_name.localeCompare(a.player.player_name))
      .sort((a, b) => showNote(a) < showNote(b) ? 1 : -1)
      .map((player, index) => {
        var DoShowVote = showVote;

        if(match && match.fixture && match.fixture.status.elapsed === 90 && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()) {} else DoShowVote = false;

        //player = match.lineup[player]
        const customAlertOptions = {
          header: player.player.player_name,
          subHeader: t('Your rating of his match?'),
          //message: 'Choose only one',
          translucent: false
        };


        return (
  <div key={index}>
    
              <IonItem id={"i"+index} style={{
                    //"--border-width": index === 0 && 0,
                  }}
                  className={match.localteam_id  === player.team_id ? "local" : "visitor"} button={!DoShowVote || myVote[player.player_id]} onClick={() => (!DoShowVote || myVote[player.player_id]) && handleOpenModal(player)}>
                
              <IonBadge className="position" color={match.localteam_id  === player.team_id ? "local-team" : "visitor-team"} slot="start">{t(player.formation[formationTime].name)}</IonBadge>

                <IonAvatar slot="start">
                  <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.image+".png"} />
                </IonAvatar>
                <IonLabel>{player.player.player_name}{eventsPlayerList(player)}</IonLabel>
                           
 
                {(!DoShowVote || myVote[player.player_id]) && wichNote === "spectators" && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                 {player.usersRating ? player.usersRating.totalUsersCount : 0}
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}

                {(!DoShowVote || myVote[player.player_id]) && wichNote === "medias" && player.mediasRating && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.mediasRating.totalUsersCount || 0} 
                </IonText>
                <IonIcon
                  icon={globe}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}
                

                {(voteAS === "" && !DoShowVote || myVote[player.player_id]) && showNote(player) >=0 && <IonBadge className="square" color={voteToColor(showNote(player), wichNote === "stats" ? 10 : 1)}>{showNote(player)}</IonBadge>}
                {(voteAS === "" && !DoShowVote || myVote[player.player_id]) && showNote(player)==="-" && <IonBadge className="square" color={"light"}>-</IonBadge>}

                
  
                  
              </IonItem>

              {/* {index === 0 && <IonItem>
              <IonLabel>mvp</IonLabel>

              </IonItem>} */}

</div>
    
            )})}

  </IonList>


  }

  function itemSelect() {

    var DoShowVote = false;
    

    return DoShowVote && Object.keys(myVote).length === 0 ? <IonItem button={true} onClick={() => handleShowVote()} style={{
      //position: "absolute",
      top: 0,
      right: 0
    }}>
              <IonLabel className="ion-text-wrap">
                {/* <h2>{t('MATCH_')}</h2> */}
                <p>{t('match_not_see')}</p>
              </IonLabel>
              
              <IonButton
                
                fill="outline"
                color="light"
                slot="end"
              >
              {/* <IonIcon style={{
                  '--height': "60px",
                }} color="light" icon={trendingUpOutline} slot="end"></IonIcon> */}
                {t('match_not_see_button')}
              </IonButton>
            </IonItem> : <IonItem style={{
      //position: "absolute",
      top: 0,
      right: 0
    }}>
              <IonLabel className="ion-text-wrap">
                {/* <h2>{t('MATCH_')}</h2> */}
                <p>{t('match_select_who')}</p>
              </IonLabel>
              <IonSelect defaultValue="spectators" value={wichNote} color="danger" mode="ios" interfaceOptions={customPopoverOptions} interface="action-sheet" cancelText={t('Cancel')}
              style={{
      maxWidth: "80%",
    }}
                onIonChange={(e) => handleSelectWichNote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  {user && <IonSelectOption value="mynotes">{t('mynotes')}</IonSelectOption>}
                  <IonSelectOption value="spectatorsmediasstats">{t('spectatorsmediasstats')}</IonSelectOption>
                  <IonSelectOption value="spectators">{t('Spectators')}</IonSelectOption>
                  <IonSelectOption value="medias">{t('Medias')}</IonSelectOption> 
                  <IonSelectOption value="stats">{t('Stats_Sorare')}</IonSelectOption> 
                    

                  {match && match.medias && Object.keys(match.medias)
      //.sort((a, b) => match.lineup[a].formation_position > match.lineup[b].formation_position ? 1 : -1)
      .map((provider, index) => {
        return <IonSelectOption key={index} value={provider}>{providerList[provider] ? providerList[provider].name : provider}</IonSelectOption>
      })}
                  
          
                </IonSelect>
            </IonItem>
  }

  const customPopoverOptions = {
    header: t('See'),
    //subHeader: 'Select your hair color',
    message: t('Rate')
  };

  //if(match) formationTime = match.formations.home[formationTime] ? formationTime : 5;




  return (
    <IonPage>
    {!showLineup && match && <MatchHeader2 
    teamHome={match.teams.home} 
    teamVisitor={match.teams.away}
    goalHome={match.goals.home} 
    goalVisitor={match.goals.away}
     />}
      <IonContent ref={elementRef}  >


      {showLineup && <IonList lines="none" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      paddingTop: 0,
    }}>


     {match && <IonToolbar color={voteFor === 'home' ? "local-team" : "visitor-team"} className="match-toolbar-3">
      
     <IonButtons slot="start">
      
        
      <IonAvatar style={{
                  width: "40px",
                  height: "40px",
                  marginLeft: "12px",
                  padding: "2px",
                  backgroundColor: "#fff"
                }}>
            <img alt="o" src={voteFor === 'home' && match.teams ? match.teams.home.logo : match.teams.away.logo} />
          </IonAvatar>
      </IonButtons>
      <IonTitle style={{
                  
                  paddingInline: "12px",
                }}>
      {voteFor === 'home' && match.teams && match.teams.away ? match.teams.home.name : match.teams.away.name}
      {voteFor === 'visitor' && match.formations && match.formations.away && " - " + match.formations.away[match.formations.home[formationTime] ? formationTime : 5]}
      {voteFor === 'home' && match.formations && match.formations.home && " - " + match.formations.home[match.formations.home[formationTime] ? formationTime : 5]}
      </IonTitle> 

     
      

    </IonToolbar>}

{match && lineup.length > 0 &&  match.formations && teamLineupFormation()}
{match && lineup.length > 0 && itemSelect()}

<div style={{marginTop: "100px"}}>
<IonButton
                onClick={() => handleVoteFor("visitor")}
                className="visitor"
                
                style={{
                  "--background": "#262626",
                  borderWidth: 0,
                  boxShadow: "none",
                  
                }}
              ></IonButton>

<IonButton
                onClick={() => {
                  setWichNote("stats");
      elementRef.current.scrollToPoint(0, 0, 0);
                }}
                className="sorare"
                
                style={{
                  "--background": "#262626",
                  borderWidth: 0,
                  boxShadow: "none",
                  
                }}
              ></IonButton>

<IonButton
                onClick={() => actionLineup()}
                className="formation"
                
                style={{
                  "--background": "#262626",
                  borderWidth: 0,
                  boxShadow: "none",
                  
                }}
              ></IonButton>
</div>
</IonList>}

{/* {!showLineup && match && match.events && <MatchEvents localteam_id={match.localteam_id} data={match.events} />}
 */}
{!showLineup && <IonList lines="none" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      paddingTop: 0,
    }}>

    

{match && lineup.length > 0 && teamLineupListAll(lineup)}
{match && lineup.length > 0 && itemSelect()}

</IonList>}


<PlayerModal
          isOpen={showModal}
          data={dataModal}
          showNoteSelect={false}
          isComponent={true}
          teamHome={match && match.localteam_id}
          closeAction={handleCloseModal}
        />
        
        
      </IonContent>

      
    </IonPage>
  );
};

export default Social;
