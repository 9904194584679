import React from "react";
import PlayerMatchItem from "../Player/PlayerMatchItem";
import MatchHeaderColor from "../Match/MatchHeaderColor";
import { useTranslation } from "react-i18next";

import { collection, query, where, limit, orderBy, onSnapshot, getFirestore } from "firebase/firestore";

import {
  IonList,
  IonSkeletonText, IonSelect, IonSelectOption,
  IonAvatar, IonItem, IonLabel, IonBadge
} from "@ionic/react";
const PlayerMatchsList = ({player_id}) => {
  const [matchs, setMatchs] = React.useState(null);
  const [wichNote, setWichNote] = React.useState('spectatorsmediasstats');

  const { t } = useTranslation();


  React.useEffect(() => {
    const q = query(collection(getFirestore(), "matchs_players"), 
      orderBy("fixture.date", "desc"), 
      where('player_id', '==', "p_" + player_id),
      where('league_id', '==', "l_1"),
      where('season_id', '==', "s_2022"),
      limit(50)
      );


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const matchsList = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      /* matchsList
      .sort((a, b) => a.league.l_1.s_2022[wichNote].rating > b.league.l_1.s_2022[wichNote].rating ? 1 : -1)
      .reverse(); */
      
      setMatchs(matchsList);
    });

    return () => unsubscribe();
    //return unsubscribe;
    // eslint-disable-next-line
  }, [player_id]);

  function itemSelect() {

    const customPopoverOptions = {
      header: t('See'),
      //subHeader: 'Select your hair color',
      message: t('Rate')
    };

    return <IonItem style={{
      //position: "absolute",
      
    }}>
              <IonLabel className="ion-text-wrap">
                {/* <h2>{t('MATCH_')}</h2> */}
                <p>{t('match_select_who')}</p>
              </IonLabel>
              <IonSelect defaultValue="spectators" value={wichNote} color="danger" mode="ios" interfaceOptions={customPopoverOptions} interface="action-sheet" cancelText={t('Cancel')}
              style={{
      maxWidth: "80%",
    }}
                //onIonChange={(e) => handleSelectWichNote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="spectatorsmediasstats">{t('spectatorsmediasstats')}</IonSelectOption>
                  <IonSelectOption value="spectators">{t('Spectators')}</IonSelectOption>
                  <IonSelectOption value="medias">{t('Medias')}</IonSelectOption> 
                  <IonSelectOption value="stats">{t('Stats_Sorare')}</IonSelectOption> 
                    
                  
          
                </IonSelect>
            </IonItem>
  }

  function renderItemSelectSkeleton() {
    return <IonItem>
    
    <IonLabel className="ion-text-wrap">
    <p><IonSkeletonText animated={true} style={{width:"80px"}}></IonSkeletonText></p>
    </IonLabel>

    <IonSelect mode="ios" 
    interface="action-sheet"
              style={{
      maxWidth: "80%",
    }}>
    
    <IonSkeletonText animated={true}></IonSkeletonText>
             
    </IonSelect>
  </IonItem>
  }

  function renderSkeleton() {
    return <IonItem>
    <IonAvatar slot="start">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
    <IonLabel>
        <IonSkeletonText animated={true} style={{ 'height': '14px', 'width': '130px' }} ></IonSkeletonText>
        {/* <p>
        <IonSkeletonText animated={true} style={{ 'width': '130px' }} ></IonSkeletonText>
        </p> */}
    </IonLabel>
    <IonSkeletonText style={{ 'height': '32px', 'width': '32px'}} animated={true} ></IonSkeletonText>
    </IonItem>
  }

  return (
    <>   
{/*         {matchs && matchs.length > 0 ? itemSelect() : renderItemSelectSkeleton()}
 */}     
    {matchs && matchs.length > 0 && <IonList lines="full" style={{paddingTop: 0}}>

      {matchs.map((match, index) => (
        <div key={match.fixture.id} style={{paddingBottom: "30px"}}>

        <MatchHeaderColor colors={match.colors} teams={match.teams} goals={match.goals}  />
        <PlayerMatchItem
          data={match}
          showNoteSelect={true}
          //myVote={myVote}
          teamHome={match && match.localteam_id}
          medias={match && match.medias}
          //sendAction={handleAddComment}
          //sendNote={handleSendNote}
          //closeAction={handleCloseModal}
          closeNote={true}
        />
        </div>
        
      ))} 
    </IonList>}

    {!matchs &&
        <IonList lines="full" style={{paddingTop: 0}}>
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
        </IonList>
      }

      {matchs && matchs.length === 0 &&
        <IonList lines="full" style={{paddingTop: 0}}>
         
        </IonList>
      }

    </>
  );
};

export default PlayerMatchsList;
