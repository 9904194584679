import React from "react";
import {
  IonBadge,
  IonLabel,
  IonButton,
  IonText,
  IonItem,
  IonList,
  IonItemDivider,
  IonItemGroup,
IonCol, IonRow, IonGrid,

  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  chevronUpCircleOutline,
  personCircleOutline,
  timeOutline,
} from "ionicons/icons";

import { useTranslation } from "react-i18next";


const SorareScoreDetailed = ({ data, template, col, onClick }) => {

  const { t } = useTranslation();

  function handleClick(e) {
    onClick(e.detail.value);
  }

let category = "";
let categoryDivider = null;

const categoryOrder = {
  "GENERAL": 0,
  "GOALKEEPING": 1,
  "DEFENDING": 2,
  "POSSESSION": 3,
  "PASSING": 4,
  "ATTACKING": 5,
  "UNKNOWN": 6,       
};

const statDontShow = {
  "level_score": 1,
  "was_fouled": 1,
  "successful_final_third_passes": 2,
  //"long_pass_own_to_opp_success": 3,
  
  "clean_sheet_60": 4,
  "goals_conceded": 4,
  "goals_conceded": 4,
  "goals_conceded": 4,
  "double_double": 5,
  "triple_double": 6,       
  "triple_triple": 6,       
};

  return (

        <>

{data && template === "small" && <IonGrid style={{paddingInline: 0}} fixed={false}>

<IonRow>
{data.detailedScore && data.detailedScore
.filter((d) => d.totalScore && !statDontShow[d.stat] )
.sort((a, b) => a.totalScore < b.totalScore  ? 1 : -1)
//.slice(0, 6)
.map((score, index) => {

return <IonCol key={index} size={col} style={{
         paddingInline: 0,
         padding: 0,
        }}>
          <IonItem lines={"none"} button onClick={handleClick}>
          {score.statValue ? <IonBadge className="square" slot="start"
                color={score.totalScore < 0 ? "rouge" : "vert"}
                style={{
                    marginInlineEnd: "12px"

                  }}>{score.statValue}</IonBadge> : null }
          <IonLabel>
            {t(score.stat)}
          </IonLabel>

          </IonItem>
  </IonCol>  
})


} 

</IonRow></IonGrid>}

{data && template === "normal" && <IonList lines={"full"} style={{
     //paddingTop: 0
   }}><IonItemGroup>

{data.detailedScore && data.detailedScore
.filter((d) => d.totalScore )
.sort((a, b) => categoryOrder[a.category] > categoryOrder[b.category]  ? 1 : -1)

.map((score, index) => {

if(score.category !== category) categoryDivider = <IonItemDivider>
      <ion-label>
       {t(score.category)}
      </ion-label>
    </IonItemDivider>; else categoryDivider = null;
//return <IonBadge key={index} className="square">ok</IonBadge>

category = score.category;

return <div key={index}>
{categoryDivider}

<IonItem>
          
            
          <IonLabel>
            {t(score.stat)}
          </IonLabel>

          <IonText style={{
                    //color: "#777777",
                    //fontSize: "16px",
                    verticalAlign: "middle",
                    paddingRight: "16px",
                    color: score.totalScore < 0 ? "#ff0000" : "#35b827"

                  }}>
                {score.totalScore > 0 && "+"}{score.totalScore}
                </IonText>
                
                
                {score.statValue ? <IonBadge className="square" slot="start"
                color={score.totalScore < 0 ? "rouge" : "vert"}
                style={{
                    marginInlineEnd: "12px"

                  }}>{score.statValue}</IonBadge> : null }
                  
                  

        </IonItem>
        </div>
})


}

<IonItem>
          
            
          <IonLabel>
            {t("Sorare score")}
          </IonLabel>

                
                
                {data.score ? <IonBadge className="square" slot="end"
                style={{
                    marginInlineEnd: "12px"

                  }}>{data.score}</IonBadge> : null }
                  
                  

        </IonItem>
</IonItemGroup>
</IonList>}

      

        </>

  );
};

export default SorareScoreDetailed;
