import React from "react";
import {
  IonAvatar,
  IonLabel,
  IonIcon,
  IonButton,
  IonText,
  IonItem,
  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  timeOutline,
} from "ionicons/icons";

import { useTranslation } from "react-i18next";


function leagueStatus(t, data, league) {

  let round = "";
  let rank = "";
  let txt = "";

  const parts = league.split('.');

const l = parts[0];
const s = parts[1]; 

if(!data.league[l]) return;
if(!data.league[l][s]) return;

  if(data && data.league && data.league[l] && data.league[l][s]) {

    if(data.league[l][s].roundStage) {

      round = data.league[l][s].roundStage.round;
      //status = data.league[l][s].roundStage.status;
      if(data.league[l][s].roundStage.status === "qualified")
      return t('Win vs') + " " + data.league[l][s].roundStage.team + " " + data.league[l][s].roundStage.score;
      else  
      return t('Lost vs') + " " + data.league[l][s].roundStage.team + " " + data.league[l][s].roundStage.score;

    }
    if(data.league[l][s].groupStage) {
      round = data.league[l][s].groupStage.standing.group.replace("Group", "");
      round = t('group') + " " + round;

      rank = data.league[l][s].groupStage.standing.rank;
      
      if(rank === 1) rank = rank + t('st');
      else if(rank === 2) rank = rank + t('nd');
      else if(rank === 3) rank = rank + t('rd');
      else rank = rank + t('th');

      if(data.league[l][s].groupStage.standing.all.played === 0) txt = round; 
      else txt = rank + " "+t('of')+" " + round; 

      if(data.league[l][s].groupStage.standing.group === "Ranking of third-placed teams" ) txt = rank + " " + t('best_third_placed');


      return txt;
    } else if(data.league[l][s].qualified) {
      return "qualified";
    }
  }
}

function leagueStage (t, data, league) {

  let round = "";
  let rank = "";
  let txt = "";
  let status = null;


const parts = league.split('.');

const l = parts[0];
const s = parts[1]; 
if(!data.league[l]) return;
if(!data.league[l][s]) return;


  if(data && data.league && data.league[l] && data.league[l][s]) {
    
    if(data.league[l][s].roundStage) {

      round = data.league[l][s].roundStage.round;
      status = data.league[l][s].roundStage.status;

      if(round === "16") round = 8;
      if(round === "Quarter-finals") round = 4;

      return status==="qualified" ? <IonButton
        fill="outline"
        slot="end"
        color={"success"}
        style={{height: "40px",}}
      ><div style={{lineHeight: "16px"}}>{t('Qualified')}<br/>{t('in')} {t(round+'finals')}</div></IonButton> : <IonButton
        fill="outline"
        slot="end"
        color={"danger"}
        style={{height: "40px"}}
      ><div style={{lineHeight: "16px"}}>{t('Disqualified')}<br/>{t('in')} {t(round+'finals')}</div></IonButton>
    }
    if(data.league[l][s].groupStage) {

      status = data.league[l][s].groupStage.status;

      return status==="qualified" ? <IonButton
        fill="outline"
        slot="end"
        color={"success"}
        style={{height: "40px",}}
      ><div style={{lineHeight: "16px"}}>{t('Qualified')}<br/>{t('in')} {t('8finals')}</div></IonButton> : <IonButton
        fill="outline"
        slot="end"
        color={"danger"}
        style={{height: "40px"}}
      ><div style={{lineHeight: "16px"}}>{t('Disqualified')}<br/>{t('in group')}</div></IonButton>
    } 
    
    else if(data.league[l][s].qualified) {
      txt= "qualified";
    }
    
    //  Group Stage

    
  }

}

const TeamItem = ({ data, index, showNotes, url, browser, color, league }) => {
  const { t } = useTranslation();

  const elementRef = React.useRef();

  React.useEffect(() => {

        if(color && data && data.colors) {

          Object.keys(data.colors).map((color, index) => {
      
            if(index===0) {
              const divElement = elementRef.current;
              divElement.style.setProperty('--background', data.colors[color].color);
              divElement.style.setProperty('--color', data.colors[color].color_text);  
            }
            return null;
          });
        }
        
  }, [data]);

  return (

        <>
          <IonItem routerLink={url} onClick={browser} button ref={elementRef}>
            <IonAvatar slot="start">
              <img alt="o" src={data.team.logo} />
            </IonAvatar>
            
            <IonLabel>
  

              <div className=" ion-text-wrap">
                <strong style={{ fontSize: "1rem" }}>{data.team.name}</strong>
              </div>

              <p
    style={{
      alignItems: "center",
      fontSize: "0.8 rem",
      fontWeight: "normal",
      marginTop: "2px",
      marginBottom: 0
    }}
  >

    <IonText
      style={{
        verticalAlign: "middle",
      }}
    >
      { leagueStatus(t, data, league) }

    </IonText>

    
  </p>



            </IonLabel>

            {leagueStage(t, data, league) }


          </IonItem>


        </>

  );
};

export default TeamItem;
