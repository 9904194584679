import React from "react";
import { IonPage, IonContent, IonFooter, IonButton, IonIcon, IonGrid, IonCol, IonRow } from "@ionic/react";
import {
  logoTwitter,
  logoDiscord
} from "ionicons/icons";

import { Browser } from '@capacitor/browser';

import SmallHeader from "../../components/Header/SmallHeader";
//import LargeHeader from "../../components/Header/LargeHeader";
import TeamMatchsList from "../../components/Team/TeamMatchsList";



import { useTranslation } from "react-i18next";


const Matchs = (props) => {
  const { t } = useTranslation();

  const [tab, setTab] = React.useState("notes");


  function handleChangeTab(tab) {
    setTab(tab);
  }

  function shareOnTwitter() {
    //setTab(tab);
  }

  async function openBrowser(url) {
    await Browser.open({
      url: url,
    });
  }

  return (
    <IonPage>
      <SmallHeader title={t('To_come')} changeTab={handleChangeTab} />
      <IonContent fullscreen>

        <TeamMatchsList tab={tab} location={props.location} />


      </IonContent>
      
    </IonPage>
  );
};

export default Matchs;
