import React, { useState, useEffect, useRef } from 'react';
import useForm from "../hooks/useForm";

import { doc, getDoc, addDoc, collection, query, where, getDocs, onSnapshot, getFirestore, updateDoc, setDoc } from "firebase/firestore";
import { ref, onValue, runTransaction, update, child, get, getDatabase} from "firebase/database";

import firebase from "../firebase";

import { Browser } from '@capacitor/browser';
import UserContext from "../contexts/UserContext";
import {
  IonPage,
  IonContent,
  IonSkeletonText, IonItemDivider, 
  IonRow,IonModal, IonInput,
  IonCol, IonSlides, IonSlide, IonRefresher, IonRefresherContent,
  IonButton, IonToolbar, IonTitle, IonButtons, IonInfiniteScrollContent, IonInfiniteScroll,
  IonItem, IonAvatar, IonItemOptions, IonItemSliding, IonItemOption, 
  IonLabel, IonList, IonSelect, IonSelectOption, IonBadge, IonNote, IonText, IonIcon, useIonToast

} from "@ionic/react";
import {
  personCircleOutline,
  logoTwitter,
  listOutline,
  globe,
  ribbon,
  paw,

  returnUpBack,
  returnUpForward,

  
  football,
  tabletPortrait,


  keypadOutline
} from "ionicons/icons";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import moment from 'moment-timezone'
import { useTranslation } from "react-i18next";

import validateUrl from "../validators/validateUrl";


import MatchBookmakers from "../components/Match/MatchBookmakers";

import NavHeader from "../components/Header/NavHeader";
import MatchHeader from "../components/Header/MatchHeader";
import MatchHeader2 from "../components/Header/MatchHeader2";
import MatchHeader3 from "../components/Header/MatchHeader3";

import MatchFormation from "../components/Match/MatchFormation";

import pitchImage from "./pitch.svg"
import MatchFormationPlayer from "../components/Match/MatchFormationPlayer";

import { trashOutline } from "ionicons/icons";
import LinkItem from "../components/Link/LinkItem";
import CommentModal from "../components/Match/CommentModal";
import PlayerModal from "../components/Match/PlayerModal";


import LinkComment from "../components/Link/LinkComment";
import MatchItem from "../components/Match/MatchItem";

import providerList from "../helpers/provider";
import formationList from "../helpers/formation";

import voteToColor from "../helpers/voteToColor";


const Match = (props) => {
  const { t } = useTranslation();

  const { user } = React.useContext(UserContext);
  const [match, setMatch] = React.useState(null);

  const [lineup, setLineup] = React.useState(null);


  const [showModal, setShowModal] = React.useState(false);
  const [dataModal, setDataModal] = React.useState(false);
  const [voteFor, setVoteFor] = React.useState('home');
  const [showVote, setShowVote] = React.useState(false);
  const [wichNote, setWichNote] = React.useState('spectatorsmediasstats');
  const [myVote, setMyVote] = React.useState([]);
  const [voteAS, setVoteAs] = React.useState("");

  const [showMatch, setShowMatch] = React.useState(true);


  const [showLineup, setShowLineup] = React.useState(true);


  const [formationTime, setFormationTime] = React.useState(0);

  
  const elementRef = React.useRef();
 
  
  const matchId = props.match.params.matchId;

  const matchRef = doc(getFirestore(), "matchs", "m_" + matchId);


  const [present] = useIonToast();


  const hoursToNote = 15;
  //let formationTime = 0;


  // a ref variable to handle the current slider
  const slider = useRef();
  // a state value to bind segment value
  const [value, setValue] = useState("0");

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: false,
    pagination: {
      el: null
    },
  
  };



  React.useEffect(() => {
  
    const unsubscribe = onSnapshot(doc(getFirestore(), "matchs", "m_" + matchId), (snapshot) => {
  
        const data = snapshot.data();

        if(data.colors && data.colors.away) {
          document.documentElement.style.setProperty("--backgroundVisitorTeam", data.colors.away.color);
          document.documentElement.style.setProperty("--colorVisitorTeam", data.colors.away.color_text);
        }
        if(data.colors && data.colors.home) {
          document.documentElement.style.setProperty("--backgroundLocalTeam", data.colors.home.color);
          document.documentElement.style.setProperty("--colorLocalTeam", data.colors.home.color_text);
        }

        if(data.formations && !data.formations.home[0]) setFormationTime(5);
        if(data.formations && !data.formations.home[5] && data.fixture.status.elapsed === 120) setFormationTime(8);

        //if(!data.formations.home[formationTime]) setFormationTime(5);
    //if(!match.formations.home[formationTime]) formationTime = 8;
  
        setMatch({ ...data, id: data.id });
  
    });

    analytics("match_view", matchId);

    return () => unsubscribe();
    //return unsubscribe;
    
  }, [matchId]);

  React.useEffect(() => {
    const q = ref(getDatabase(), 'matchs_players/m_' + matchId);

    const unsubscribe = onValue(q, (snapshot) => {

      if(!snapshot.val()) return;

      const allLineup = Object.keys(snapshot.val()).map((doc) => {
        return { id: doc, ...snapshot.val()[doc] };
      });
      setLineup(allLineup);
    });

    return () => unsubscribe();
    //return unsubscribe;
  }, [matchId]);

  React.useEffect(() => {

    if(!user) return;

    console.log(user)

    setShowVote(true)

    const q = query(collection(getFirestore(), "notes"), 
      where('match_id', '==', "m_" + matchId),
      where('user_id', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let allNotes = {};

      querySnapshot.docs.map((doc) => {
        allNotes[doc.data().player_id] = doc.data();
        return true;
      });

      setMyVote(allNotes);
    });

    return () => unsubscribe();
    //return unsubscribe;
  }, [user]);

  async function analytics(page, id) {

    try {
      firebase.log(page, id);
    } catch (err) {
      console.error("analytics Error", err);
    }
  }

  const INITIAL_STATE = {
    url: "",
  };

  const { handleSubmit, handleChange, values, isSubmitting } = useForm(
    INITIAL_STATE,
    validateUrl,
    submitUrl
  );

  async function submitUrl() {
    const { url } = values;

    let data_provider = {};
          data_provider.medias = {};
          data_provider.medias[voteAS] = url;

          //matchRef.update(data_provider, {merge: true});

  //const mRef = doc(firebase.db, "matchs", "m_" + matchId);


          await setDoc(matchRef, data_provider, { merge: true });
        
          setVoteAs("");
  }

  

  function actionLineup() {
    setShowLineup(!showLineup);
  }

  function handleVoteFor(team) {
    setVoteFor(team);

    //const y = elementRef.current.querySelector('.match-toolbar-3').offsetTop;
    elementRef.current && elementRef.current.scrollToPoint(0, 0, 500);
  }

  function handleShowMatch(ev) {
    ev.target.complete();

    setShowMatch(!showMatch)
  }

  

  function handleShowVote() {  
    setShowVote(!showVote);
  }

  

  function handleOpenModal(player) {
    setShowModal(true);
    setDataModal(player);
    console.log(player)
  }
  
  function handleCloseModal() {
    setShowModal(false);
  }

  

  function handleSendNote(id, note) {

    //console.log(id, note)
    handleVoteUser(id, note);
    !user && setShowModal(false);


  }


  function handleAddComment(commentText) {
    if (!user) {
      props.history.push("/profile");
    } else {
      matchRef.get().then((doc) => {
        if (doc.exists) {
          const previousComments = doc.data().comments;
          const newComment = {
            postedBy: { id: user.uid, name: user.displayName },
            created: Date.now(),
            text: commentText,
          };
          const updatedComments = [...previousComments, newComment];
          matchRef.update({ comments: updatedComments });
          setMatch((prevState) => ({
            ...prevState,
            comments: updatedComments,
          }));
        }
      });
      setShowModal(false);
    }
  }

 

  function handleDeleteLink() {
    matchRef
      .delete()
      .then(() => {
        console.log(`Document with ID ${match.id} deleted`);
      })
      .catch((err) => {
        console.error("Error deleting document", err);
      });
    props.history.push("/");
  }

  const handleVote = (vote) => {

    if(voteAS === "") handleVoteUser(vote.target.id, vote.detail.value);
    else handleVoteAs(vote);

  }

  const handleSelectVoteAs = (vote) => {

    setVoteAs(vote.target.value);
    values.url = match.medias ? match.medias[vote.target.value] : "";

  }

  const handleHdmVoteAs = (vote) => {

    //setVoteAs(vote.target.value);
    values.url = match.medias[vote.target.value] || "";
    //values.hdm = match.medias[vote.target.value] || "";

  }

  const handleSelectWichNote = (note) => {

    setWichNote(note.target.value);

  }

  

  async function handleVoteAs(vote) {

    //const ref = doc(firebase.db, "matchs_players", "m_" + matchId + vote.target.id);

    if (!user) {
      props.history.push("/profile");
    } else {

      console.log('/matchs_players/' + match.id + '/'+ vote.target.id)

      //const doc = await getDoc(ref);
      const dbRef = ref(getDatabase());
get(child(dbRef, '/matchs_players/' + match.id + '/'+ vote.target.id)).then((snapshot) => {
  if (snapshot.exists()) {
    //const previousVotes = doc.data().votes;
    //const vote = {votedBy: { id: user.uid, name: user.displayName }} ; 
    //const updatedVotes = [...previousVotes, vote];
    //const voteCount = updatedVotes.length;

    console.log("Document data:");


    let data = {};
    //data.lineup = {};
    //data.lineup[vote.target.id] = {};
    data.medias = {};
    data.medias[voteAS] = {
      note: Number(vote.detail.value),
      media: voteAS,
    }

    let totalRating = 0;
    let newTotalUsersCount = 0;
    
    if(snapshot.val().medias) {
    
    Object.keys(snapshot.val().medias)
    .map((provider, index) => {

      if(voteAS !== provider) totalRating = totalRating + snapshot.val().medias[provider].note;
      //return "";

    });

    if(snapshot.val().medias[voteAS]) {
      newTotalUsersCount = Object.keys(snapshot.val().medias).length;
    }  
    else newTotalUsersCount = Object.keys(snapshot.val().medias).length + 1;  

    } else newTotalUsersCount = 1;


  const newTotalRating = totalRating + Number(vote.detail.value);

  /* data.mediasRating = {
    //totalComments : doc.data().totalComments + 1,
    totalRating : newTotalRating,
    totalUsersCount : newTotalUsersCount,
    averageRating : newTotalRating/newTotalUsersCount,
  }; */
  


  //setDoc(ref, data, { merge: true });
  const updates = {};
  updates['/matchs_players/' + match.id + '/'+ vote.target.id + '/mediasRating'] = {
    //totalComments : doc.data().totalComments + 1,
    totalRating : newTotalRating,
    totalUsersCount : newTotalUsersCount,
    averageRating : newTotalRating/newTotalUsersCount,
  };
  updates['/matchs_players/' + match.id + '/'+ vote.target.id + '/medias/' + voteAS] = {
    note: Number(vote.detail.value),
    media: voteAS,
  }

  update(ref(getDatabase()), updates);

    addDoc(collection(getFirestore(), "notes-media"), { 
      media: voteAS,
      match_id: match.id,
      player_id: vote.target.id, 
      player_name: match.lineup[vote.target.id].player.player_name,
      position: match.lineup[vote.target.id].player.position,
      match: match.teams.home.name + " " + match.goals.home + " - " + match.goals.away + " " + match.teams.away.name,
      note: Number(vote.detail.value), 
      user_id: user.uid, 
      username: user.displayName, 
      created: Date.now(), 
    });



  
} else {
    console.log("No data available");
  }
}).catch((error) => {
  console.error(error);
});


     
    }
  }

  const handleVoteUser = async (id, vote) => {

    if (!user) {
      props.history.push("/profile");
    } else {


    const q = query(collection(getFirestore(), "notes"), 
      where('match_id', '==', "m_" + matchId),
      where('user_id', '==', user.uid),
      where('player_id', '==', id)
    );

    const querySnapshot = await getDocs(q);

        if(querySnapshot.docs.length === 0) {

          //const ref = doc(firebase.db, "matchs_players", "m_" + matchId + id);
          addDoc(collection(getFirestore(), "notes"), { 
            match_id: match.id, 
            player_id: id, 
            user_id: user.uid, 
            team_id: match.lineup[id].team_id,
  
            player: match.lineup[id].player,
            match: match.teams.home.name + " " + match.goals.home + " - " + match.goals.away + " " + match.teams.away.name,
            note: Number(vote), 
            username: user.displayName, 
            created: Date.now(), 
          });
          
          const postRef = ref(getDatabase(), '/matchs_players/m_' + matchId + '/'+ id + '/usersRating');

          runTransaction(postRef, (post) => {
        
            let data = {};

            if(!post) post = {};


        
        const totalRating = post.totalRating || 0;
        const totalUsersCount = post.totalUsersCount || 0;


        const newTotalRating = totalRating + Number(vote);
        //console.log(newTotalRating)
        const newTotalUsersCount = totalUsersCount + 1;  

        data = {
          //totalComments : doc.data().totalComments + 1,
          totalRating : newTotalRating,
          totalUsersCount : newTotalUsersCount,
          averageRating : newTotalRating/newTotalUsersCount,
        };

        console.log(totalRating)

        if(totalRating === 0){
          
          data.totalNotes = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0};
          data.totalNotes[vote] = 1;

        } else {

          data.totalNotes = post.totalNotes;
          data.totalNotes[vote] = post.totalNotes[vote] + 1;
  
        }
            
            return data;
          });

/* try {
  await runTransaction(firebase.db, async transaction => {
        const doc = await transaction.get(ref);

        const totalRating = doc.data().totalRating || 0;
        const totalUsersCount = doc.data().totalUsersCount || 0;

        let data = {};

        const newTotalRating = totalRating + Number(vote);
        //console.log(newTotalRating)
        const newTotalUsersCount = totalUsersCount + 1;  

        data = {
          //totalComments : doc.data().totalComments + 1,
          totalRating : newTotalRating,
          totalUsersCount : newTotalUsersCount,
          averageRating : newTotalRating/newTotalUsersCount,
        };

        if(totalRating === 0){
          
          data.totalNotes = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0};
          data.totalNotes[vote] = 1;

        } else {

          data.totalNotes = {};
          data.totalNotes[vote] = doc.data().totalNotes[vote] + 1;
  
        }

        transaction.set(ref, data, {merge: true});

        addDoc(collection(firebase.db, "notes"), { 
          match_id: match.id, 
          player_id: id, 
          player_name: match.lineup[id].player_name,
          position: match.lineup[id].position,
          match: match.teams.home.name + " " + match.goals.home + " - " + match.goals.away + " " + match.teams.away.name,
          note: Number(vote), 
          user_id: user.uid, 
          team_id: match.lineup[id].team_id,
          username: user.displayName, 
          created: Date.now(), 
        });

        return {note: id, value: vote};
      })
    } catch (e) {
      console.log("Transaction failed: ", e);
    } */

        } else {
          alert("deja vote")
        }
      
    }
  };

  const handleVoteUserkk = async (id, vote) => {

    if (!user) {
      props.history.push("/profile");
    } else {


    var data = {
      //totalComments : doc.data().totalComments + 1,
      totalRating : 0,
      totalUsersCount : 0,
      averageRating : 0,
    };
      
      data.totalNotes = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0};


    const updates = {};
    updates['/matchs_players/m_' + matchId + '/'+ id + '/usersRating'] = null;
  
    update(ref(getDatabase()), updates);
      
    }
  };

  
  const handleGoToSorareMatch = async () => {

    /* await Browser.open({
      url: "https://twitter.com/intent/tweet?text="+encodeURI(text)+"&hashtags=PSGOM,MVP" //+match.localTeam.data.short_code+match.visitorTeam.data.short_code,
    }); */

  };


  const handleUpdateMatch = async () => {
    try {
      const response = await fetch(`https://europe-west1-mvp-futbol.cloudfunctions.net/api/match/${matchId}`, {
        method: 'GET', // Specify the request method if needed
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers if required, such as authorization or API keys
          //'X-RapidAPI-Key': "38169f944amsh07c614143888d4fp169308jsn693fe69826d7",
          //'X-RapidAPI-Host': "api-football-v1.p.rapidapi.com/v3"
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result, matchId);
      // Process the result as needed, for example:
      // if (result.response && result.response[0]) setData(result.response[0].bookmakers);
    } catch (error) {
      console.error('Error fetching match data:', error);
    }
  };
  
  const handleUpdateSorare = async (team) => {
    try {
      const response = await fetch(`https://europe-west1-mvp-futbol.cloudfunctions.net/api/sorare/${team}/${matchId}`, {
        method: 'GET', // Specify the request method if needed
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers if required, such as authorization or API keys
          //'X-RapidAPI-Key': "38169f944amsh07c614143888d4fp169308jsn693fe69826d7",
          //'X-RapidAPI-Host': "api-football-v1.p.rapidapi.com/v3"
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result, matchId);
      // Process the result as needed, for example:
      // if (result.response && result.response[0]) setData(result.response[0].bookmakers);
    } catch (error) {
      console.error('Error fetching Sorare data:', error);
    }
  };
  
  

  async function shareOnTwitter() {
    let text = t("My_ratings_of") + " "+match.teams.home.name+" "+match.goals.home+" - "+match.goals.away+" "+match.teams.away.name+" "+t("on")+" mvp.futbol/match/" +match.fixture.id+ "\n";

    Object.keys(myVote)
      .filter((d) => match.lineup[d].team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .sort((a, b) => match.lineup[a].formation[formationTime].formation_position > match.lineup[b].formation[formationTime].formation_position ? 1 : -1)
      .slice(0, 11)

      .map((player, index) => {
        player = match.lineup[player]

        text += "\n" + player.player.player_name + ": " + myVote[player.player_id].note;

      })

      text += "\n"

    await Browser.open({
      url: "https://twitter.com/intent/tweet?text="+encodeURI(text)+"&hashtags=PSGOM,MVP" //+match.localTeam.data.short_code+match.visitorTeam.data.short_code,
    });
  }

  function showNote(player) {
    var note = 0;
    var totalNote = 0;
    var nbNote = 0;
    if(wichNote === "spectators") {
      if(!player.usersRating) return -1;
      if(player.usersRating.averageRating >= 10) note = 10; else note = player.usersRating.averageRating.toFixed(1);  
      return note;
    }
    else if(wichNote === "medias") {
      if(!player.mediasRating) return -1;
      if(player.mediasRating.averageRating >= 10) note = 10; else note = player.mediasRating.averageRating.toFixed(1);  
      return note;
    }
    else if(wichNote === "spectatorsmediasstats") {
      if(player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
        totalNote = totalNote + player.usersRating.averageRating;
        nbNote++;
      }
      if(player.mediasRating && (player.mediasRating.averageRating || player.mediasRating.averageRating === 0)) {
        totalNote = totalNote + player.mediasRating.averageRating;
        nbNote++;
      }
      if(player.stats && (player.stats["sorare"] || player.stats["sorare"].note ===0 )) {
        totalNote = totalNote + player.stats["sorare"].note;
        nbNote++;
      }

      if(nbNote === 0) return -1;
      if((totalNote / nbNote).toFixed(1) >= 10) note = 10; else note = (totalNote / nbNote).toFixed(1);  
      return note;
    }
    else if(wichNote === "mynotes") {
      if(!myVote[player.player_id]) return -1;
      return myVote[player.player_id].note;
    }
    else if(wichNote === "stats") {
      if(!player.stats || !player.stats["sorare"]) return -1;
      else return player.stats["sorare"].note * 10;
    }
    else {
      if(!player.medias || !player.medias[wichNote]) return -1;
      return player.medias[wichNote].note;
    }
  }

  function showNoteOLD(player) {
    var note = 0;
    var totalNote = 0;
    var nbNote = 0;
    if(wichNote === "spectators" && player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
      if(player.usersRating.averageRating >= 10) note = 10; else note = player.usersRating.averageRating.toFixed(1);  
      return note;
    }
    else if(wichNote === "medias" && player.mediasRating) {
      if(player.mediasRating.averageRating >= 10) note = 10; else note = player.mediasRating.averageRating.toFixed(1);  
      return note;
    }
    else if(wichNote === "spectatorsmediasstats") {

      if(player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
        totalNote = totalNote + player.usersRating.averageRating;
        nbNote++;
      }
      if(player.mediasRating && (player.mediasRating.averageRating || player.mediasRating.averageRating === 0)) {
        totalNote = totalNote + player.mediasRating.averageRating;
        nbNote++;
      }
      if(player.stats && (player.stats["sorare"] || player.stats["sorare"].note ===0 )) {
        totalNote = totalNote + player.stats["sorare"].note;
        nbNote++;
      }

      if(nbNote === 0) return "-";

      if((totalNote / nbNote) >= 10) note = 10; else note = (totalNote / nbNote).toFixed(1);  

      return note;

    }
    else if(wichNote === "mynotes" && myVote[player.player_id]) return myVote[player.player_id].note;
    else if(wichNote === "stats" && player.stats && player.stats["sorare"]) {
      //if(player.stats["sorare"].note >= 10) note = 10; else 
      note = player.stats["sorare"].note;
      return note;
    }
    else if (player.medias && player.medias[wichNote]) return player.medias[wichNote].note;
    else return "-";

  }

  const loadData = (ev) => {
    

    setTimeout(() => {
     // console.log(voteFor);
    setVoteFor(voteFor === 'home' ? 'visitor' : 'home')

      ev.target.complete();

      const y = elementRef.current.querySelector('.match-toolbar-3').offsetTop;
      elementRef.current && elementRef.current.scrollToPoint(0, y-8, 500);
      
    }, 1000);
  }

  function renderPlayerSkeleton() {
    return <IonItem>
    <IonSkeletonText slot="start" style={{ 'height': '32px', 'width': '32px', 'marginInlineEnd': '12px'}} animated={true} ></IonSkeletonText>

    <IonAvatar slot="start">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
    <IonLabel>
        <IonSkeletonText animated={true} style={{ 'height': '14px', 'width': '130px' }} ></IonSkeletonText>
        {/* <p>
        <IonSkeletonText animated={true} style={{ 'width': '130px' }} ></IonSkeletonText>
        </p> */}
    </IonLabel>
    <IonSkeletonText style={{ 'height': '32px', 'width': '32px'}} animated={true} ></IonSkeletonText>
    </IonItem>
  }


  function renderItemSelectSkeleton() {
    return <IonItem>
    
    <IonLabel className="ion-text-wrap">
    <p><IonSkeletonText animated={true} style={{width:"80px"}}></IonSkeletonText></p>
    </IonLabel>

    <IonSelect mode="ios" 
    interface="action-sheet"
              style={{
      maxWidth: "80%",
    }}>
    
    <IonSkeletonText animated={true}></IonSkeletonText>
             
    </IonSelect>
  </IonItem>
  }

  function teamLineupFormation() {
    //if(!match.formations.home[formationTime]) formationTime = 5;
    //if(!match.formations.home[formationTime]) formationTime = 8;


    //formationTime = match.formations.home[formationTime] ? formationTime : 5;
    //formationTime = 0;


    return <>
    <div className={`pitch f_${voteFor === 'home' ? match.formations.home[formationTime] : match.formations.away[formationTime]}`}>
        {
        //Object.keys(match.lineup)
        lineup
        .filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
        .filter((a) => a.formation && a.formation[formationTime] )
       
        .sort((a, b) => a.formation[formationTime].formation_position > b.formation[formationTime].formation_position ? 1 : -1)
        .slice(0, 11)

        .map((player, index) => {
  
          //player = match.lineup[player]
          const customAlertOptions = {
            header: player.player.player_name,
            subHeader: t('Your_rating_of_his_match'),
            //message: 'Choose only one',
            translucent: false
          };
  
          var DoShowVote = showVote;
  
          if(match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()) {} else DoShowVote = false;
  
  
  
          return <IonItem key={player.player_id} className={"g_" + player.formation[formationTime].formation} button={(!DoShowVote || myVote[player.player_id]) && voteAS === ""} onClick={() => (!DoShowVote || myVote[player.player_id]) && voteAS === "" && handleOpenModal(player)}>
    <div className="pitch-player">
    {//"g_" + player.formation[formationTime].formation_position
    }
    <IonAvatar style={{
        width: "40px",
        height: "40px",
        fontSize: "14px",
        margin: "auto",
      }} >

  
  
{player.events && player.events.subst && player.events.subst
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((subst, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonIcon key={index}
                  icon={returnUpBack}
                  //icon={subst.player.id === player.player.id ? returnUpBack : returnUpForward}
                  color={"danger"}
                  //color={subst.player.id === player.player.id ? "danger" : "success"}
                  style={{
                    position: "absolute",
                    top: "-4px",
                    left: "18px",
                  }}
                />
})


}  


{player.events && player.events.Card && player.events.Card
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((card, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
if(card.detail === 'Yellow Card')
return <IonIcon key={index}
                  icon={tabletPortrait}
                  color={"jaune"}
                  style={{
                    position: "absolute",
                    top: "22px",
                    left: "17px",
                  }}
                />
if(card.detail === 'Second Yellow card')
return <IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "18px",
                  }}
                />

if(card.detail === 'Red Card')
return <IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "18px",
                  }}
                />
})



}  


{player.events && player.events.Goal && player.events.Goal.filter((d) => d.detail === "Own Goal" || d.detail === "Normal Goal" || d.detail === "Penalty").length > 0 && <IonText style={{
                    position: "absolute",
                    top: "-4px",
                    left: "70px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "1px",
                  }}
                >
                {player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length > 1 && player.events.Goal.filter((d) => d.detail === "Normal Goal").length}
                </IonText>
                <IonIcon 
                  key={index}
                  color={player.events.Goal.filter((d) => d.detail === "Own Goal").length > 0 ? "danger" : player.events.Goal.filter((d) => d.detail === "Penalty").length > 0 && "warning"}
                  icon={football}
                  style={{
                    verticalAlign: "middle",
                  }}
                />
                </IonText>}


{player.events && player.events.Assist && player.events.Assist.length > 0 && <IonText style={{
                    position: "absolute",
                    top: "22px",
                    left: "72px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "1px",
                  }}
                >
                {player.events.Assist.length > 1 && player.events.Assist.length}
                </IonText>
                <IonIcon 
                  key={index}
                  icon={paw}
                  style={{
                    verticalAlign: "middle",
                  }}
                />
                </IonText>}


{/* <img alt="o" src={player.img} />
 */}
{/* {player.events && player.events.Assist && player.events.Assist
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((player, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonIcon key={index}
                  icon={ribbon}
                  color={"jaune"}
                  style={{
                    position: "absolute",
                    top: "-13px",
                    left: "44px",
                  }}
                />
})}   */}

{(voteAS === "" && !DoShowVote || myVote[player.player_id]) && showNote(player) >= 0 ? <IonBadge className="round" color={voteToColor(showNote(player), wichNote === "stats" ? 10 : 1)}>{showNote(player)}</IonBadge> : <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.image+".png"} />}
{/* {!showNote(player) && !DoShowVote && <img alt="o" src={"https://media.api-sports.io/football/players/"+player.image+".png"} />}
 */}      </IonAvatar>
      <IonLabel>{player.player.player_name}</IonLabel>
      {(voteAS === "" && DoShowVote && !myVote[player.player_id]) &&<IonSelect onIonChange={(e) => handleVote(e)} mode="ios" id={player.player_id} interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}>
        <IonSelectOption value="10">10</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
        

      </IonSelect>}
      {(voteAS != "" && DoShowVote) && <IonSelect color="mvp" mode="ios" id={player.player_id} interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleVote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >

                  <IonSelectOption value="10">10</IonSelectOption>
                  <IonSelectOption value="9.5">9,5</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8.5">8,5</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7.5">7,5</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6.5">6,5</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5.5">5,5</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4.5">4,5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3.5">3,5</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2.5">2,5</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1.5">1,5</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0.5">0,5</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
                </IonSelect>}
    </div>
  </IonItem>
        })}
        <img className="Outlines" src={pitchImage} alt="Pitch outlines"/>
  
      </div>
  </>
  }

  function teamLineupList(data) {
    //if(!match.formations.home[formationTime]) formationTime = 5;
    //if(!match.formations.home[formationTime]) formationTime = 8;
        //let category = "";
    let categoryDivider = null;

    return <IonList lines={"full"} >
    
    {data
      
      .map((player, index) => {
        var DoShowVote = showVote;

        if(match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()) {} else DoShowVote = false;

        //player = match.lineup[player]
        const customAlertOptions = {
          header: player.player.player_name,
          subHeader: t('Your rating of his match?'),
          //message: 'Choose only one',
          translucent: false
        };


        if(index === 11) categoryDivider = <IonItemDivider>
      <ion-label>
       {t('Substitutes')}
      </ion-label>
    </IonItemDivider>; else categoryDivider = null;
//return <IonBadge key={index} className="square">ok</IonBadge>

return <div key={player.player_id}>
{categoryDivider}

          <IonItemSliding key={index}>
    <IonItemOptions side="start">
      <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
      <IonItemOption color="danger" onClick={() => console.log('share clicked')}>Share</IonItemOption>
    </IonItemOptions>

   

    
              <IonItem className={voteFor === 'home' ? "local" : "visitor"} button={!DoShowVote || myVote[player.player_id]} onClick={() => (!DoShowVote || myVote[player.player_id]) && handleOpenModal(player)}>
                
              <IonBadge className="position" color={voteFor === 'home' ? "local-team" : "visitor-team"} slot="start">{t(player.formation && player.formation[formationTime] && player.formation[formationTime].name)}</IonBadge>

                <IonAvatar slot="start">
                  <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.image+".png"} />
                </IonAvatar>
                <IonLabel>{player.player.player_name}{eventsPlayerList(player)}</IonLabel>
                           
 
                {(!DoShowVote || myVote[player.player_id]) && wichNote === "spectators" && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                 {player.usersRating ? player.usersRating.totalUsersCount : 0}
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}

                {(!DoShowVote || myVote[player.player_id]) && wichNote === "medias" && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.mediasRating ? player.mediasRating.totalUsersCount : 0}
                </IonText>
                <IonIcon
                  icon={globe}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}
                

                {(voteAS === "" && !DoShowVote || myVote[player.player_id]) && showNote(player) >=0 && <IonBadge className="square" color={voteToColor(showNote(player), wichNote === "stats" ? 10 : 1)}>{showNote(player)}</IonBadge>}
                {(voteAS === "" && !DoShowVote || myVote[player.player_id]) && showNote(player)===-1 && showNote(player) !== 0 && <IonBadge className="square" color={"light"}>-</IonBadge>}


                {(voteAS === "" && DoShowVote && !myVote[player.player_id]) && <IonSelect  mode="ios" id={player.player_id} interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleVote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="10">10</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
                </IonSelect>}
                {(voteAS !== "" && DoShowVote && !myVote[player.player_id]) && <IonSelect color="mvp" mode="ios" id={player.player_id} interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleVote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="10">10</IonSelectOption>
                  <IonSelectOption value="9.5">9,5</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8.5">8,5</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7.5">7,5</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6.5">6,5</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5.5">5,5</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4.5">4,5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3.5">3,5</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2.5">2,5</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1.5">1,5</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0.5">0,5</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
                </IonSelect>}
              </IonItem>


    <IonItemOptions side="end">
    <IonItemOption>
        {/* Changer<br/>votre<br/>note */}
        Ma note
      </IonItemOption>

      <IonItemOption onClick={() => console.log('unread clicked')}>
      {myVote[player.player_id] && myVote[player.player_id].note && <IonButton slot="start" color={voteToColor(myVote[player.player_id].note, wichNote === "stats" ? 10 : 1)} className="square" >{myVote[player.player_id].note}</IonButton>}
      </IonItemOption>

    </IonItemOptions>
  </IonItemSliding>
</div>
            })}

</IonList>


  }

  function eventsPlayerList(player) {
//console.log(player.events && player.events.Goal && player.events.Goal.filter((d) => d.detail !== "Missed Penalty" && d.detail !== "Penalty"))
    return <>

    {player.events && player.events.subst && player.events.subst
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .map((subst, index) => {
      //return <IonBadge key={index} className="square">ok</IonBadge>
      return <IonIcon key={index}
                        icon={subst.player.id === player.player.id ? returnUpBack : returnUpForward}
                        color={subst.player.id === player.player.id ? "danger" : "success"}
                        style={{
                          marginLeft: "6px",
                          //verticalAlign: "middle",
                        }}
                      />
      })
      
      
      }  
      
      
      {player.events && player.events.Card && player.events.Card
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .map((card, index) => {
      //return <IonBadge key={index} className="square">ok</IonBadge>
      if(card.detail === 'Yellow Card')
      return <IonIcon key={index}
                        icon={tabletPortrait}
                        color={"jaune"}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                        }}
                      />
                      
      if(card.detail === 'Second Yellow card')
      return <IonIcon key={index}
                        icon={tabletPortrait}
                        color={"jaune"}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                        }}
                        
                      />
      if(card.detail === 'Red Card')
      return <IonIcon key={index}
                        icon={tabletPortrait}
                        color={"rouge"}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                        }}
                        
                      />
      })
      
      
      }  
      
      {player.events && player.events.Goal && player.events.Goal.filter((d) => d.detail === "Own Goal" || d.detail === "Normal Goal" || d.detail === "Penalty").length > 0 && <IonText style={{
                          //color: "#777777",
                          marginLeft: "6px",
                        }}><IonText
                        //size={"small"}
                        style={{
                          verticalAlign: "middle",
                          paddingRight: "1px",
                        }}
                      >
                      {player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length > 1 && player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length}
                      </IonText>
                      <IonIcon 
                        icon={football}
                        color={player.events.Goal.filter((d) => d.detail === "Own Goal").length > 0 ? "danger" : player.events.Goal.filter((d) => d.detail === "Penalty").length > 0 && "warning"}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      </IonText>}
      
      {player.events && player.events.Assist && player.events.Assist.length > 0 && <IonText style={{
                          //color: "#777777",
                          marginLeft: "6px",
                        }}><IonText
                        //size={"small"}
                        style={{
                          verticalAlign: "middle",
                          paddingRight: "1px",
                        }}
                      >
                      {player.events.Assist.length > 1 && player.events.Assist.length}
                      </IonText>
                      <IonIcon 
                        icon={paw}
                        style={{
                          verticalAlign: "middle",
                        }}
                      />
                      </IonText>}
      
      
      
      {/* {player.events && player.events.Assist.length > 1 && <IonIcon key={index}
                        icon={ribbon}
                        color={"jaune"}
                        style={{
                          marginLeft: "6px",
                          //verticalAlign: "middle",
      
                        }}
                      />
      
      
      
      }   */}
      </>

  }

  function matchNoteList() {

    const data = [{
      name: "Note du match",
      id: "coatching"

    },{
      name: "Coatching",
      id: "coatching"

    },{
      name: "Coatching",
      id: "coatching"

    },{
      name: "Arbitrage",
      id: "coatching"

    }, ]
    return <>
    
    {data
      //.sort((a, b) => a.formation_position > b.formation_position ? 1 : -1)
      .map((row, index) => {
        var DoShowVote = showVote;

        if(match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()) {} else DoShowVote = false;

        //player = match.lineup[player]
        const customAlertOptions = {
          header: row.name,
          subHeader: t('Your rating of his match?'),
          //message: 'Choose only one',
          translucent: false
        };


        return (

          <IonItemSliding key={index}>
    <IonItemOptions side="start">
      <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
      <IonItemOption color="danger" onClick={() => console.log('share clicked')}>Share</IonItemOption>
    </IonItemOptions>

   

    
              <IonItem className={voteFor === 'home' ? "local" : "visitor"} 
              button={!DoShowVote || myVote[row.id]} 
              //onClick={() => (!DoShowVote || myVote[row.id]) && handleOpenModal(row)}
              >
                
              <IonBadge className="position" color={voteFor === 'home' ? "local-team" : "visitor-team"} slot="start">{match.formations.home && formationList[voteFor === 'home' ? match.formations.home : match.formations.away] && formationList[voteFor === 'home' ? match.formations.home : match.formations.away][row.grid] ? formationList[voteFor === 'home' ? match.formations.home : match.formations.away][row.grid] : row.position}</IonBadge>

                <IonAvatar slot="start">
                  <img alt="o" src={"https://media.api-sports.io/football/players/"+row.image+".png"} />
                </IonAvatar>
                <IonLabel>{row.name}</IonLabel>
                           
 
                {(!DoShowVote || myVote[row.id]) && wichNote === "spectators" && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                jj
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}

                {(!DoShowVote || myVote[row.id]) && wichNote === "medias" && row.mediasRating && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {row.mediasRating.totalUsersCount || 0} 
                </IonText>
                <IonIcon
                  icon={globe}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}
                

                {(voteAS === "" && !DoShowVote || myVote[row.id]) && showNote(row) >=0 && <IonBadge className="square" color={voteToColor(showNote(row), wichNote === "stats" ? 10 : 1)}>{showNote(row)}</IonBadge>}
                {(voteAS === "" && !DoShowVote || myVote[row.id]) && !showNote(row) === -1 && showNote(row) !== 0 && <IonBadge className="square" color={"light"}>-</IonBadge>}


                {(voteAS === "" && DoShowVote && !myVote[row.id]) && <IonSelect  mode="ios" id={row.id} interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleVote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="10">10</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
                </IonSelect>}
                {(voteAS != "" && DoShowVote && !myVote[row.id]) && <IonSelect color="mvp" mode="ios" id={row.id} interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleVote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="10">10</IonSelectOption>
                  <IonSelectOption value="9.5">9,5</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8.5">8,5</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7.5">7,5</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6.5">6,5</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5.5">5,5</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4.5">4,5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3.5">3,5</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2.5">2,5</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1.5">1,5</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0.5">0,5</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
                </IonSelect>}
              </IonItem>


    <IonItemOptions side="end">
    <IonItemOption>
        {/* Changer<br/>votre<br/>note */}
        Ma note
      </IonItemOption>

      <IonItemOption onClick={() => console.log('unread clicked')}>
      {myVote[row.id] && myVote[row.id].note && <IonButton slot="start" color={voteToColor(myVote[row.id].note, wichNote === "stats" ? 10 : 1)} className="square" >{myVote[row.id].note}</IonButton>}
      </IonItemOption>

    </IonItemOptions>
  </IonItemSliding>
            )})}

  </>


  }


  

  function teamLineupListAll() {

    //if(!match.formations.home[formationTime]) formationTime = 5;
    //if(!match.formations.home[formationTime]) formationTime = 8;
    return <IonList lines={"full"} >
    
    {lineup
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      .sort((a, b) => b.player.player_name.localeCompare(a.player.player_name))
      .sort((a, b) => showNote(a) < showNote(b) ? 1 : -1)
      .map((player, index) => {
        var DoShowVote = showVote;

        if(match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()) {} else DoShowVote = false;

        //player = match.lineup[player]
        const customAlertOptions = {
          header: player.player.player_name,
          subHeader: t('Your rating of his match?'),
          //message: 'Choose only one',
          translucent: false
        };


        return (
    
              <IonItem key={player.player_id} style={{
                    //"--border-width": index === 0 && 0,
                  }}
                  className={match.localteam_id  === player.team_id ? "local" : "visitor"} button={!DoShowVote || myVote[player.player_id]} onClick={() => (!DoShowVote || myVote[player.player_id]) && handleOpenModal(player)}>
                
                <IonBadge className="position" color={match.localteam_id  === player.team_id ? "local-team" : "visitor-team"} slot="start">{t(player.formation && player.formation[formationTime] && player.formation[formationTime].name)}</IonBadge>

                <IonAvatar slot="start">
                  <img alt="o" src={"https://media.api-sports.io/football/players/"+player.player.image+".png"} />
                </IonAvatar>
                <IonLabel>{player.player.player_name}{eventsPlayerList(player)}</IonLabel>
                           
 
                {(!DoShowVote || myVote[player.player_id]) && wichNote === "spectators" && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                 {player.usersRating ? player.usersRating.totalUsersCount : 0}
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}

                {(!DoShowVote || myVote[player.player_id]) && wichNote === "medias" && player.mediasRating && <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.mediasRating.totalUsersCount || 0} 
                </IonText>
                <IonIcon
                  icon={globe}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>}
                

                {(voteAS === "" && (!DoShowVote || myVote[player.player_id])) && showNote(player) >=0 && <IonBadge className="square" color={voteToColor(showNote(player), wichNote === "stats" ? 10 : 1)}>{showNote(player)}</IonBadge>}
                {(voteAS === "" && (!DoShowVote || myVote[player.player_id])) && showNote(player)===-1 && <IonBadge className="square" color={"light"}>-</IonBadge>}

                
  {/* {index === 0 && <IonItem>
              <IonLabel>mvp</IonLabel>

              </IonItem>} */}
                  
              </IonItem>

              


    
            )})}

  </IonList>


  }

  function itemSelect() {

    var DoShowVote = showVote;
    if(match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()) {} else DoShowVote = false;

    return DoShowVote && Object.keys(myVote).length === 0 ? <IonItem button={true} onClick={() => handleShowVote()} style={{
      //position: "absolute",
      top: 0,
      right: 0
    }}>
              <IonLabel className="ion-text-wrap">
                {/* <h2>{t('MATCH_')}</h2> */}
                <p>{t('match_not_see')}</p>
              </IonLabel>
              
              <IonButton
                
                fill="outline"
                color="light"
                slot="end"
              >
              {/* <IonIcon style={{
                  '--height': "60px",
                }} color="light" icon={trendingUpOutline} slot="end"></IonIcon> */}
                {t('match_not_see_button')}
              </IonButton>
            </IonItem> : <IonItem style={{
      //position: "absolute",
      top: 0,
      right: 0
    }}>
              <IonLabel className="ion-text-wrap">
                {/* <h2>{t('MATCH_')}</h2> */}
                <p>{t('match_select_who')}</p>
              </IonLabel>
              <IonSelect defaultValue="spectators" value={wichNote} color="danger" mode="ios" interfaceOptions={customPopoverOptions} interface="action-sheet" cancelText={t('Cancel')}
              style={{
      maxWidth: "80%",
    }}
                onIonChange={(e) => handleSelectWichNote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  {user && <IonSelectOption value="mynotes">{t('mynotes')}</IonSelectOption>}
                  <IonSelectOption value="spectatorsmediasstats">{t('spectatorsmediasstats')}</IonSelectOption>
                  <IonSelectOption value="spectators">{t('Spectators')}</IonSelectOption>
                  <IonSelectOption value="medias">{t('Medias')}</IonSelectOption> 
                  <IonSelectOption value="stats">{t('Stats_Sorare')}</IonSelectOption> 
                    

                  {match && match.medias && Object.keys(match.medias)
      //.sort((a, b) => match.lineup[a].formation_position > match.lineup[b].formation_position ? 1 : -1)
      .map((provider, index) => {
        return <IonSelectOption key={index} value={provider}>{providerList[provider] ? providerList[provider].name : provider}</IonSelectOption>
      })}
                  
          
                </IonSelect>
            </IonItem>
  }

  function renderSkeleton() {
    return <IonItem className="ion-text-center match-item">
    <IonAvatar slot="start">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
    <IonLabel style={{ 'height': '66px'}}>
    <p style={{ 'width': '200px', margin: '0 auto', marginTop: '8px'}}>
        <IonSkeletonText animated={true} ></IonSkeletonText>
      </p>
      <p style={{ 'width': '300px', margin: '0 auto'}}>
        <IonSkeletonText animated={true}></IonSkeletonText>
      </p>
      <p style={{ 'width': '150px', margin: '0 auto'}}>
        <IonSkeletonText animated={true}></IonSkeletonText>
      </p>
    </IonLabel>
    <IonAvatar slot="end">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
  </IonItem>
}

  const customPopoverOptions = {
    header: t('See'),
    //subHeader: 'Select your hair color',
    message: t('Rate')
  };

  return (
    <IonPage>
      <MatchHeader         
        actionVoteFor={handleVoteFor}
        showVote={showVote}
        voteFor={voteFor}
        openVote={match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()}
        actionVote={handleShowVote}
        teamVisitor={match && match.teams && match.teams.away.logo}
        teamHome={match && match.teams && match.teams.home.logo}
        //color_text={voteFor && match.colors && match.colors.home && match.colors.away ? match.colors.away.color_text : match.colors.home.color_text}
        //color={voteFor && match.colors && match.colors.home && match.colors.away ? match.colors.away.color : match.colors.home.color}
      />
      <IonContent ref={elementRef}  >

      <IonRefresher disabled={true} slot="fixed" onIonRefresh={handleShowMatch}>
        <IonRefresherContent
          //pullingIcon={chevronDownCircleOutline}
          pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing...">
        </IonRefresherContent>
      </IonRefresher>
  
      {/* 
      {match && Date.now()/1000 > match.time.starting_at.timestamp ? "commencé" : "pas commencé"}
      {match && Date.now()/1000 > match.time.starting_at.timestamp + 3600 * 12 ? "pas terminé" : "terminé"} */}
      
      <IonList lines="none" style={{paddingTop: 0}}>        
        {match ? <MatchItem
          showNotes={false}
          data={match}
        /> : renderSkeleton()}
      </IonList>


      
      <IonList lines="none" style={{
      minHeight: "2000px",
      //paddingBottom: "600px",
      paddingTop: 0,
    }}>

{/* {match && <MatchBookmakers match_id={matchId} />}
 */}
 



     {match && lineup && lineup.length > 0 && voteFor !== 'noteClose' && <IonToolbar color={voteFor === 'home' ? "local-team" : "visitor-team"} className="match-toolbar-3">
      
      <IonTitle>
      {voteFor === 'home' && match.teams && match.teams.away ? match.teams.home.name : match.teams.away.name}
      {voteFor === 'visitor' && match.formations && match.formations.away[match.formations.home[formationTime] ? formationTime : 5] && " - " + match.formations.away[match.formations.home[formationTime] ? formationTime : 5]}
      {voteFor === 'home' && match.formations && match.formations.home[match.formations.home[formationTime] ? formationTime : 5] && " - " + match.formations.home[match.formations.home[formationTime] ? formationTime : 5]}
      </IonTitle> 

      <IonButtons slot="end">
        
 
          <IonButton 
          fill="clear" 
          onClick={() => actionLineup()}
          ><IonIcon
          size="large"

            icon={showLineup ? listOutline : keypadOutline}
          /></IonButton>
      </IonButtons>

    </IonToolbar>}

{match && lineup && lineup.length > 0 ? itemSelect() : renderItemSelectSkeleton()}

{voteFor === 'noteClose' && match && match.formations && lineup && lineup.length > 0 && teamLineupListAll()}
{voteFor === 'noteClose' && !lineup &&
        <IonList lines="full">
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
         {renderPlayerSkeleton()}
        </IonList>
      }

{(voteFor === 'home' || voteFor === 'visitor') && match && match.formations && lineup && lineup.length > 0 && showLineup && teamLineupFormation()}
{(voteFor === 'home' || voteFor === 'visitor') && match && match.formations && lineup && lineup.length > 0 && showLineup && teamLineupList(
  
lineup.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.filter((a) => a.formation && a.formation[formationTime] )

.sort((a, b) => a.formation[formationTime].formation_position > b.formation[formationTime].formation_position ? 1 : -1)
.slice(11, 22))
}
{(voteFor === 'home' || voteFor === 'visitor') && match && match.formations && lineup && lineup.length > 0 && !showLineup && 
teamLineupList(lineup
.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.filter((a) => a.formation && a.formation[formationTime] )

.sort((a, b) => a.formation[formationTime].formation_position > b.formation[formationTime].formation_position ? 1 : -1))}

{/* {voteFor === 'noteClose' && match && lineup.length > 0 && matchNoteList()}
 */}
            {match && Object.keys(myVote)
              .filter((d) => myVote[d].team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
            .length >= 11 && <IonItem button={true} onClick={() => shareOnTwitter()}>
              <IonLabel className="ion-text-wrap">
                <h2>{t('Share_your_ratings')}</h2>
{/*                 <p>{t('Share your notes')}</p>
 */}              </IonLabel>
              <IonButton
                
                fill="outline"
                color="twitter"
                slot="end"
              >
              <IonIcon style={{
                  '--height': "60px",
                }} color="twitter" icon={logoTwitter} slot="end"></IonIcon>
                {t('on_Twitter')}
              </IonButton>
            </IonItem>}




            {voteAS != "" && <><IonItem>
        <IonLabel position="floating">URL de l'article</IonLabel>
        <IonInput 
        placeholder="http://"
        value={values.url}
        name="url"

            onIonChange={handleChange}
            required
            ></IonInput>
            <IonButton
            slot='end'
              type="submit"
              color="primary"
              expand="block"
              onClick={handleSubmit}
              //disabled={isSubmitting}
            >
              OK
            </IonButton>
      </IonItem>
      
      <IonItem>

<IonLabel>{t('Most_Valuable_Player')}</IonLabel>

<IonSelect 
name="hdm"
value={values.hdm}

style={{
      maxWidth: "60%",
    }}
    mode="ios" interfaceOptions={customPopoverOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleHdmVoteAs(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >

                {lineup && lineup.length > 0 &&
      //.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
      lineup.sort((a, b) => (a.medias && a.medias[voteAS] && a.medias[voteAS].note) < (b.medias && b.medias[voteAS] && b.medias[voteAS].note) ? 1 : -1)
      .map((player, index) => {
        var DoShowVote = showVote;
        return <IonSelectOption key={index} value={player.player.player_name}>{player.player.player_name}</IonSelectOption>;
      })
        
        
        }
        
                </IonSelect>



</IonItem></>

}

{user && user.uid === "pfl7Wcsdc9RbMaKNagGUHgQcrEg2" && <IonItem>

<IonButton
                
                fill="outline"
                color="light"
                slot="end"
                onClick={() => handleGoToSorareMatch()}


              >

                Sorare
              </IonButton>



<IonButton
                
                fill="outline"
                color="light"
                slot="end"
                onClick={() => handleUpdateMatch()}


              >

                Update match
              </IonButton>

              <IonButton
                
                fill="outline"
                color="light"
                slot="end"
                onClick={() => handleUpdateSorare("homeTeam")}
              >

                Update Sorare homeTeam
              </IonButton>

              <IonButton
                
                fill="outline"
                color="light"
                slot="end"
                onClick={() => handleUpdateSorare("awayTeam")}
              >

                Update Sorare awayTeam
              </IonButton>

              

            
</IonItem>}

            {user && user.uid === "pfl7Wcsdc9RbMaKNagGUHgQcrEg2" && <IonItem>
              <IonLabel className="ion-text-wrap">
                <h2>{t('Share Notes')}</h2>
                <p>A new message in your network</p>
              </IonLabel>
              <IonSelect value={voteAS} color="mvp" mode="ios" interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleSelectVoteAs(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  {Object.keys(providerList)
      //.sort((a, b) => match.lineup[a].formation_position > match.lineup[b].formation_position ? 1 : -1)
      .map((provider, index) => {
        return <IonSelectOption key={index} value={provider}>{providerList[provider] ? providerList[provider].name : provider}</IonSelectOption>
      })}
          
                </IonSelect>
            </IonItem>}

    


</IonList>

<IonList>
</IonList>

<PlayerModal
          isOpen={showModal}
          data={dataModal}
          showNoteSelect={true}
          myVote={myVote}
          teamHome={match && match.localteam_id}
          medias={match && match.medias}
          sendAction={handleAddComment}
          sendNote={handleSendNote}
          closeAction={handleCloseModal}
          closeNote={match && match.fixture && (match.fixture.status.elapsed === 90 || match.fixture.status.elapsed === 120) && moment().format() < moment(match.fixture.date).local().add(hoursToNote, 'hours').format()}
        />

    <IonInfiniteScroll
    
          onIonInfinite={loadData}
          //threshold="10%"
          threshold="100px"
          disabled={!lineup || voteFor === 'noteClose'}
          
        >
          <IonInfiniteScrollContent
            //loadingSpinner="bubbles"
            loadingText={t('Loading...')}
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {/* <CommentModal
          isOpen={showModal}
          title="New Comment"
          sendAction={handleAddComment}
          closeAction={handleCloseModal}
        /> */}
        
        
        
        
      </IonContent>

      
    </IonPage>
  );
};

export default Match;
