import React, { useRef } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonBadge,
  IonIcon,
  IonRow, IonCol,IonAvatar,
  IonSelectOption, IonSelect,
  IonText
} from "@ionic/react";

import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { ref, onValue, getDatabase} from "firebase/database";

import SorareScoreDetailed from "./SorareScoreDetailed";
import MatchPosition from "./MatchPosition";



import { Browser } from '@capacitor/browser';

import { useTranslation } from "react-i18next";

import {
  personCircleOutline,
  arrowBackOutline,
  arrowUpOutline,
  closeOutline,
  globe,

  ribbon,
  paw,
  returnUpForward,
  returnUpBack,
  football,
  tabletPortrait,
} from "ionicons/icons";

import providerList from "../../helpers/provider";


const PlayerModal = ({ isOpen, teamHome, sendAction, closeAction, comment, data, myVote, sendNote, medias, closeNote, showNoteSelect, isComponent }) => {

  const { t } = useTranslation();

  const [showNotesStat, setShowNotesStat] = React.useState(false);
  const [showNotesMedias, setShowNotesMedias] = React.useState(true);
  const [showNotesAverage, setShowNotesAverage] = React.useState(false);
  const [showStatsSorare, setShowStatsSorare] = React.useState(false);
  const [player, setPlayer] = React.useState(data);



  //const [notesMedias, setNotesMedias] = React.useState([]);

  const modal = useRef(null);

  const starCountRef = ref(getDatabase(), '/matchs_players/' + data.match_id + '/' + data.player_id);

  

  const [commentText, setCommentText] = React.useState(
    comment ? comment.text : ""
  );

/*   React.useEffect(() => {
    //data && getPlayer();

    //setPlayer(data);

    // eslint-disable-next-line
  }, [data]); 


  React.useEffect(() => {
    
    //!isOpen && starCountRef.off();

  }, [isOpen]);*/

  React.useEffect(() => {
  if(isOpen){
    data && setPlayer(data);
    setShowNotesStat(false);
    setShowNotesMedias(true);
    setShowNotesAverage(false);
    setShowStatsSorare(false);

    const unsubscribe = onValue(starCountRef, (snapshot) => {

      const data = snapshot.val();

      setPlayer({ ...data, id: data.id });

    });

    //return unsubscribe;
    return () => unsubscribe();
  }
}, [isOpen]);
  

  async function getPlayer() {

    /* const playerRef = doc(firebase.db, "matchs_players", data.match_id + data.player_id);

    onSnapshot(playerRef, (doc) => {

      setPlayer({ ...doc.data(), id: doc.id });

    }); */


    onValue(starCountRef, (snapshot) => {

      const data = snapshot.val();

      setPlayer({ ...data, id: data.id });


    });

  }

  function handleSendAction(item) {
    sendAction(item);
    setCommentText("");
  }

  function handleNote(note) {
    sendNote(player.player_id, note.target.value);
  }



  

  async function openBrowser(url) {
    //await window.open(url);

    await Browser.open({
      url,
    });
  }

  function voteToColor(vote) {
    if(vote < 1) return "0";
    else if(vote < 2) return "1";
    else if(vote < 3) return "2";
    else if(vote < 4) return "3";
    else if(vote < 5) return "4";
    else if(vote < 6) return "5";
    else if(vote < 7) return "6";
    else if(vote < 8) return "7";
    else if(vote < 9) return "8";
    else if(vote < 10) return "9";
    else if(vote >= 10) return "10";
  }

  function mediaText(media) {
    if(media < 1) return "0";

  }

  function handleShowNotesAverage() {
    //modal.setCurrentBreakpoint(0.75);
    modal.current?.setCurrentBreakpoint(0.3)
    setShowNotesMedias(false);
    setShowNotesAverage(true);
    setShowNotesStat(false);
    setShowStatsSorare(false);

  }

  function handleShowStatsSorare() {
    //modal.setCurrentBreakpoint(0.75);
    modal.current?.setCurrentBreakpoint(1)
    setShowNotesMedias(false);
    setShowNotesAverage(false);
    setShowNotesStat(false);
    setShowStatsSorare(true);
  }

  

  function handleShowNotesStat() {
  //modal.setCurrentBreakpoint(0.75);
  modal.current?.setCurrentBreakpoint(0.75)
  setShowNotesStat(!showNotesStat);
  setShowNotesMedias(false);
  setShowNotesAverage(false);
  }

  function handleShowNotesMedias() {
    //modal.setCurrentBreakpoint(0.75);
    modal.current?.setCurrentBreakpoint(0.75)
    
    //setShowNotesStat(!showNotesStat);
    setShowNotesMedias(true);
    setShowNotesAverage(false);
    setShowStatsSorare(false);
    setShowNotesStat(false);
  
    }

    function handleShowFull() {
      //modal.setCurrentBreakpoint(0.75);
      if(modal.current?.currentBreakpoint === 1) {
        handleShowStatsSorare();
        return;
      }
      modal.current?.setCurrentBreakpoint(1)
      setShowNotesMedias(true);
      setShowNotesAverage(false);
      setShowNotesStat(false);
      setShowStatsSorare(false);
    }

    function showNote(player) {
      var note = 0;
      var totalNote = 0;
      var nbNote = 0;
      if(player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
        totalNote = totalNote + player.usersRating.averageRating;
        nbNote++;
      }
      if(player.mediasRating && (player.mediasRating.averageRating || player.mediasRating.averageRating === 0)) {
        totalNote = totalNote + player.mediasRating.averageRating;
        nbNote++;
      }
      if(player.stats && (player.stats["sorare"] || player.stats["sorare"].note ===0 )) {
        totalNote = totalNote + player.stats["sorare"].note;
        nbNote++;
      }

      if(nbNote === 0) return "-";

      if((totalNote / nbNote) >= 10) note = 10; else note = (totalNote / nbNote).toFixed(1);  

      return note;
  
    }

    

    const customAlertOptions = {
      header: player && player.player.player_name,
      subHeader: t('Your_rating_of_his_match'),
      //message: 'Choose only one',
      translucent: false
    };


  return (
    <IonModal className={isComponent && "isComponent"} ref={modal} canDismiss={true} isOpen={isOpen} onDidDismiss={closeAction}
    //backdropDismiss={true}
   // showBackdrop={false}
    backdropBreakpoint={0.3}
    initialBreakpoint={0.5}
          breakpoints={[0, 0.3, 0.5, 0.75, 1]}
          //handleBehavior="cycle"
          >
      <IonHeader translucent>
        <IonToolbar color={teamHome === player.team_id ? "local-team" : "visitor-team"}>

          
      

          <IonButtons slot="start">
          {/* {player.totalUsersCount >=0 &&<IonButton 
          //color={voteToColor(player.averageRating)}
          >{player.totalUsersCount}
          <IonIcon
          slot="end"
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />
          </IonButton>} */}

         {!isComponent && <IonButton onClick={closeAction}><IonIcon
          size="large"
                  icon={closeOutline}
                  style={{
                    verticalAlign: "middle",
                    //paddingRight: "10px",
                  }}
                /></IonButton>}

                {player && player.formation && player.formation[0] && data && data.player.color && <MatchPosition marginLeft= {isComponent ? "12px" : 0} position={t(player.formation[0].name)} background={data.player.color_text} color={data.player.color} />}

{/* {!isComponent && <IonAvatar style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "12px",
                    marginLeft: 0,
                  }}>
{player && <img alt="o" src={"https://media.api-sports.io/football/players/"+ player.player.image+".png"} />}
</IonAvatar>} */}

          
          </IonButtons> 

          <IonTitle style={{
                    //paddingInline: "12px",
                    //paddingInline: !isComponent ? "12px" : 0,
                    paddingInline: 0,

                    //paddingRight: "10px",
                  }}>{player && player.player.player_name}</IonTitle>

{showNote(player) >=0 && <IonButton slot="end" onClick={handleShowNotesAverage} color={voteToColor(showNote(player))} className="square-end" >{showNote(player)}</IonButton>}
          {showNote(player) === -1 && <IonButton slot="end" onClick={handleShowNotesAverage} color={"light"} className="square-end" >-</IonButton>}
          
          {/* <IonButtons slot="end">
            <IonButton onClick={() => handleSendAction(commentText)}>
              Send
            </IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      

      {showStatsSorare && <>
        <SorareScoreDetailed template="normal" data={player.sorare} />

        <IonItem>
            
          <IonLabel>
          <IonButton
              onClick={() => handleShowNotesMedias()}                
            >{t("Back")}</IonButton>
          </IonLabel>


        </IonItem>
      </> }

              {/* <IonToolbar color="primary">

    
          <IonTitle>{t('Medias')}</IonTitle>
      
          
        {player.mediasRating && <IonBadge color={voteToColor(player.mediasRating.averageRating)} slot="end" className="position">{player.mediasRating.averageRating.toFixed(1)}</IonBadge>}

        
        </IonToolbar> */}
        {(showNotesAverage || showNotesMedias) && <IonRow>
        <IonCol style={{
     paddingLeft: 0,
     paddingRight: 0,
     paddingTop: 0,
     paddingBottom: 0,
   }}>

{!isComponent && player.usersRating && player.usersRating.totalUsersCount > 0 && <IonList lines={"inset"} style={{
     paddingBottom: 0
   }}>

<IonItem button={true} onClick={handleShowNotesStat}>
 
 <IonLabel>{t('Spectators')}</IonLabel>
            

 <IonText style={{
     color: "#777777",
     fontSize: "14px",
   }}><IonText
   size={"small"}
   style={{
     verticalAlign: "middle",
     paddingRight: "2px",
   }}
 >
 {player.usersRating ? player.usersRating.totalUsersCount : 0}
 </IonText>
 <IonIcon
   icon={personCircleOutline}
   style={{
     verticalAlign: "middle",
     paddingRight: "10px",
   }}
 />{" "}
 </IonText>

 {player.usersRating && player.usersRating.averageRating >=0 && <IonBadge className="square" color={voteToColor(player.usersRating.averageRating)}>{player.usersRating.averageRating >= 10 ? 10 : player.usersRating.averageRating.toFixed(1)}</IonBadge>}
 {!player.usersRating && <IonBadge className="square" color={"light"}>-</IonBadge>}


 
</IonItem></IonList>}

{(showNoteSelect && !showNotesAverage && player.usersRating && player.usersRating.totalUsersCount > 0) && <IonList lines={"none"} style={{
     paddingBottom: "18px",
     paddingTop: 0,

   }}>

<IonItem>

<IonLabel>{t('Myrating')}</IonLabel>

{closeNote ? myVote && myVote[player.player_id] ? <IonBadge color={voteToColor(myVote[player.player_id].note)} slot="end" className="position3">{myVote[player.player_id].note}</IonBadge> : <IonSelect 
style={{
      maxWidth: "60%",
    }}
    mode="ios" interfaceOptions={customAlertOptions} interface="action-sheet" okText="Ok" cancelText={t('Cancel')} placeholder={t('Rate')}
                onIonChange={(e) => handleNote(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="10">10</IonSelectOption>
        <IonSelectOption value="9">9</IonSelectOption>
        <IonSelectOption value="8">8</IonSelectOption>
        <IonSelectOption value="7">7</IonSelectOption>
        <IonSelectOption value="6">6</IonSelectOption>
        <IonSelectOption value="5">5</IonSelectOption>
        <IonSelectOption value="4">4</IonSelectOption>
        <IonSelectOption value="3">3</IonSelectOption>
        <IonSelectOption value="2">2</IonSelectOption>
        <IonSelectOption value="1">1</IonSelectOption>
        <IonSelectOption value="0">0</IonSelectOption>
                </IonSelect> : myVote && myVote[player.player_id] ? <IonBadge color={voteToColor(myVote[player.player_id].note)} slot="end" className="position3">{myVote[player.player_id].note}</IonBadge> : <IonBadge className="square" color={"light"}>-</IonBadge>}



</IonItem>

</IonList>}













<IonList lines={"inset"} style={{
     paddingTop: showNotesAverage ?  0 : isComponent ? "8px" : "8px",
     //paddingTop: 0,
     paddingBottom: 0

   }}>
        
        <IonItem button={true} onClick={handleShowStatsSorare}>
 
 <IonLabel>{t('Statistiques')}</IonLabel>
            

<IonText
   size={"small"}
   style={{
     verticalAlign: "middle",
     paddingRight: "10px",
     color: "#777777",
     fontSize: "14px",

   }}
 >{t('Score_Sorare')}
 </IonText> 
 

 {player.stats && player.stats.sorare ? <IonBadge className="square" color={voteToColor(player.stats.sorare.note)}>{player.stats.sorare.note >= 10 ? 10 : player.stats.sorare.note.toFixed(1)}</IonBadge> : <IonBadge className="square" color={"light"}>-</IonBadge>}


 
</IonItem>
</IonList>



{!showNotesAverage && <IonList lines={"none"} style={{
     paddingTop: 0
   }}>
{player.events && player.events.Assist && player.events.Assist
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((player, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonIcon key={index}
                  icon={ribbon}
                  color={"jaune"}
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "16px",
                  }}
                />
})


}




{player.events && player.events.Goal && player.events.Goal.length > 0 && <IonItem>
<IonLabel class="ion-text-wrap">

{player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length > 0 && <>

{player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length === 1 ? <>1 {t('goal')} {t('at')} </> : <>{player.events.Goal.filter((d) => d.detail === "Normal Goal" || d.detail === "Penalty").length} {t('goals')} {t('at')} </>}

{player.events.Goal.map((goal, index) => {
  return <span key={index}>{index!==0 && ", "}{goal.time.elapsed}m</span>
})}

{player.events.Goal.filter((d) => d.detail === "PenaltyEnd" || d.detail === "Penalty").length > 0 && <>
{player.events.Goal.filter((d) => d.detail === "PenaltyEnd" || d.detail === "Penalty").length === 1 ? <> (1 {t('penalty')})</> : <> ({player.events.Goal.filter((d) => d.detail === "Penalty" || d.detail === "PenaltyEnd").length} {t('penaltys')})</>}
</>}

</>}



{player.events.Goal.filter((d) => d.detail === "PenaltyEnd").length > 0 && <>{t('PenaltyEnd')}</>}
{player.events.Goal.filter((d) => d.detail === "PenaltyEndMissed").length > 0 && <>{t('PenaltyEndMissed')}</>}





{player.events.Goal.filter((d) => d.detail === "Missed Penalty").length > 0 && <>

{player.events.Goal.filter((d) => d.detail === "Missed Penalty").length === 1 ? <>1 {t('Missed penalty')} {t('at')} </> : <>{player.events.Goal.filter((d) => d.detail === "Missed penalty").length} {t('Missed Penaltys')} {t('at')} </>}

{player.events.Goal.filter((d) => d.detail === "Missed Penalty").map((goal, index) => {
  return <span key={index}>{index!==0 && ", "}{goal.time.elapsed}m</span>
})}

</>}





{player.events.Goal.filter((d) => d.detail === "Own Goal").length > 0 && <>

{player.events.Goal.filter((d) => d.detail === "Own Goal").length === 1 ? <>1 {t('Own Goal')} {t('at')} </> : <>{player.events.Goal.filter((d) => d.detail === "Own Goal").length} {t('Own Goal')} {t('at')} </>}

{player.events.Goal.filter((d) => d.detail === "Own Goal").map((goal, index) => {
  return <span key={index}>{index!==0 && ", "}{goal.time.elapsed}m</span>
})}

</>}

</IonLabel>

<IonIcon 
                  icon={football}
                  color={player.events.Goal.filter((d) => d.detail === "Missed Penalty" || d.detail === "Own Goal" || d.detail === "PenaltyEndMissed").length > 0 ? "danger" : player.events.Goal.filter((d) => d.detail === "Penalty").length > 0 && "warning"}
                  slot={"end"}
                />
</IonItem>
}


{player.events && player.events.Assist && player.events.Assist.length > 0 && <IonItem>
<IonLabel class="ion-text-wrap">

{player.events.Assist.length === 1 ? <>1 {t('assist')} {t('at')} </> : <>{player.events.Assist.length} {t('assists')} {t('at')} </>}

{player.events.Assist
.map((goal, index) => {
return <span key={index}>{index!==0 && ", "}{goal.time.elapsed}m</span>
})

}</IonLabel>

<IonIcon 
                  icon={paw}
                  color={"mvp"}
                  slot={"end"}
                />
</IonItem>
}

{/* {player.events && player.events.Var && player.events.Var.length > 0 && <IonItem>
<IonLabel class="ion-text-wrap">

{player.events.Var.length === 1 ? <>1 {t('Var')} {t('at')} </> : <>{player.events.Var.length} {t('Var')} {t('at')} </>}

{player.events.Var
.map((goal, index) => {
return <span key={index}>{index!==0 && ", "}{goal.time.elapsed}m</span>
})

}</IonLabel>

<IonIcon 
                  icon={paw}
                  color={"mvp"}
                  slot={"end"}
                />
</IonItem>
}
 */}






















{player.events && player.events.Card && player.events.Card
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((card, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
if(card.detail === 'Yellow Card')
 
return <IonItem key={index}>

<IonLabel class="ion-text-wrap">{t('Yellow_card')} {t('at')} {card.time.elapsed}m</IonLabel>

<IonIcon key={index}
                  icon={tabletPortrait}
                  color={"jaune"}
                  slot={"end"}
                  
                /></IonItem>

if(card.detail === 'Second Yellow card')
 
return <IonItem key={index}>

<IonLabel class="ion-text-wrap">{t('Red_card')} {t('at')} {card.time.elapsed}m</IonLabel>

<IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  slot={"end"}
                  
                /></IonItem>

if(card.detail === 'Red Card')
 
return <IonItem key={index}>

<IonLabel class="ion-text-wrap">{t('Red_card')} {t('at')} {card.time.elapsed}m</IonLabel>

<IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  slot={"end"}
                  
                /></IonItem>

})


}  








{player.events && player.events.subst && player.events.subst
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((event, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonItem  key={index}>

{event.player.id !== player.player.id ? 
<IonLabel class="ion-text-wrap">{t('Substitute')} {event.player.name} {t('at')} {event.time.elapsed}m</IonLabel> :
<IonLabel class="ion-text-wrap">{t('Out_at')} {event.time.elapsed}m {t('by')} {event.assist.name}</IonLabel>
}
<IonIcon
icon={event.player.id === player.player.id ? returnUpBack : returnUpForward}
color={event.player.id === player.player.id ? "danger" : "success"}
slot={"end"}
                  
/></IonItem>
})


}  







</IonList>}



   </IonCol>


        <IonCol style={{
     paddingLeft: 0,
     paddingRight: 0,
     paddingTop: 0,
     paddingBottom: 0,
   }}>
   <IonList lines={"inset"} style={{
     paddingBottom: 0
   }}>
   {showNotesAverage && <IonItem button={true} onClick={handleShowNotesMedias}>
             
             <IonText
                slot='end'

   size={"small"}
   style={{
     verticalAlign: "middle",
     paddingRight: "0px",
     marginInlineStart: "0px",
     color: "#777777",
     fontSize: "14px",

   }}
 >{t('Average_notes')}
 </IonText> 
             <IonIcon
                slot='end'
                  icon={arrowUpOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "4px",
                  }}
                />{" "}

             </IonItem>}
             <IonItem button={true} onClick={handleShowNotesMedias}>
             
                <IonLabel>{t('Medias')}</IonLabel>
                           
                


                <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {(player.mediasRating && player.mediasRating.totalUsersCount) || 0}
                </IonText>
                <IonIcon
                  icon={globe}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>
                

                {player.mediasRating && <IonBadge className="square" color={voteToColor(player.mediasRating.averageRating)}>{player.mediasRating.averageRating >= 10 ? 10 : player.mediasRating.averageRating.toFixed(1)}</IonBadge>}
                {!player.mediasRating && <IonBadge className="square" color={"light"}>-</IonBadge>}


                
              </IonItem>
            </IonList>

            <IonList lines={"none"} style={{
     paddingTop: 0
   }} >
        {data && player.medias && showNotesMedias && Object.keys(player.medias)
      .sort((a, b) => player.medias[a].note < player.medias[b].note ? 1 : -1)
      .map((note, index) => {

        const media = player.medias[note].media;

        return <IonItem 
        key={index} 
        button={medias && medias[media]}
        onClick={() => medias && medias[media] && openBrowser(medias[media])}

        >

        <IonLabel>{providerList[media] ? providerList[media].name : media}</IonLabel>


        {note === "sorare" ? <IonBadge color={voteToColor(player.medias[note].note)} slot="end" className="position3">{player.medias[note].note.toFixed(1)}</IonBadge> : <IonBadge color={voteToColor(player.medias[note].note)} slot="end" className="position3">{player.medias[note].note}</IonBadge>}

        </IonItem>
      })}
      </IonList>
        </IonCol>

        {player.sorare && <SorareScoreDetailed onClick={handleShowFull} template="small" col="12" data={player.sorare} />}

      </IonRow>}

      {data && player.usersRating && player.usersRating.totalNotes && showNotesStat && <>
      
      {Object.keys(player.usersRating.totalNotes).map((note, index) => (
          <IonItem key={index} className="ion-text-center">

            <IonBadge color={note} slot="start" className="position">{note}</IonBadge>
            
            <IonProgressBar slot="end" color="primary" value={(player.usersRating.totalNotes[note] / player.usersRating.totalUsersCount)}></IonProgressBar>
            
            <IonText slot="end" style={{
                    color: "#777777",
                    width: "70px",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.usersRating.totalNotes[note]}
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />
                </IonText>
          </IonItem>
        ))}
        <IonItem>
          
            
            <IonLabel>
            <IonButton
                onClick={() => handleShowNotesMedias()}                
              >{t("Back")}</IonButton>
            </IonLabel>

  
          </IonItem></>}
        

      
      

          {isComponent && player && !showNotesStat && !showStatsSorare && <img  alt="o" src={player.img}  style={{
                    zIndex: "1000",
                    position: "absolute",
                    bottom: "0px",
                    width: "300px",
                    right: "10px",
                  }} />}

      </IonContent>
      {/* <CommentModal
          isOpen={showModal}
          title="New Comment"
          sendAction={handleAddComment}
          closeAction={handleCloseModal}
        /> */}
    </IonModal>
    
  );
};

export default PlayerModal;
