import React from "react";
import { IonPage, IonContent } from "@ionic/react";

import LargeHeader from "../../components/Header/LargeHeader";
import PlayersList from "../../components/Player/PlayersList";
import { useTranslation } from "react-i18next";

const Players = (props) => {
  const { t } = useTranslation();

  const [tab, setTab] = React.useState("notes");

  function handleChangeTab(tab) {
    setTab(tab);
  }

  const [league, setLeague] = React.useState("l_4.s_2024");

  function handleChangeLeague(league) {
    setLeague(league);
  }

  return (
    <IonPage>
      <LargeHeader actionLeague={handleChangeLeague} title={t('Players')} changeTab={handleChangeTab} />
      <IonContent fullscreen>

        <PlayersList team={null} league={league} />


      </IonContent>
      
    </IonPage>
  );
};

export default Players;
