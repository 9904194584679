import React, {useState, useEffect} from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import {
  IonSplitPane,
  IonMenu,
  IonHeader,
IonRow, IonGrid, IonFooter,
IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonContent,
  setupIonicReact,
  IonPage,IonListHeader, IonCol, 
  IonMenuToggle, IonLabel, IonItem,
  IonIcon,
  IonList,
  IonButton,
  IonAvatar, IonSkeletonText,

  IonApp
} from '@ionic/react';

import { initializeApp } from "firebase/app"
import firebaseConfig from "./firebase/config";

import Menu from "./components/Menu";

import { IonReactRouter } from "@ionic/react-router";
/* import {
  newspaperOutline,
  searchOutline,
  personCircleOutline,
  createOutline,
  trendingUpOutline,
  footballOutline
} from "ionicons/icons"; */


import Matchs from "./pages/Tabs/Matchs";
import Match from "./pages/Match";
import Social from "./pages/Social";
import Teams from "./pages/Tabs/Teams";
import Team from "./pages/Team";

import Players from "./pages/Tabs/Players";
import Player from "./pages/Player";



import News from "./pages/Tabs/News";
/* 
import Notes from "./pages/Tabs/Notes";
import Submit from "./pages/Tabs/Submit";
import Search from "./pages/Tabs/Search";
import Link from "./pages/Link";
import Example from "./pages/Example"; */


import Profile from "./pages/Tabs/Profile";
import EditProfile from "./pages/Auth/EditProfile";
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import Forgot from "./pages/Auth/Forgot";
import useAuth from "./hooks/useAuth";
import UserContext from "./contexts/UserContext";


import moment from 'moment-timezone';

import './i18n';
//import i18n from "i18next";

import { useTranslation } from "react-i18next";



/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";



moment.locale('fr', {
  months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact : true,
  weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact : true,
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
      sameDay : '[Aujourd’hui à] LT',
      nextDay : '[Demain à] LT',
      nextWeek : 'dddd [à] LT',
      lastDay : '[Hier à] LT',
      lastWeek : 'dddd [dernier à] LT',
      sameElse : 'L'
  },
  relativeTime : {
      future : 'dans %s',
      past : 'il y a %s',
      s : 'quelques secondes',
      m : 'une minute',
      mm : '%d minutes',
      h : 'une heure',
      hh : '%d heures',
      d : 'un jour',
      dd : '%d jours',
      M : 'un mois',
      MM : '%d mois',
      y : 'un an',
      yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
      return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse : /PD|MD/,
  isPM : function (input) {
      return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem : function (hours, minutes, isLower) {
      return hours < 12 ? 'PD' : 'MD';
  },
  week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // Used to determine first week of the year.
  }
});

setupIonicReact({
  rippleEffect: true,
  mode: 'md',
});




const App = (props) => {
  const [user, setUser] = useAuth();
  const { i18n } = useTranslation();

  initializeApp(firebaseConfig);

moment.locale(i18n.language);

  const menuRef = React.useRef(null);


  React.useEffect(() => {
    const handleLanguageChange = () => {
      moment.locale(i18n.language);
    };

    i18n.on("languageChanged", handleLanguageChange);

  }, [i18n]);


  const handleCloseMenu = () => {
    menuRef.current?.close();
  }

  return (
    <IonApp>
      <IonReactRouter>
        <UserContext.Provider value={{ user, setUser }}>
          {/* <IonTabs> */}
          <IonSplitPane contentId="main">
      {/*--  the side menu  --*/}
      <IonMenu ref={menuRef} style={{
                    "--side-max-width": "300px"
                  }}
      
       contentId="main" side="end" menuId="user" type="overlay">

<Menu closeMenu={handleCloseMenu} />
      
      </IonMenu>
            <IonRouterOutlet id="main">
              <Route
                path="/"
                render={() => <Redirect to="/matchs" />}
                exact={true}
              />
              <Route path="/matchs" component={Matchs} />
              <Route path="/profile" component={Profile} />
              <Route path="/edit-profile" component={EditProfile} />
              <Route path="/register" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/match/:matchId" component={Match} />
              <Route path="/social/:matchId" component={Social} />

              <Route path="/team/:teamId" component={Team} />
              <Route path="/teams" component={Teams} />

              <Route path="/players" component={Players} />
              <Route path="/player/:playerId" component={Player} />

              

              <Route path="/news" component={News} />
              {/* <Route path="/link/:linkId" component={Link} />
              <Route path="/news" component={News} />
              <Route path="/notes" component={Notes} />
              <Route path="/submit" component={Submit} />
              <Route path="/search" component={Search} />
              <Route path="/Example" component={Example} /> */}

              
              <Route component={() => <Redirect to="/" />} />
            </IonRouterOutlet>
            </IonSplitPane>

            {/* <IonTabBar color={"secondary"} slot="bottom">
              <IonTabButton tab="notes" href="/notes">
                <IonIcon icon={trendingUpOutline} />
                <IonLabel>{t('To_rate')}</IonLabel>
              </IonTabButton>
              <IonTabButton tab="matchs" href="/matchs">
                <IonIcon icon={footballOutline} />
                <IonLabel>{t('To_come')}</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs> */}
        </UserContext.Provider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
