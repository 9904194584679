import React from "react";

import { collection, query, where,orderBy, onSnapshot, limit, getDocs, startAfter, getFirestore } from "firebase/firestore";

import MatchItem from "../Match/MatchItem";
//import LargeHeader from "../Header/LargeHeader";
import moment from 'moment-timezone'

import {
  IonList,
  IonListHeader,
  IonInfiniteScroll, IonInfiniteScrollContent,

  IonSkeletonText,
  IonAvatar, IonItem, IonLabel
} from "@ionic/react";
const TeamMatchsList = (props) => {
  const [matchs, setMatchs] = React.useState(null);
  const [lastVisibleDoc, setLastVisibleDoc] = React.useState(null);
  const [disabledInfinite, setDisabledInfinite] = React.useState(false);

  //const isTrending = props.location.pathname.includes("notes");
  const nblimit = 20;

  React.useEffect(() => {

    setMatchs(null);

    let order = ">";
    if(props.tab==="notes") order = "<";

    const q = query(collection(getFirestore(), "matchs"), 
    orderBy("fixture.date", "desc"), 
      where('fixture.date', order, moment().format()),
      limit(nblimit)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      const links = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      
      setMatchs(props.tab==="notes" ? links : links.reverse());
    });

    //return unsubscribe;
    return () => unsubscribe();
  }, [props.tab]);

  const loadData = async (ev) => {

    let order = ">";
    if(props.tab==="notes") order = "<";

    const q = query(collection(getFirestore(), "matchs"), 
    orderBy("fixture.date", "desc"), 
      where('fixture.date', order, moment().format()),
      startAfter(lastVisibleDoc),
      limit(nblimit)
    );

    const querySnapshot = await getDocs(q);

    ev.target.complete();

    const links = querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    const m = props.tab==="notes" ? links : links.reverse();

    setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

    if(nblimit !== querySnapshot.docs.length) setDisabledInfinite(true);

    setMatchs([...matchs, ...m]);
    
  };

  function renderSkeleton() {
    return <IonItem className="ion-text-center match-item">
    <IonAvatar slot="start">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
    <IonLabel style={{ 'height': '67px', marginTop: '8px'}}>
    <p style={{ 'width': '200px', margin: '0 auto', marginTop: '8px'}}>
        <IonSkeletonText animated={true} ></IonSkeletonText>
      </p>
      <p style={{ 'width': '300px', margin: '0 auto'}}>
        <IonSkeletonText animated={true}></IonSkeletonText>
      </p>
      <p style={{ 'width': '150px', margin: '0 auto'}}>
        <IonSkeletonText animated={true}></IonSkeletonText>
      </p>
    </IonLabel>
    <IonAvatar slot="end">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
  </IonItem>
}


  return (
    <>        
    {matchs && matchs.length > 0 && <IonList lines="none" style={{paddingTop: 0}}>

      {matchs.map((match, index) => (
        <MatchItem
          bg={index %2}
          key={match.fixture.id}
          //showNotes={type === "notes"}
          url={`/match/${match.fixture.id}`}
          data={match}
        />
      ))}
      </IonList>}

      <IonInfiniteScroll
          onIonInfinite={loadData}
          //threshold="10%"
          //threshold="100px"
          disabled={!matchs || disabledInfinite}
        >
          <IonInfiniteScrollContent
            //loadingSpinner="bubbles"
            //loadingText={t('Loading...')}
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      {!matchs &&
        <IonList lines="full" style={{paddingTop: 0}}>
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
        </IonList>
      }

      {matchs && matchs.length === 0 &&
        <IonList lines="full" style={{paddingTop: 0}}>
         
        </IonList>
      }

    </>
  );
};

export default TeamMatchsList;
