import React from "react";
import {
  IonAvatar,
  IonLabel,
  IonIcon,
  IonText,
  IonItem,
  IonImg,
  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  chevronUpCircleOutline,
  personCircleOutline,
  timeOutline,
} from "ionicons/icons";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import MatchNotes from "./MatchNotes";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";

function matchStatus(t, data) {

  if(data.fixture.status.short === "NS" || data.fixture.status.short === "AET" || data.fixture.status.short === "ET" || data.fixture.status.short === "P") {
    
      return moment(data.fixture.date).local().fromNow();
      //formatDistanceToNow(moment(link.time.starting_at.date_time).tz("Europe/Paris").unix())
    
  } else if(data.fixture.status.short === "LIVE" || data.fixture.status.short === "1H" || data.fixture.status.short === "2H") {
    if(data.fixture.status.elapsed === 90 || data.fixture.status.elapsed === 120) return t('Voting_in_progress') + "..."
    else return data.fixture.status.elapsed+"m"
  } else if(data.fixture.status.short === "HT" || data.fixture.status.short === "BT") {
    return t('Half_time');
  } else if(data.fixture.status.short === "FT" || data.fixture.status.short === "PEN") {
    if((data.fixture.status.elapsed === 90 || data.fixture.status.elapsed === 120) && moment().format() < moment(data.fixture.date).local().add(15, 'hours').format()) {
      return t('Voting_in_progress') + "!";
    }
    
    else return t('Ended') + " " + moment(data.fixture.date).local().fromNow();
  } else if(data.fixture.status.short === "CANC" || data.fixture.status.short === "PST" || data.fixture.status.short === "INT" || data.fixture.status.short === "ABD" || data.fixture.status.short === "SUSP" || data.fixture.status.short === "TBD" || data.fixture.status.short === "WO" || data.fixture.status.short === "AWD") {
    return t(data.fixture.status.short);
  } else {
    return "..."
  }
  

}

function matchLeague(t, league) {

  let name = league.name;
  let round = league.round;
  let stage = "";
  let journee = "";
  let day = 0;

  if(name === "Friendlies") {

      return t('Friendlie');

  }

  else if(name === "Euro Championship - Qualification") {

    stage = "qualifying";
    //if(league.round.includes("Qualifying")) stage = "qualifying";

    if(stage === "qualifying") {
      round = league.round.replace("Qualifying Round -", "");
      day = round.slice(round.length-1, round.length);

      if(day === "1") journee = t('st');
      else if(day === "2") journee = t('nd');
      else if(day === "3") journee = t('rd');
      else journee = t('th');

      return t('Euro Championship - Qualification') + " - " + round + journee + " " + t('day');

    }
  }

  else if(name === "Euro Championship") {

    stage = "group";
    if(league.round.includes("Round of")) stage = "round";
    if(round === "Quarter-finals" || round === "Semi-finals" || round === "3rd Place Final" || round === "Final") stage = "round";

    if(stage === "round") {
      round = league.round.replace("Round of ", "");

      if(round === "16") round = 8;
      if(round === "Quarter-finals") round = 4;
      if(round === "Semi-finals") round = 2;
      if(round === "3rd Place Final") round = 1.5;
      if(round === "Final") round = 1;
    
      journee = t(round+'final');

      return t('Euro Championship') + " - " + journee;
    }
      
    if(stage === "group") {
      round = league.round.replace("Group Stage -", "");
      day = round.slice(round.length-1, round.length);

      if(day === "1") journee = t('st');
      else if(day === "2") journee = t('nd');
      else if(day === "3") journee = t('rd');
      else journee = t('th');

      return t('Euro Championship') + " - " + round + journee + " " + t('day');
    }
  
}
  
  else if(name === "World Cup") {

      stage = "group";
      if(league.round.includes("Round of")) stage = "round";
      if(round === "Quarter-finals" || round === "Semi-finals" || round === "3rd Place Final" || round === "Final") stage = "round";

      if(stage === "round") {
        round = league.round.replace("Round of ", "");

        if(round === "16") round = 8;
        if(round === "Quarter-finals") round = 4;
        if(round === "Semi-finals") round = 2;
        if(round === "3rd Place Final") round = 1.5;
        if(round === "Final") round = 1;
      
        journee = t(round+'final');

        return t('World Cup') + " - " + journee;
      }
        
      if(stage === "group") {
        round = league.round.replace("Group Stage -", "");
        day = round.slice(round.length-1, round.length);

        if(day === "1") journee = t('st');
        else if(day === "2") journee = t('nd');
        else if(day === "3") journee = t('rd');
        else journee = t('th');

        return t('World Cup') + " - " + round + journee + " " + t('day');
      }
    
  } else if(name === "UEFA Champions League") {

    round = league.round.replace("Group", "");

    journee = round.slice(round.length-1, round.length) === "1" ? "ère" : "ème";    
    return "Champions League - " + t('Group') + " " + round + journee + " journée";
  
  } else if(name === "Ligue 1") {
      round = league.round.replace("Regular Season - ", "");
    
      return name + " - " + round + "éme journée";
    //return data.fixture.status.short+"m"
  } else if(name === "UEFA Nations League") {
    round = league.round.replace("League", "Ligue");
    journee = round.slice(round.length-1, round.length) === "1" ? "ère" : "ème";    
    return "Ligue des nations - " + round + journee + " journée";
  
  //return data.fixture.status.short+"m"
  } else {
    return name + " - " + round;
  }

}



const MatchItem = ({ data, index, showNotes, url, browser, bg }) => {
  const { t } = useTranslation();

  if(!data.league) return;



  return (

        <>
          <IonItem color={"league-"+data.league.id} className="ion-text-center match-item" routerLink={url} onClick={browser} button>
            <IonAvatar slot="start">
              <img alt="o" src={data.teams.home.logo} />
            </IonAvatar>
            
            <IonLabel>
              <p
                style={{
                  alignItems: "center",
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                }}
              >
                {/* <IonImg
                  //icon={linkOutline}
                  
                  src={"https://media.api-sports.io/football/leagues/"+data.league.id+".png"}
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    height:"15px",
                  width:"15px"
                  }}
                />{" "} */}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {matchLeague(t, data.league)}
                </IonText>
              </p>

              <div className="ion-text-wrap" style={{marginBottom: "2px"}}>
                <strong style={{ fontSize: "1rem" }}>{data.teams.home.name} {data.goals.home} - {data.goals.away} {data.teams.away.name}</strong>
                {data.score && data.score.penalty && data.score.penalty.home && <div style={{ fontSize: "14px", marginTop: "4px",  marginBottom: "-1px" }}>({data.score.penalty.home} TAB {data.score.penalty.away})</div>}

              </div>



              <p
                style={{
                  alignItems: "center",
                  fontSize: "0.8 rem",
                  fontWeight: "normal",
                  marginBottom: 0
                }}
              >
                {/* <IonIcon
                  icon={chevronUpCircleOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {link.voteCount} upvotes             
                </IonText>
                
                {" | "} */}
                <IonIcon
                  icon={timeOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                    

                  {matchStatus(t, data)}


                    {
                      //moment(link.time.starting_at.date_time).tz("Europe/Paris").format()
                      }

                </IonText>

                {/* {" | "}
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  
                </IonText> */}
                
                {/* {link.comments.length > 0 && (
                  <>
                    {" | "}
                    <IonIcon
                      icon={chatbubbleEllipsesOutline}
                      style={{
                        verticalAlign: "middle",
                      }}
                    />{" "}
                    <IonText
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {link.comments.length} comments
                    </IonText>
                  </>
                )}{" "} */}
              </p>
            </IonLabel>

            <IonAvatar slot="end">
              <img alt="o" src={data.teams.away.logo} />
            </IonAvatar>
          </IonItem>

{/*           {data && data.events && matchEvents(data)}
 */}

          {/* {showNotes && <MatchNotes lineup={data.lineup} />} */}
{/* 
          <IonAccordionGroup>
      <IonAccordion value="first">
        <IonItem slot="header" color="light">
          <IonLabel>First Accordion</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          First Content
        </div>
      </IonAccordion>
      <IonAccordion value="second">
        <IonItem slot="header" color="light">
          <IonLabel>Second Accordion</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Second Content
        </div>
      </IonAccordion>
      <IonAccordion value="third">
        <IonItem slot="header" color="light">
          <IonLabel>Third Accordion</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Third Content
        </div>
      </IonAccordion>
    </IonAccordionGroup> */}

        </>

  );
};

export default MatchItem;
