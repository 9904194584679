import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      fr: {
        translation: {

          "best_third_placed": "meilleur troisième",

          "8final": "Huitième de finale",
          "4final": "Quart de finale",
          "2final": "Demi finale",
          "1.5final": "3ème place",
          "1final": "Finale",

          "8finals": "Huitième",
          "4finals": "Quart",
          "2finals": "Demi",
          "1finals": "Finale",

          "Qualified": "Qualifié",
          "Disqualified": "Éliminé",
          "in group": "en groupe",
          "group": "groupe",
          "of": "du",
          "in": "en",
          "Win vs": "A gagné vs",
          "Lost vs": "A perdu vs",
          "Own Goal": "CSC",
          "Missed penalty": "penalty raté",
          "Missed penaltys": "penalty ratés",
          

          
          

          "G": "G",
          "D": "D",
          "M": "M",
          "F": "A",
          "DG": "DG",
          "DC": "DC",
          "DD": "DD",
          "MG": "MG",
          "MC": "MC",
          "MD": "MD",
          "MDC": "MDC",
          "MO": "MO",
          "MOC": "MOC",
          "MOD": "MOD",
          "MOG": "MOG",
          "BU": "BU",
          "AG": "AG",
          "AD": "AD",

          "Substitutes": "Remplaçants",
          "My_ratings_of": "Mes notes de",
          "on": "sur",
          

          "goal": "but",
          "goals": "buts",
          "assist": "passe décisive",
          "assists": "passes décisives",
          "Yellow_card": "Carton jaune",
          "Red_card": "Carton rouge",
          "Out_at": "Remplacé à",
          "Substitute": "Remplace",



          "at": "à",
          "by": "par",

          

          

          "Rating_closed": "Note fermée",
          "See ratings": "Voir les notes",
          "Share Notes": "Partager les notes",

          "Loading...": "Chargement...",

          
          
          "Sign up": "Inscription",
          "Username": "Nom d'utilisateur",
          "Login": "Connexion",
          "Login with mail": "Connexion par mail",
          "Login with Twitter": "Connexion avec Twitter",
          "Log in to rate": "Connecte-toi pour noter",
          "Edit": "Modifier",
          "Logout": "Déconnexion",
          "Email": "Email",
          "Password": "Mot de passe",
          "Forgot_Password": "Mot de passe oublié",
          
          "Pseudo": "Pseudo",

          "Langue": "Langue",
          "New Password": "Nouveau mot de passe",
          "Current Password": "Mot de passe actuel",
          "Save": "Sauvergarder",
          "You have updated your profile successfully.": "Langue",


          "Edit Profile": "Modifier votre profil",
          "Save": "Sauvergarder",
          "Save": "Sauvergarder",



          "Friendlie": "Match amical",
          "World Cup": "Coupe du monde",
          "Euro Championship - Qualification": "Qualification Euro",
          "Euro Championship": "Euro",
          
          "day": "journée",
          "Group": "Groupe",
          "st": "ère",
          "nd": "ème",
          "rd": "ème",
          "th": "ème",

          "CANC": "Annulé",
          "PST": "Reporté",
          "INT": "Interrompu",
          "ABD": "Abandonné",
          "SUSP": "Suspendu",
          "TBD": "Temps à définir",
          "WO": "Victoire sur tapis vert",
          "AWD": "PB",

          "Half_time": "Mi temps",
          "Ended": "Terminé",          
          "Voting_in_progress": "Vote en cours",
          
          "Matchs": "Matchs",
          "Teams": "Equipes",
          "Players": "Joueurs",
          "Account": "Compte",

          

          "To_rate": "A noter",
          "To_come": "A venir",
          "Profile": "Profil",
          "Cancel": "Annuler",
          "Rate": "Noter",
          "See": "Voir",

          "MVP": "HDM",
          "Most_Valuable_Player": "Homme du match", 

          "Spectators": "Spectateurs",
          "Medias": "Médias",
          "spectatorsmediasstats": "Spectateurs + Medias + Stats",
          "Stats_Sorare": "Stats (Sorare)",
          "mynotes": "Mes notes",
          "Myrating": "Ma note",
          "Average_notes": "Note moyenne",

          "match_select_who": "Notes",
          "match_not_see": "Pas vu le match?",
          "Your_rating_of_his_match": "Ta note de son match?",
          "match_not_see_button": "Voir les notes",

          "Share_your_ratings": "Partager tes notes",
          "on_Twitter": "sur Twitter",
          
          "Score_Sorare": "Note Sorare",








          "ontarget_scoring_att": "Tirs cadrés",
          "won_contest": "Dribble réussi ",
          "pen_area_entries": "Incursions dans la surface",
          "big_chance_missed": "Grosse occasion ratée",

          "clean_sheet_60": "Clean sheet",
          "effective_clearance": "Dégagement réussi",
          "won_tackle": "Tacle réussi",
          "blocked_cross": "Centre bloqué",
          "outfielder_block": "Blocage d'un joueur",
          "double_double": "Double-double",
          "triple_double": "Triple-double",
          "triple_triple": "Triple-triple",

          "yellow_card": "Carton jaune",
          "fouls": "Fautes",
          "error_lead_to_shot": "Erreur menant à un tir",
          "goals_conceded": "Buts encaissés",
          "penalty_kick_missed": "Penalty manqué",
          "was_fouled": "Fautes subies",

          "saves": "Arrêts",
          "saved_ibox": "Sauvetage dans la surface",
          "good_high_claim": "Centre capté",
          "punches": "Dégagements aux poings",
          "dive_save": "Arrêt plongeon",
          "dive_catch": "Arrêt plongeon",
          "cross_not_claimed": "Centre non dégagé",
          "six_second_violation": "Règle des six secondes",
          "gk_smother": "Balle captée",
          "accurate_keeper_sweeper": "Sortie réussie",

          "poss_lost_ctrl": "Ballon perdu",
          "poss_won": "Ballon gagné",
          "duel_lost": "Duel perdu",
          "duel_won": "Duel gagné",
          "interception_won": "Interception réussie",

          "big_chance_created": "Grosse occasion créée",
          "adjusted_total_att_assist": "Dernières passes menant à un tir",
          "accurate_pass": "Passe réussie",
          "successful_final_third_passes": "Passes réussies dans le dernier tiers",
          "accurate_long_balls": "Longs ballons réussis",
          "long_pass_own_to_opp_success": "Passe longue dans le camp adverse",
          "missed_pass": "Passe ratée",

          "POSSESSION": "Possession",
          "PASSING": "Passes",
          "GOALKEEPING": "Gardien",
          "GENERAL": "Général",
          "DEFENDING": "Défense",
          "ATTACKING": "Attaque",
          "UNKNOWN": "Résultats",
          
          "level_score": "Score décisif",
          "dive_sagve": "Note",



          
        }
      },
      en: {
        translation: {
          "best_third_placed": "best third-placed",

          "8final": "Huitième de finale",
          "4final": "Quarter-final",
          "2final": "Semi final",
          "1final": "Finale",

          "8finals": "Huitième",
          "4finals": "Quarter",
          "2finals": "Semi",
          "1.5final": "3rd place",
          "1finals": "Finale",
          
          "G": "G",
          "D": "D",
          "M": "M",
          "F": "F",
          "DG": "WB",
          "DC": "CB",
          "DD": "WB",
          "MG": "WM",
          "MC": "CM",
          "MD": "WM",
          "MDC": "DM",
          "MO": "AM",
          "MOC": "AM",
          "MOD": "W",
          "MOG": "W",
          "BU": "AF",
          "AG": "AF",
          "AD": "AF",

          "Substitutes": "Substitutes",
          "My_ratings_of": "My ratings of",

          

          "Yellow_card": "Yellow card",
          "Red_card": "Red card",
          "Out_at": "Substitute at",
          "Substitute": "Substitute",

          "CANC": "Cancelled",
          "PST": "Postponed",
          "INT": "Interrupted",
          "ABD": "Abandoned",
          "SUSP": "Suspended",
          "TBD": "Time To Be Defined",
          "WO": "WalkOver",
          "AWD": "PB",

          "Half_time": "Halftime",
          "Ended": "Ended",          
          "Voting_in_progress": "Rating in progress",
          "To_rate": "To rate",
          "To_come": "To come",
          "Profile": "Profile",
          "Cancel": "Cancel",
          "Rate": "Rate",
          "See": "See",

          "MVP": "MVP",
          "Most_Valuable_Player": "Most Valuable Player",

          
          "Spectators": "Spectators",
          "spectatorsmediasstats": "Spectators + Medias + Stats",
          "Stats_Sorare": "Stats (Sorare)",
          "mynotes": "My ratings",
          "Myrating": "My rating",
          "Average_notes": "Average rating",
          
          "match_select_who": "Ratings",
          "match_not_see": "Haven't seen the game?",
          "Your_rating_of_his_match": "Your rating of his match?",
          "match_not_see_button": "See ratings",

          "Share_your_ratings": "Share your ratings",
          "on_Twitter": "on Twitter",
          
          "Score_Sorare": "Score Sorare",
          
          


          "Forgot_Password": "Forgot Password?",
          "Rating_closed": "Rating closed",


          

        }
      }
    },
    //lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


export default i18n;