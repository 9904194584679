import React from "react";
import TeamItem from "./TeamItem";

import { collection, query, where, orderBy, onSnapshot, getFirestore } from "firebase/firestore";

import {
  IonList,
  IonSkeletonText,
  IonAvatar, IonItem, IonLabel
} from "@ionic/react";
const TeamsList = ({league}) => {
  const [teams, setTeams] = React.useState(null);

  React.useEffect(() => {

    var orderByT = "league."+league+".last_update";
    var whereT = "league."+league+".qualified";

    const q = query(collection(getFirestore(), "teams"), 
      orderBy(orderByT, "desc"), 
      where(whereT, '==', 1)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      const allteams = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      allteams.length > 0 ? setTeams(allteams) : setTeams([]);//.reverse()
    });

    //return unsubscribe;
    return () => unsubscribe();
  }, [league]);

  function renderSkeleton() {
    return <IonItem>
    <IonAvatar slot="start">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
    <IonLabel>
        <IonSkeletonText animated={true} style={{ 'width': '130px' }} ></IonSkeletonText>
        <p>
        <IonSkeletonText animated={true} style={{ 'width': '130px' }} ></IonSkeletonText>
        </p>
    </IonLabel>
  </IonItem>
}

  return (
    <>        
    {teams && teams.length > 0 && <IonList lines="full" style={{paddingTop: 0}}>

      {teams.map((link, index) => (
        <TeamItem
          bg={index %2}
          //color={true}
          league={league}
          key={link.team.id}
          //showNotes={type === "notes"}
          url={`/team/${link.team.id}`}
          data={link}
        />
      ))} 
    </IonList>}

    {!teams &&
        <IonList lines="full" style={{paddingTop: 0}}>
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
        </IonList>
      }

      {teams && teams.length === 0 &&
        <IonList lines="full" style={{paddingTop: 0}}>
         
        </IonList>
      }

    </>
  );
};

export default TeamsList;
