const providerList = {
  
  "lequipe": {name: "L'équipe", lang: "fr", type: 1},
  "eurosport": {name: "Eurosport", lang: "fr", type: 1},
  
  //"leparisien": {name: "Le Parisien", lang: "fr", type: 2},
  "footmercato": {name: "FootMercato", lang: "fr", type: 2},
  "maxifoot": {name: "Maxifoot", lang: "fr", type: 2},
  "sofoot": {name: "Sofoot", lang: "fr", type: 2},
  //"mpg": {name: "MPG", lang: "fr", type: 2},

  //"lephoceen": {name: "Le Phocéen", lang: "fr", type: 3},
  //"canalsupporters": {name: "Canal Supporters", lang: "fr", type: 3},

  //"lephoceenjj": {name: "Le Phocéen", lang: "fr", type: 3},

  "sorare": {name: "Sorare", lang: "fr", type: 4},

  
  "lagazzettadellosport": {name: "La Gazzetta dello Sport", lang: "it", type: 1},
  "goal": {name: "Goal", lang: "us", type: 1},

};

export default providerList