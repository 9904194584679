import React, {useEffect} from "react";
import {

IonRow, IonGrid, IonFooter,

  IonContent,
  IonListHeader, IonCol, 
  IonMenuToggle, IonLabel, IonItem,
  IonIcon,
  IonList,
  IonButton,
  IonAvatar, IonSkeletonText,
  IonSelect, IonSelectOption             

} from '@ionic/react';

import {
  trendingUpOutline,
  footballOutline,
  settingsOutline,
  logoTwitter,
  globe,
  logoInstagram,
  mail,
  peopleCircleOutline,
  logoDiscord,
  calendarNumberOutline
} from "ionicons/icons";

import { useLocation } from "react-router-dom";

import UserContext from "../contexts/UserContext";

import { useTranslation } from "react-i18next";
import { Browser } from '@capacitor/browser';



const Menu = ({closeMenu}) => {
  const { user } = React.useContext(UserContext);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [language, setLanguage] = React.useState(i18n.language || "en-US");

  const pages = [
    { title: t('Matchs'), path: '/matchs', path2: '/match', icon: footballOutline },
    //{ title: 'matchs', path: '/matchs', icon: settingsOutline },
    //{ title: 'Classement', path: '/news', icon: trendingUpOutline },
    { title: t('Teams'), path: '/teams', path2: '/team', icon: globe },
    { title: t('Players'), path: '/players', path2: '/player', icon: peopleCircleOutline }
    
  ];

  const renderMenuItems = () => {
    return pages.map((page) => (
            <IonItem button
            key={page.path}
            routerLink={page.path}
                color={location && (location.pathname === page.path || "/"+location.pathname.split("/")[1] === page.path2) ? '' : 'primary'}
                onClick={() => navigateToPage(page)}>
                <IonIcon slot="start" icon={page.icon} style={{
                marginInlineEnd: "12px"
              }} />
                <IonLabel>
                    {page.title}
                </IonLabel>
            </IonItem>
    ));
}

const navigateToPage = (page) => {
    //history.push(page.path);
    //setActivePage(page.title);
    //menuRef.current?.close();
    closeMenu();
}


async function openBrowser(url) {
  await Browser.open({
    url: url,
  });
}

const handleSelectLang = (vote) => {
  const lang = vote.target.value === "en" ? "en-US" : "fr-FR";
  setLanguage(vote.target.value)
  i18n.changeLanguage(lang);
}
  return (<>
      {/* <Profile /> */}
        {/* <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>MVP <IonIcon icon={footballOutline} style={{
                    "--side-max-width": "300px"
                  }} /></IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonContent color={"primary"}>
        <IonList lines="none" style={{
                    padding: 0,
                  }}>
                <IonItem color={"primary"}>
              <IonLabel className="ion-text-wrap">
              </IonLabel>
              <IonSelect value={language.split("-")[0]} color="mvp" mode="ios" interface="popover"
                onIonChange={(e) => handleSelectLang(e)}
                >
                  <IonSelectOption value="en">English</IonSelectOption>
                  <IonSelectOption value="fr">Francais</IonSelectOption>
                </IonSelect>
            </IonItem>
            </IonList>

        <IonRow style={{ marginTop: '60px' }}>
        <IonAvatar style={{ margin: '0 auto', '--border-radius': '6px', height: "100px", width: "100px" }}>
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
</IonRow>
       

        <IonList lines="none" style={{
                    marginTop: "100px",
                    padding: 0,
                  }}>
                    { renderMenuItems() }
                </IonList>
                <IonList lines="none" style={{
                    marginTop: "50px",
                    padding: 0,
                  }}>
        <IonListHeader color="primary">
        {t('Account')}
        </IonListHeader>
        <IonMenuToggle auto-hide="false">
          <IonItem button routerLink={"/profile"} 
                    color={location && (location.pathname === "/profile" || location.pathname === "/edit-profile" || location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/forgot") ? '' : 'primary'}
          
                    //onClick={() => navigateToPage({title: "profile"})}
                    >
            <IonIcon slot="start" icon={settingsOutline} style={{
                    marginInlineEnd: "12px"
                  }}></IonIcon>
            <IonLabel>
              {user ? t('Profile') : t('Login')}
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      </IonList>
        

                
      </IonContent>
      <IonFooter style={{
                    background: "#0d3aff",
                    //padding: 0,
                  }}>
<IonRow>
             <IonGrid style={{
                  textAlign: "center",
                }}>

          
              <IonCol size="6">
              <IonButton
                onClick={() => openBrowser(i18n.language === "fr" ? "https://twitter.com/MVP_FOOTBALL_FR" : "https://twitter.com/MVP_FOOTBALL_EN")}
                fill="outline"
                color="twitter"
                
              >
              <IonIcon style={{
                  '--height': "60px",
                }} color="twitter" icon={logoTwitter} slot="end"></IonIcon>
                {t('Twitter')}
              </IonButton>
              
              </IonCol>
              <IonCol size="6">

              
              <IonButton
                onClick={() => openBrowser("https://www.instagram.com/mvpfootball_en/")}
                fill="outline"
                color="instagram"
                
              >
              <IonIcon style={{
                  '--height': "60px",
                }} color="instagram" icon={logoInstagram} slot="end"></IonIcon>
                {t('Insta')}
              </IonButton>

              </IonCol>

              <IonCol size="6">

              <IonButton
                onClick={() => openBrowser("https://discord.gg/wP5MhTeZKP")}
                fill="outline"
                color="discord"
                
              >
              <IonIcon style={{
                  '--height': "60px",
                }} color="discord" icon={logoDiscord} slot="end"></IonIcon>
                {t('Discord')}
              </IonButton>

              
              
              </IonCol>
              <IonCol size="6">

              <IonButton
                onClick={() => openBrowser("mailto:mvp-football@proton.me")}
                fill="outline"
                color="light"
                
              >
              <IonIcon style={{
                  '--height': "60px",
                }} 
                color="light" 
                icon={mail} slot="end"></IonIcon>
                {t('Contact')}
              </IonButton>

              </IonCol>
             </IonGrid>
             </IonRow>

             {/* <Countdown /> */}

            

    </IonFooter>
    </>
  );
};

export default Menu;
