function voteToColor(vote, m) {
    if(vote < (1 * m)) return "0";
    else if(vote < (2* m)) return "1";
    else if(vote < (3* m)) return "2";
    else if(vote < (4* m)) return "3";
    else if(vote < (5* m)) return "4";
    else if(vote < (6* m)) return "5";
    else if(vote < (7* m)) return "6";
    else if(vote < (8* m)) return "7";
    else if(vote < (9* m)) return "8";
    else if(vote < (10* m)) return "9";
    else if(vote >= (10* m)) return "10";
  }
  
  export default voteToColor;
