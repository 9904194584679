import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
  IonImg,
  IonSkeletonText, IonAvatar, 
  IonMenuButton
} from "@ionic/react";

import { useTranslation } from "react-i18next";

import {
  trendingUpOutline,
  
} from "ionicons/icons";
import { IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';

const MatchHeader = ({ title, teamVisitor, teamHome, color, color_text, actionVoteFor, voteFor, showVote, actionVote, openVote }) => {
  const { t } = useTranslation();
  
  //const elementRef = React.useRef();

  React.useEffect(() => {

    if(openVote) actionVoteFor('home');  else actionVoteFor('noteClose');

    // eslint-disable-next-line
  }, [openVote]);

  /* React.useEffect(() => {
    const divElement = elementRef.current;
    divElement.style.setProperty('--background', color);
    divElement.style.setProperty('--color', color_text);
  }, [team]); */

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonBackButton  defaultHref="/" />
                    {/* <IonButton ref={elementRef} onClick={() => actionVoteFor()}>
                    <IonImg style={{

                    height:"30px",
                    marginLeft: "6px",
                  width:"30px"}} src={team} slot="end" />
                    {t('See')} </IonButton> */}

        </IonButtons>
        <IonButtons slot="start">
        </IonButtons>
        <IonButtons slot="primary">
          
            {openVote && <IonButton 
            style={{
                width: "140px",
                //fontSize: "0.8rem",
                marginRight: "12px",

                fontWeight: "normal",
              }} 
            fill="outline" 
            onClick={() => actionVote()}>
            {/* <IonIcon style={{
                  '--height': "60px",
                }} color="light" icon={trendingUpOutline} slot="end"></IonIcon> */}
                {showVote ? t('See ratings') : t('Rate')}</IonButton>}
{/*                 <IonMenuButton></IonMenuButton>
 */}
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        <IonSegment value={voteFor}>
        <IonSegmentButton value="home" onClick={() => actionVoteFor('home')}>
          {teamHome ? <IonImg style={{

            height:"30px",
            marginLeft: "6px",

            width:"30px"}} src={teamHome} /> : <IonAvatar style={{height: "30px",marginLeft: "6px",width:"30px"}}><IonSkeletonText animated={true}></IonSkeletonText></IonAvatar> }
        </IonSegmentButton>
        <IonSegmentButton value="visitor" onClick={() => actionVoteFor('visitor')}>
          {teamVisitor ? <IonImg style={{

            height: "30px",
            marginLeft: "6px",
            width:"30px"}} src={teamVisitor} /> : <IonAvatar style={{height: "30px",marginLeft: "6px",width:"30px"}}><IonSkeletonText animated={true}></IonSkeletonText></IonAvatar> }
        </IonSegmentButton>

        {!openVote && <IonSegmentButton value="noteClose" disabled={false} onClick={() => actionVoteFor('noteClose')}>
        {teamHome ? t('Rating_closed') : <IonSkeletonText animated={true} style={{width:"280px"}}></IonSkeletonText>} 
        </IonSegmentButton>}

        

      </IonSegment>
      {/* <IonButtons slot="end">
              <IonMenuButton></IonMenuButton>
            </IonButtons> */}
      </IonToolbar>
    </IonHeader>
  );
};

export default MatchHeader;
