import React from "react";
import PlayerItem from "./PlayerItem";
import { useTranslation } from "react-i18next";

import { collection, query, where, limit, orderBy, onSnapshot, startAfter, getDocs, getFirestore } from "firebase/firestore";

import {
  IonList,
  IonSkeletonText, IonSelect, IonSelectOption,
  IonAvatar, IonItem, IonLabel, IonBadge,
  IonInfiniteScroll, IonInfiniteScrollContent,

} from "@ionic/react";
const PlayersList = ({team, league}) => {
  const [players, setPlayers] = React.useState(null);
  const [wichNote, setWichNote] = React.useState('ratingAverage');
  const [lastVisibleDoc, setLastVisibleDoc] = React.useState(null);
  const [disabledInfinite, setDisabledInfinite] = React.useState(false);

  

  const { t } = useTranslation();

  const nblimit = 30;


  React.useEffect(() => {
    let q = null;
    //if(!wichNote) wichNote = 'ratingAverage';
      //console.log(wichNote)
    let order = 'league.'+league+'.' + wichNote + '.rating';
    if(wichNote === "ratingAverage") order = 'league.'+league+'.' + wichNote;

    //var orderByT = "league."+league+".last_update";
    var whereT = "league."+league+".qualified";
    
    if(team) {
      console.log(whereT)
      setDisabledInfinite(true);
      
      q = query(collection(getFirestore(), "players"), 
      ////orderBy('league.l_1.s_2022.' + wichNote, "desc"), 
      where('team_id', '==', "t_" + team),
      where(whereT, '==', 1)
      );
    } else {
      q = query(collection(getFirestore(), "players"), 
      orderBy(order, "desc"), 
      where(whereT, '==', 1),
      limit(nblimit)
      );
    } 

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      const allPlayers = querySnapshot.docs
      .map((doc) => {
        return { id: doc.id, ...doc.data() };
      })
      .filter((a) => a.league && a.league.l_1 && a.league.l_1.s_2022 && a.league.l_1.s_2022[wichNote] )

      if(team && wichNote === "ratingAverage") allPlayers
      .sort((a, b) => a.league.l_1.s_2022[wichNote] > b.league.l_1.s_2022[wichNote] ? 1 : -1)
      .reverse();

      if(team && (wichNote === "ratingMedias" || wichNote === "ratingSorare")) allPlayers
      .sort((a, b) => a.league.l_1.s_2022[wichNote].rating > b.league.l_1.s_2022[wichNote].rating ? 1 : -1)
      .reverse();

      setPlayers(allPlayers); //.reverse()
    });

    //return unsubscribe;
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [team, wichNote, league]);

  const loadData = async (ev) => {

    let order = 'league.'+league+'.' + wichNote + '.rating';
    if(wichNote === "ratingAverage") order = 'league.'+league+'.' + wichNote;
    var whereT = "league."+league+".qualified";

    const q = query(collection(getFirestore(), "players"), 
      orderBy(order, "desc"), 
      where(whereT, '==', 1),
      startAfter(lastVisibleDoc),
      limit(nblimit)
    );
  
    const querySnapshot = await getDocs(q);

    ev.target.complete();

    const allPlayers = querySnapshot.docs
    .map((doc) => {
        return { id: doc.id, ...doc.data() };
    })

    if(nblimit !== querySnapshot.docs.length) setDisabledInfinite(true);

    setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

    setPlayers([...players, ...allPlayers]);
    
  };

  function itemSelect() {

    const customPopoverOptions = {
      header: t('See'),
      //subHeader: 'Select your hair color',
      message: t('Rate')
    };

    return <IonItem style={{
      //position: "absolute",
      
    }}>
              <IonLabel className="ion-text-wrap">
                {/* <h2>{t('MATCH_')}</h2> */}
                <p>{t('match_select_who')}</p>
              </IonLabel>
              <IonSelect defaultValue="ratingAverage" value={wichNote} color="danger" mode="ios" interfaceOptions={customPopoverOptions} interface="action-sheet" cancelText={t('Cancel')}
              style={{
      maxWidth: "80%",
    }}
                onIonChange={(e) => setWichNote(e.target.value)}
                
                >
                  <IonSelectOption value="ratingAverage">{t('spectatorsmediasstats')}</IonSelectOption>
{/*                   <IonSelectOption value="spectators">{t('Spectators')}</IonSelectOption>
 */}                  <IonSelectOption value="ratingMedias">{t('Medias')}</IonSelectOption> 
                  <IonSelectOption value="ratingSorare">{t('Stats_Sorare')}</IonSelectOption> 
                    
                  
          
                </IonSelect>
            </IonItem>
  }

  function renderItemSelectSkeleton() {
    return <IonItem>
    
    <IonLabel className="ion-text-wrap">
    <p><IonSkeletonText animated={true} style={{width:"80px"}}></IonSkeletonText></p>
    </IonLabel>

    <IonSelect mode="ios" 
    interface="action-sheet"
              style={{
      maxWidth: "80%",
    }}>
    
    <IonSkeletonText animated={true}></IonSkeletonText>
             
    </IonSelect>
  </IonItem>
  }

  function renderSkeleton() {
    return <IonItem>
    <IonAvatar slot="start">
      <IonSkeletonText animated={true}></IonSkeletonText>
    </IonAvatar>
    <IonLabel>
        <IonSkeletonText animated={true} style={{ 'height': '14px', 'width': '130px' }} ></IonSkeletonText>
        {/* <p>
        <IonSkeletonText animated={true} style={{ 'width': '130px' }} ></IonSkeletonText>
        </p> */}
    </IonLabel>
    <IonSkeletonText style={{ 'height': '32px', 'width': '32px'}} animated={true} ></IonSkeletonText>
    </IonItem>
  }

  return (
    <>       
            
    {/* {players && players.length > 0 ? itemSelect() : renderItemSelectSkeleton()} */}
    {itemSelect()}
 
    {players && players.length > 0 && <IonList lines="full" style={{paddingTop: 0}}>

      {players.map((player, index) => (
        <PlayerItem
          bg={index %2}
          key={player.player.id}
          url={`/player/${player.player.id}`}
          player={player}
          wichNote={wichNote}
        />
      ))} 
    </IonList>}
    <IonInfiniteScroll
          onIonInfinite={loadData}
          //threshold="10%"
          //threshold="100px"
          disabled={!players || disabledInfinite}
        >
          <IonInfiniteScrollContent
            //loadingSpinner="bubbles"
            //loadingText={t('Loading...')}
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
    

    {!players &&
        <IonList lines="full" style={{paddingTop: 0}}>
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
         {renderSkeleton()}
        </IonList>
      }

      {players && players.length === 0 &&
        <IonList lines="full" style={{paddingTop: 0}}>
         
        </IonList>
      }

    </>
  );
};

export default PlayersList;
