import React from "react";
//import firebase from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function useAuth() {
  const [authUser, setAuthUser] = React.useState(null);

  React.useEffect(() => {
    /* const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthUser(user);
        //console.log(user)
      } else {
        setAuthUser(null);
      }
    }); */

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
  if (user) {
    setAuthUser(user);
  } else {

    //firebase.loginAnonymously();

    setAuthUser(null);
  }
});

    return () => unsubscribe();
  }, []);

  return [authUser, setAuthUser];
}

export default useAuth;
