import React, {useState} from "react";
import { IonHeader, IonToolbar, IonTitle, IonIcon, IonButtons, IonButton, IonTabBar,
  IonSegment,IonSegmentButton, IonMenuButton,
  IonTabButton, IonLabel} from "@ionic/react";
import {
  trendingUpOutline,
  footballOutline,
  settingsOutline,
  calendarNumberOutline
} from "ionicons/icons";
import { useTranslation } from "react-i18next";

const SmallHeader = ({ title, changeTab }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("notes");

  function handleChangeTab(e) {
    setValue(e.detail.value);
    changeTab(e.target.value);
  }

  return (
    <IonHeader >
      <IonToolbar
        color="primary"
        
      >
      {/* <IonButtons slot="end">
        

          <IonButton routerLink={"/profile"} style={{
                    //verticalAlign: "middle",
                    //marginTop: "6px",
                  }}><IonIcon

                  icon={settingsOutline}
                  
                /></IonButton>
          
          </IonButtons>  */}
          <IonSegment value={value} mode={"md"} onIonChange={(e) => handleChangeTab(e)}>
      <IonSegmentButton value="notes" layout="icon-end">
      <IonIcon icon={footballOutline} />
                <IonLabel>{t('To_rate')}</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value="matchs" layout="icon-end">
      <IonIcon icon={calendarNumberOutline} />
                <IonLabel>{t('To_come')}</IonLabel>
      </IonSegmentButton>
    </IonSegment>
    <IonButtons slot="end">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default SmallHeader;
