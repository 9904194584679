import React from "react";
import {
  IonAvatar,
  IonLabel,
  IonIcon,
  IonRow,
  IonBadge,
  IonCol, IonGrid, IonButton,
  IonItem,
  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  timeOutline,
} from "ionicons/icons";

import { useTranslation } from "react-i18next";

function leagueStatus(t, data) {

  let round = "";
  let rank = "";


  if(data && data.league && data.league.l_1 && data.league.l_1.y_2022) {
    
    if(data.league.l_1.y_2022.groupStage) {
      round = data.league.l_1.y_2022.groupStage.standing.group.replace("Group", "");
      round = t('Group') + " " + round;

      rank = data.league.l_1.y_2022.groupStage.standing.rank;
      
      if(rank === 1) rank = rank + t('st');
      else if(rank === 2) rank = rank + t('nd');
      else if(rank === 3) rank = rank + t('rd');
      else rank = rank + t('th');

      let txt = rank + " " + round
      return txt;
    } else if(data.league.l_1.y_2022.qualified) {
      return "qualified";
    }
    

    //  Group Stage

    
  }

}

function matchEventss(data, localteam_id) {


  var events = data.map((event, index) => {
   
return (
  // eslint-disable-next-line react/jsx-key
  <div key={index} className={index % 2 === 0 ? "roadmap roadmap-left" : "roadmap roadmap-right"}>
    <div className="roadmap-step">
      <div className="roadmap-head">
        <span 
         className="roadmap-title">{event.player.name}</span>
      </div>
      <p>{event.time.elapsed}</p>
    </div>
  </div>
)


      })

  return <div className="roadmap-grid" >  

    <div className="roadmap-line"></div>
    {events}
  </div> 
  
}

function matchEvents(data, localteam_id) {

  let local = [];
  let visitor = [];

  data.map((event, index) => {
    /* if(event.type==="subst") {
      console.log("event", event)

    } */
    if(event.type==="Goal") {

      if("t_" + event.team.id === localteam_id) local.push(event); else visitor.push(event);

    }       

    return true;
  })

  return (
          
    <IonGrid fixed={true} style={{
         //textAlign: "center",
       }}>
    <ion-row>
     <IonCol size="6" style={{
         textAlign: "right",
        }}>

     {local.map((event, index) => {
      return <div>{event.player.name} {event.time.elapsed}'</div>
     }
     )}

     
     
     </IonCol>
     <IonCol size="6" style={{
         //width: "50%",
       }}>

     {visitor.map((event, index) => {
      return <div>{event.time.elapsed}' {event.player.name} </div>
     }
     )}

     </IonCol>
     </ion-row>
    </IonGrid>
   
);
  
}

const MatchEvents = ({ data, localteam_id }) => {
  //const { t } = useTranslation();

  return matchEvents(data, localteam_id)
};

export default MatchEvents;
