
import React from "react";
import MatchItem from "./MatchItem";
import LargeHeader from "../Header/LargeHeader";
import {
  personCircleOutline,
} from "ionicons/icons";
import {
  IonList,
  IonBadge,
  IonItem,
  IonLabel,
  IonText,
  IonIcon

} from "@ionic/react";
const MatchPosition = ({position, background, color, marginLeft}) => {

  const elementRef = React.useRef();

  React.useEffect(() => {
    const divElement = elementRef.current;
    divElement.style.setProperty('--background', background);
    divElement.style.setProperty('--color', color);
  }, [color, background, position]);

  return (
       
    <IonBadge style={{
                    
      marginLeft: marginLeft,
    }} className="position" ref={elementRef} slot="start">{position}</IonBadge>
            
  );
};

export default MatchPosition;
