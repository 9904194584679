import React, { useEffect, useState } from "react";
import { IonHeader, IonToolbar, IonTitle, IonSelect, IonSelectOption, IonButtons, IonBackButton, IonMenuButton } from "@ionic/react";
import { useTranslation } from "react-i18next";

const LargeHeader = ({ title, backButton, actionLeague }) => {
  const { t } = useTranslation();
  const [selectedLeague, setSelectedLeague] = useState('');

  useEffect(() => {
    // Retrieve stored league value from local storage when the component mounts
    const storedLeague = localStorage.getItem('selectedLeague');
    if (storedLeague) {
      setSelectedLeague(storedLeague);
      actionLeague(storedLeague);  // Call actionLeague with the stored value
    }
  }, [actionLeague]);

  const handleLeagueChange = (value) => {
    // Store the selected league in local storage
    localStorage.setItem('selectedLeague', value);
    setSelectedLeague(value);
    actionLeague(value);
  };

  return (
    <IonHeader>
      <IonToolbar color="primary">
        {backButton && <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>}
        <IonTitle style={{ paddingInline: backButton ? 0 : 20 }}>
          {title}
        </IonTitle>
        <IonSelect
          slot="end"
          aria-label={t('Competition')}
          onIonChange={(e) => handleLeagueChange(e.detail.value)}
          value={selectedLeague} // Set the selected value
          placeholder={t('Competition')}
        >
          <IonSelectOption value="l_4.s_2024">Euro 2024</IonSelectOption>
          <IonSelectOption value="l_1.s_2022">CDM 2022</IonSelectOption>
        </IonSelect>
        <IonButtons slot="end">
          <IonMenuButton />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default LargeHeader;
