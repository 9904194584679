import React from "react";
import { IonPage, IonContent } from "@ionic/react";

import LargeHeader from "../../components/Header/LargeHeader";
import TeamsList from "../../components/Team/TeamsList";
import { useTranslation } from "react-i18next";

const Teams = (props) => {
  const { t } = useTranslation();

  const [league, setLeague] = React.useState("l_4.s_2024");

  function handleChangeLeague(league) {
    setLeague(league);
  }



  return (
    <IonPage>
      <LargeHeader actionLeague={handleChangeLeague} title={t('Teams')} backButton={false} />
      <IonContent fullscreen>

        <TeamsList league={league} />


      </IonContent>
      
    </IonPage>
  );
};

export default Teams;
