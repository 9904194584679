import React, { useEffect, useState } from 'react';
import {
  IonBadge,
  IonLabel,
  IonIcon,
  IonText,
  IonItem,
  IonList,
  //IonAccordion, 
  //IonAccordionGroup,
} from "@ionic/react";
import {
  chevronUpCircleOutline,
  personCircleOutline,
  timeOutline,
} from "ionicons/icons";

import { useTranslation } from "react-i18next";


const MatchBookmakers = ({ match_id }) => {

  const { t } = useTranslation();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`https://api-football-v1.p.rapidapi.com/v3/odds?fixture=`+match_id,{
      headers: {
        'X-RapidAPI-Key': "38169f944amsh07c614143888d4fp169308jsn693fe69826d7",
        'X-RapidAPI-Host': "api-football-v1.p.rapidapi.com/v3"        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
        .then(res => res.json())
        .then(result => {
          console.log(result)
          if(result.response && result.response[0]) setData(result.response[0].bookmakers);
        });
  }, [match_id]);

let category = "";
let categoryDivider = null;

const categoryOrder = {
  "GENERAL": 0,
  "GOALKEEPING": 1,
  "DEFENDING": 2,
  "POSSESSION": 3,
  "PASSING": 4,
  "ATTACKING": 5,
  "UNKNOWN": 6,
          
};



  return (

        <>

{data && <IonList lines={"full"} style={{
     //paddingTop: 0
   }}>
{
//.filter((d) => d.totalScore )
//.sort((a, b) => categoryOrder[a.category] > categoryOrder[b.category]  ? 1 : -1)

data
.map((bookmaker, index) => {

{/* if(score.category !== category) categoryDivider = <ion-item-divider>
      <ion-label>
       {t(score.category)}
      </ion-label>
    </ion-item-divider>; else categoryDivider = null;

category = score.category; */}

return bookmaker.bets
.filter((d) => d.name === "Match Winner" )
.map((bet, index) => {

return <IonItem key={index}>
          
            
          <IonLabel>
            {t(bookmaker.name)}
          </IonLabel>

          {bet.values
.map((value, index) => {

return <IonLabel key={index} className="ion-text-center">
            {t(value.odd)}
          </IonLabel>
    

})}
    

        </IonItem>
})
})


}

<IonItem>
          
            
          <IonLabel>
            {t("score.stat")}
          </IonLabel>

                
                
                {data.score ? <IonBadge className="square" slot="end"
                style={{
                    marginInlineEnd: "12px"

                  }}>{data.score}</IonBadge> : null }
                  
                  

        </IonItem>

</IonList>}


          <IonItem>
          
            
            <IonLabel>
              ok
            </IonLabel>

  
          </IonItem>

      

        </>

  );
};

export default MatchBookmakers;
