import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
  IonAvatar,
} from "@ionic/react";

const MatchHeader2 = ({ teamHome, teamVisitor, goalHome, goalVisitor, logoHome, logoVisitor }) => {
  const elementRef = React.useRef();

  /* React.useEffect(() => {
    const divElement = elementRef.current;
    divElement.style.setProperty('--background', color);
    divElement.style.setProperty('--color', color_text);
  }, [team]); */

  return (
    <IonHeader>
      <IonToolbar color="local-team" className="match-toolbar-left">

      
          
      
        <IonButtons slot="start">
      
        
        <IonAvatar style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "8px",
                    padding: "2px",
                    backgroundColor: "#fff"
                  }}>
              <img alt="o" src={teamHome.logo} />
            </IonAvatar>
        </IonButtons>
        
        <IonTitle slot="end" style={{
                    paddingInline: "12px",
                   
                  }}>{teamHome.code}</IonTitle>
        <IonButton className="positiondd" color={"light"} slot="end" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      marginRight: "15px",
    }}>{goalHome}</IonButton>

      </IonToolbar>
      <IonToolbar color="visitor-team" className="match-toolbar-right">
      
      <IonButton className="positiondd" color={"light"} slot="start" style={{
      //minHeight: "1200px",
      //paddingBottom: "600px",
      marginLeft: "15px",
    }}>{goalVisitor}</IonButton>

        <IonTitle style={{
                    paddingInline: "12px",
                   
                  }}
        >{teamVisitor.code}</IonTitle>

        <IonButtons slot="end">
        
        <IonAvatar style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "8px",
                    padding: "2px",
                    backgroundColor: "#fff"
                  }}>
              <img alt="o" src={teamVisitor.logo} />
            </IonAvatar>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default MatchHeader2;
