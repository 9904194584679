import React from "react";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonButton,
  IonRouterLink,
  IonLoading,
  IonIcon,
  useIonToast
} from "@ionic/react";
import NavHeader from "../../components/Header/NavHeader";
import validateLogin from "../../validators/validateLogin";
import useForm from "../../hooks/useForm";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";


const INITIAL_STATE = {
  email: "",
  password: "",
};

const Login = (props) => {

  const { t } = useTranslation();

  const { handleSubmit, handleChange, values, isSubmitting } = useForm(
    INITIAL_STATE,
    validateLogin,
    authenticateUser
  );
  const [busy, setBusy] = React.useState(false);

  const [present] = useIonToast();

  

  const toast = (message) => {
    present({
      message: message,
      duration: 1500,
      position: 'bottom',
      color: 'secondary'
    });
  };

  async function authenticateUser() {
    setBusy(true);
    const { email, password } = values;
    try {
      await firebase.login(email, password);
      toast("Bienvenue!");
      props.history.push("/");
    } catch (err) {
      console.error("Authentication Error", err);
      toast(err.message);
    }
    setBusy(false);
  }



  return (
    <IonPage>
      <NavHeader title={t("Login")} />
      <IonLoading message={"Please wait..."} isOpen={busy} />
      <IonContent>

        <IonItem lines="full">
          <IonLabel position="floating">{t("Email")}</IonLabel>
          <IonInput
            name="email"
            type="text"
            value={values.email}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>
        <IonItem lines="full">
          <IonLabel position="floating">{t("Password")}</IonLabel>
          <IonInput
            name="password"
            type="password"
            value={values.password}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>
        <IonRow>
          <IonCol>
            <IonButton
              type="submit"
              color="primary"
              expand="block"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {t("Login")}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol class="ion-text-center ion-padding-vertical">
            <IonRouterLink routerLink={"/forgot"}>
              {t("Forgot_Password")}
            </IonRouterLink>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Login;
