import React from "react";
import UserContext from "../../contexts/UserContext";
import useForm from "../../hooks/useForm";
import {
  IonPage,
  IonLoading,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
  useIonToast
} from "@ionic/react";
import NavHeader from "../../components/Header/NavHeader";
import firebase from "../../firebase";
import validateEditProfile from "../../validators/validateEditProfile";

import { useTranslation } from 'react-i18next';


const EditProfile = (props) => {
  const { t, i18n } = useTranslation();

  const { user, setUser } = React.useContext(UserContext);
  const INITIAL_STATE = {
    name: user && user.displayName,
    email: user && user.email,
    newPassword: "",
    currentPassword: "",
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    isSubmitting,
  } = useForm(INITIAL_STATE, validateEditProfile, authenticateuser);
  const [busy, setBusy] = React.useState(false);

  const [psw, setPsw] = React.useState(false);

  const [present] = useIonToast();

  const toast = (message) => {
    present({
      message: message,
      duration: 1500,
      position: 'bottom',
      color: 'secondary'
    });
  }

  async function reauthenticate(email, password) {
    const credential = firebase.app.auth.EmailAuthProvider.credential(
      email,
      password
    );
    try {
      await user.reauthenticateWithCredential(credential);
      console.log("Reauthentication Successful");
    } catch (err) {
      console.error("Profile Update Error", err);
      toast(err.message);
    }
  }

  async function updateProfileItems(name, email, password) {
    await user.updateProfile({
      displayName: name,
    });
    if (email) await user.updateEmail(email);
    if (password) {
      await user.updatePassword(password);
    }
  }

  async function authenticateuser() {
    setBusy(true);
    const { name, email, currentPassword, newPassword } = values;
    try {
      //await reauthenticate(user.email, currentPassword);
      await updateProfileItems(name, email, newPassword);
      /* const result = await firebase.login(
        email,
        newPassword || currentPassword
      ); */
      setValues({
        name: user && user.displayName,
        email: user && user.email,
        newPassword: "",
        currentPassword: "",
      });
      //setUser(result.user);
      toast(t("You have updated your profile successfully."));
      props.history.push("/profile");
    } catch (err) {
      console.error("Profile Update Error", err);
      toast(err.message);
    }
    setBusy(false);
  }

  const handleSelectLang = (vote) => {

    i18n.changeLanguage(vote.target.value);

  }

  return (
    <IonPage>
      <NavHeader title={t("Edit Profile")}/>
      <IonLoading message={"Please wait..."} isOpen={busy} />
      <IonContent>
        <IonItem lines="full">
          <IonLabel position="floating">{t('Pseudo')}</IonLabel>
          <IonInput
            name="name"
            type="text"
            value={values.name}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>

        <IonItem lines="full">
          <IonLabel position="floating">{t('Email')}</IonLabel>
          <IonInput
            name="email"
            type="text"
            value={values.email}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>

        <IonItem>
              <IonLabel className="ion-text-wrap">
                {t('Langue')}
              </IonLabel>
              <IonSelect color="mvp" mode="ios" interface="action-sheet" okText="Ok" cancelText={t('Cancel')} 
                onIonChange={(e) => handleSelectLang(e)}
                //onIonFocus={(e) => {console.log(e)}}
                
                >
                  <IonSelectOption value="en-US">English</IonSelectOption>
                  <IonSelectOption value="fr-FR">Francais</IonSelectOption>
          
                </IonSelect>
            </IonItem>

        {psw && <><IonItem lines="full">
          <IonLabel position="floating">{t('New Password')}</IonLabel>
          <IonInput
            name="newPassword"
            type="password"
            value={values.newPassword}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>

        <IonItem lines="full">
          <IonLabel position="floating">{t('Current Password')}</IonLabel>
          <IonInput
            name="currentPassword"
            type="password"
            value={values.currentPassword}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem></>}

        <IonRow>
          <IonCol>
            <IonButton
              type="submit"
              color="primary"
              expand="block"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {t('Save')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
