import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/analytics";

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
//import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';

import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  PhoneAuthProvider,
  signInWithRedirect,
  signInWithCredential,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  signInAnonymously
} from 'firebase/auth';


import { initializeApp } from "firebase/app"

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getDatabase } from "firebase/database";

import { getAnalytics, logEvent } from "firebase/analytics";


import firebaseConfig from "./config";

class Firebase {
  constructor() {
    //this.analytics = getAnalytics();

    //const firebaseApp = initializeApp(firebaseConfig);
    //const analytics = 

   /*  getAuth() = getAuth(firebaseApp);
    //this.db = firebase.firestore();
    this.db = getFirestore(firebaseApp);

    this.fb = getDatabase(firebaseApp);

    this.analytics = getAnalytics();

    console.log("db") */

    //this.log('home', 0);
    //this.setContext();
    //this.crash();
  }

  async log(page, id) {

    logEvent(getAnalytics(), page, {
      id: id, 
    });

    /* this.analytics.logEvent(this.analytics, 'select_content', {
      content_type: 'image',
      content_id: 'P12453',
      items: [{ name: 'Kittens' }]
    }); */
  }

 /*  crash = async () => {
    await FirebaseCrashlytics.crash({ message: 'Test' });
  };

  setContext = async () => {
    await FirebaseCrashlytics.setContext({ 
      key: 'page',
      value: 'home',
      type: 'string'
    });
  }; */

  async register(name, email, password) {
    const newUser = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password
    );
    /* return newUser.user.updateProfile({
      displayName: name,
    }); */
  }

  login(email, password) {
    return signInWithEmailAndPassword(getAuth(), email, password);
  }

  loginAnonymously() {
    return signInAnonymously(getAuth());
  }

  async loginTwitter() {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithTwitter()
    /* if (!result.credential?.idToken || !result.credential?.secret) {
      throw new Error('Twitter error')
    }
    // 2. Create credentials on the web layer
    const credential = TwitterAuthProvider.credential(
      result.credential?.idToken,
      result.credential?.secret
    )
    // 3. Sign in on the web layer using the id token
    await signInWithCredential(getAuth(), credential) */
  }

  async loginTwitterk() {
    var provider = new TwitterAuthProvider();
    const newUser = await signInWithRedirect(getAuth(), provider)
    //.signInWithPopup(provider)
    .then((result) => {
      var credential = result.credential;
  
      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
      // You can use these server side with your app's credentials to access the Twitter API.
      var token = credential.accessToken;
      var secret = credential.secret;
  
      // The signed-in user info.
      var user = result.user;



      /* return newUser.user.updateProfile({
        displayName: user.name,
        role: 'admin',
      }); */

      //console.log(user)

      //return user;
      // ...
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;

      console.log(error, errorMessage)


      //return false
      // ...
    });

    /* return newUser.user.updateProfile({
      displayName: 'name',
    }); */


    
  }

  

  logout() {
    return signOut(getAuth());
  }

  resetPassword(email) {
    return sendPasswordResetEmail(getAuth(), email);
  }
}

const firebaseA = new Firebase();
export default firebaseA;
