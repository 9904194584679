export default function validateUrl(values) {
  let errors = {};

  //Password Errors
  if (!values.url) {
    errors.url = "An url is required.";
  }

  return errors;
}
