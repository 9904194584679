import React, { useRef } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonBadge,
  IonIcon,
  IonRow, IonCol,IonAvatar,
  IonSelectOption, IonSelect,
  IonText
} from "@ionic/react";

import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { ref, onValue} from "firebase/database";


import SorareScoreDetailed from "../Match/SorareScoreDetailed";
import MatchPosition from "../Match/MatchPosition";



import { Browser } from '@capacitor/browser';

import { useTranslation } from "react-i18next";

import {
  personCircleOutline,
  arrowBackOutline,
  arrowUpOutline,
  closeOutline,
  globe,

  ribbon,
  paw,
  returnUpForward,
  returnUpBack,
  football,
  tabletPortrait,
} from "ionicons/icons";

import providerList from "../../helpers/provider";


const PlayerMatchItem = ({ isOpen, teamHome, sendAction, closeAction, comment, data, myVote, sendNote, medias, closeNote, showNoteSelect, isComponent }) => {

  const { t } = useTranslation();

  const [showNotesStat, setShowNotesStat] = React.useState(false);
  const [showNotesMedias, setShowNotesMedias] = React.useState(false);
  const [showNotesAverage, setShowNotesAverage] = React.useState(true);
  const [showStatsSorare, setShowStatsSorare] = React.useState(false);
  const [player, setPlayer] = React.useState(data);



  //const [notesMedias, setNotesMedias] = React.useState([]);

  const modal = useRef(null);
  const elementRefToolbar = React.useRef();

/*   React.useEffect(() => {
    elementRefToolbar.current.style.setProperty('--background', data.player.color);
    elementRefToolbar.current.style.setProperty('--color', data.player.color_text);
  }, [data]); */

  const [commentText, setCommentText] = React.useState(
    comment ? comment.text : ""
  );

/*   React.useEffect(() => {
    //data && getPlayer();

    //setPlayer(data);

    // eslint-disable-next-line
  }, [data]); 


  React.useEffect(() => {
    
    //!isOpen && starCountRef.off();

  }, [isOpen]);*/


  

  async function openBrowser(url) {
    //await window.open(url);

    await Browser.open({
      url,
    });
  }

  function voteToColor(vote) {
    if(vote < 1) return "0";
    else if(vote < 2) return "1";
    else if(vote < 3) return "2";
    else if(vote < 4) return "3";
    else if(vote < 5) return "4";
    else if(vote < 6) return "5";
    else if(vote < 7) return "6";
    else if(vote < 8) return "7";
    else if(vote < 9) return "8";
    else if(vote < 10) return "9";
    else if(vote >= 10) return "10";
  }

  function mediaText(media) {
    if(media < 1) return "0";

  }

  function handleShowNotesAverage() {
    //modal.setCurrentBreakpoint(0.75);
    modal.current?.setCurrentBreakpoint(0.3)
    setShowNotesMedias(false);
    setShowNotesAverage(true);
    setShowNotesStat(false);
    setShowStatsSorare(false);

  }

  function handleShowStatsSorare() {
    //modal.setCurrentBreakpoint(0.75);
    modal.current?.setCurrentBreakpoint(1)
    setShowNotesMedias(false);
    setShowNotesAverage(false);
    setShowNotesStat(false);
    setShowStatsSorare(true);
  }

  

  function handleShowNotesStat() {
  //modal.setCurrentBreakpoint(0.75);
  modal.current?.setCurrentBreakpoint(0.75)
  setShowNotesStat(!showNotesStat);
  setShowNotesMedias(false);
  setShowNotesAverage(false);
  }

  function handleShowNotesMedias() {
    //modal.setCurrentBreakpoint(0.75);
    modal.current?.setCurrentBreakpoint(0.75)
    
    //setShowNotesStat(!showNotesStat);
    setShowNotesMedias(true);
    setShowNotesAverage(false);
    setShowStatsSorare(false);
    setShowNotesStat(false);
  
    }

    function handleShowFull() {
      //modal.setCurrentBreakpoint(0.75);
      if(modal.current?.currentBreakpoint === 1) {
        handleShowStatsSorare();
        return;
      }
      modal.current?.setCurrentBreakpoint(1)
      setShowNotesMedias(true);
      setShowNotesAverage(false);
      setShowNotesStat(false);
      setShowStatsSorare(false);
    }

    function showNote(player) {
      var note = 0;
      var totalNote = 0;
      var nbNote = 0;
      if(player.usersRating && (player.usersRating.averageRating || player.usersRating.averageRating === 0)) {
        totalNote = totalNote + player.usersRating.averageRating;
        nbNote++;
      }
      if(player.mediasRating && (player.mediasRating.averageRating || player.mediasRating.averageRating === 0)) {
        totalNote = totalNote + player.mediasRating.averageRating;
        nbNote++;
      }
      if(player.stats && (player.stats["sorare"] || player.stats["sorare"].note ===0 )) {
        totalNote = totalNote + player.stats["sorare"].note;
        nbNote++;
      }

      if(nbNote === 0) return "-";

      if((totalNote / nbNote) >= 10) note = 10; else note = (totalNote / nbNote).toFixed(1);  

      return note;
  
    }

    function matchLeague(t, league) {

      let name = league.name;
      let round = league.round;
      let stage = "";
      let journee = "";
      let day = 0;
    
      if(name === "World Cup") {
    
          stage = "group";
          if(league.round.includes("Round of")) stage = "round";
          if(round === "Quarter-finals") stage = "round";
    
          if(stage === "round") {
            round = league.round.replace("Round of ", "");
    
            if(round === "16") round = 8;
            if(round === "Quarter-finals") round = 4;
          
            journee = t(round+'final');
    
            //return t('World Cup') + " - " + journee;
            return journee;
          }
            
          if(stage === "group") {
            round = league.round.replace("Group Stage -", "");
            day = round.slice(round.length-1, round.length);
    
            if(day === "1") journee = t('st');
            else if(day === "2") journee = t('nd');
            else if(day === "3") journee = t('rd');
            else journee = t('th');
    
            //return t('World Cup') + " - " + round + journee + " " + t('day');
            return round + journee + " " + t('day');
          }
        
      } else if(name === "UEFA Champions League") {
    
        round = league.round.replace("Group", "");
    
        journee = round.slice(round.length-1, round.length) === "1" ? "ère" : "ème";    
        return "Champions League - " + t('Group') + " " + round + journee + " journée";
      
      } else if(name === "Ligue 1") {
          round = league.round.replace("Regular Season - ", "");
        
          return name + " - " + round + "éme journée";
        //return data.fixture.status.short+"m"
      } else if(name === "UEFA Nations League") {
        round = league.round.replace("League", "Ligue");
        journee = round.slice(round.length-1, round.length) === "1" ? "ère" : "ème";    
        return "Ligue des nations - " + round + journee + " journée";
      
      //return data.fixture.status.short+"m"
      } else {
        return name + " - " + round;
      }
    
    }

    

    const customAlertOptions = {
      header: player && player.player.player_name,
      subHeader: t('Your_rating_of_his_match'),
      //message: 'Choose only one',
      translucent: false
    };


  return (
    <>
        <IonToolbar ref={elementRefToolbar}>

          
      

          <IonButtons slot="start">


                {player && player.formation && player.formation[0] && data && data.player.color && <MatchPosition marginLeft= {"12px"} position={t(player.formation[0].name)} background={data.player.color} color={data.player.color_text} />}

          
          </IonButtons> 

          <IonTitle style={{
                    //paddingInline: "12px",
                    //paddingInline: !isComponent ? "12px" : 0,
                    textAlign: "center",

                    fontSize: "16px",
                  }}>{matchLeague(t, data.league)}</IonTitle>

{showNote(player) >=0 && <IonButton slot="end" onClick={handleShowNotesAverage} color={voteToColor(showNote(player))} className="square-end" >{showNote(player)}</IonButton>}
          {showNote(player) === -1 && <IonButton slot="end" onClick={handleShowNotesAverage} color={"light"} className="square-end" >-</IonButton>}
          
          {/* <IonButtons slot="end">
            <IonButton onClick={() => handleSendAction(commentText)}>
              Send
            </IonButton>
          </IonButtons> */}
        </IonToolbar>

      {showStatsSorare && <>
        <SorareScoreDetailed template="normal" data={player.sorare} />

        <IonItem>
            
          <IonLabel>
          <IonButton
              onClick={() => handleShowNotesMedias()}                
            >{t("Back")}</IonButton>
          </IonLabel>


        </IonItem>
      </> }

              {/* <IonToolbar color="primary">

    
          <IonTitle>{t('Medias')}</IonTitle>
      
          
        {player.mediasRating && <IonBadge color={voteToColor(player.mediasRating.averageRating)} slot="end" className="position">{player.mediasRating.averageRating.toFixed(1)}</IonBadge>}

        
        </IonToolbar> */}
        {(showNotesAverage || showNotesMedias) && <IonRow>
        <IonCol style={{
     paddingLeft: 0,
     paddingRight: 0,
     paddingTop: 0,
     paddingBottom: 0,
   }}>







<IonList lines={"none"} style={{
     //paddingTop: 0
   }}>



{player.events && player.events.Goal && player.events.Goal.length > 0 && <IonItem lines='none'>
<IonLabel class="ion-text-wrap">

{player.events.Goal.length === 1 ? <>1 {t('goal')}</> : <>{player.events.Goal.length} {t('goals')} </>}

</IonLabel>

<IonIcon 
                  icon={football}
                  color={"mvp"}
                  slot={"start"}
                  style={{marginInlineEnd: "12px"}}

                />
</IonItem>
}


{player.events && player.events.Assist && player.events.Assist.length > 0 && <IonItem lines='none'>
<IonLabel class="ion-text-wrap">

{player.events.Assist.length === 1 ? <>1 {t('assist')}</> : <>{player.events.Assist.length} {t('assists')}</>}

</IonLabel>

<IonIcon 
                  icon={paw}
                  color={"mvp"}
                  slot={"start"}
style={{marginInlineEnd: "12px"}}

                />
</IonItem>
}

{/* {player.events && player.events.Var && player.events.Var.length > 0 && <IonItem>
<IonLabel class="ion-text-wrap">

{player.events.Var.length === 1 ? <>1 {t('Var')} {t('at')} </> : <>{player.events.Var.length} {t('Var')} {t('at')} </>}

{player.events.Var
.map((goal, index) => {
return <span key={index}>{index!==0 && ", "}{goal.time.elapsed}m</span>
})

}</IonLabel>

<IonIcon 
                  icon={paw}
                  color={"mvp"}
                  slot={"end"}
                />
</IonItem>
}
 */}






















{player.events && player.events.Card && player.events.Card
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((card, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
if(card.detail === 'Yellow Card')
 
return <IonItem lines='none' key={index}>

<IonLabel class="ion-text-wrap">{t('Yellow_card')}</IonLabel>

<IonIcon key={index}
                  icon={tabletPortrait}
                  color={"jaune"}
                  slot={"start"}
style={{marginInlineEnd: "12px"}}

                  
                /></IonItem>

if(card.detail === 'Second Yellow card')
 
return <IonItem lines='none' key={index}>

<IonLabel class="ion-text-wrap">{t('Red_card')}</IonLabel>

<IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  slot={"start"}
style={{marginInlineEnd: "12px"}}

                  
                /></IonItem>

if(card.detail === 'Red Card')
 
return <IonItem key={index}>

<IonLabel class="ion-text-wrap">{t('Red_card')}</IonLabel>

<IonIcon key={index}
                  icon={tabletPortrait}
                  color={"rouge"}
                  slot={"start"}
style={{marginInlineEnd: "12px"}}
                  
                /></IonItem>

})


}  








{player.events && player.events.subst && player.events.subst
//.filter((d) => d.team_id === (voteFor === 'home' ? match.localteam_id : match.visitorteam_id) )
.map((event, index) => {
//return <IonBadge key={index} className="square">ok</IonBadge>
return <IonItem lines='none' key={index}>

{event.player.id !== player.player.id ? 
<IonLabel class="ion-text-wrap">{t('Substitute')} {t('at')} {event.time.elapsed}m</IonLabel> :
<IonLabel class="ion-text-wrap">{t('Out_at')} {event.time.elapsed}m</IonLabel>
}
<IonIcon
icon={event.player.id === player.player.id ? returnUpBack : returnUpForward}
color={event.player.id === player.player.id ? "danger" : "success"}
slot={"start"}
style={{marginInlineEnd: "12px"}}

/></IonItem>
})


}  







</IonList>

{/* {player.sorare && <SorareScoreDetailed onClick={handleShowFull} template="small" col="12" data={player.sorare} />}
 */}

   </IonCol>


        <IonCol style={{
     paddingLeft: 0,
     paddingRight: 0,
     paddingTop: 0,
     paddingBottom: 0,
   }}>
        <IonList lines={"none"} style={{
     paddingBottom: 0
   }}>

<IonItem button={true} onClick={handleShowStatsSorare}>
 
 <IonLabel>{t('Statistiques')}</IonLabel>
            

<IonText
   size={"small"}
   style={{
     verticalAlign: "middle",
     paddingRight: "10px",
     color: "#777777",
     fontSize: "14px",

   }}
 >{t('Score_Sorare')}
 </IonText> 
 

 {player.stats && player.stats.sorare ? <IonBadge className="square" color={voteToColor(player.stats.sorare.note)}>{player.stats.sorare.note >= 10 ? 10 : player.stats.sorare.note.toFixed(1)}</IonBadge> : <IonBadge className="square" color={"light"}>-</IonBadge>}


 
</IonItem>
   
   {player && player.mediasRating && player.mediasRating.totalUsersCount !== 0 && <IonItem button={true} onClick={handleShowNotesMedias}>
             
                <IonLabel>{t('Medias')}</IonLabel>
                           
                


                <IonText style={{
                    color: "#777777",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {(player.mediasRating && player.mediasRating.totalUsersCount) || 0}
                </IonText>
                <IonIcon
                  icon={globe}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />{" "}
                </IonText>
                

                {player.mediasRating && <IonBadge className="square" color={voteToColor(player.mediasRating.averageRating)}>{player.mediasRating.averageRating >= 10 ? 10 : player.mediasRating.averageRating.toFixed(1)}</IonBadge>}
                {!player.mediasRating && <IonBadge className="square" color={"light"}>-</IonBadge>}


                
              </IonItem>}
            
        {data && player.medias && showNotesMedias && Object.keys(player.medias)
      .sort((a, b) => player.medias[a].note < player.medias[b].note ? 1 : -1)
      .map((note, index) => {

        const media = player.medias[note].media;

        return <IonItem 
        key={index} 
        button={medias && medias[media]}
        onClick={() => medias && medias[media] && openBrowser(medias[media])}

        >

        <IonLabel>{providerList[media] ? providerList[media].name : media}</IonLabel>


        {note === "sorare" ? <IonBadge color={voteToColor(player.medias[note].note)} slot="end" className="position3">{player.medias[note].note.toFixed(1)}</IonBadge> : <IonBadge color={voteToColor(player.medias[note].note)} slot="end" className="position3">{player.medias[note].note}</IonBadge>}

        </IonItem>
      })}
      

{player && player.usersRating && player.usersRating.totalUsersCount !== 0 && <IonItem button={true} onClick={handleShowNotesStat}>
 
 <IonLabel>{t('Spectators')}</IonLabel>
            

 <IonText style={{
     color: "#777777",
     fontSize: "14px",
   }}><IonText
   size={"small"}
   style={{
     verticalAlign: "middle",
     paddingRight: "2px",
   }}
 >
 {player.usersRating ? player.usersRating.totalUsersCount : 0}
 </IonText>
 <IonIcon
   icon={personCircleOutline}
   style={{
     verticalAlign: "middle",
     paddingRight: "10px",
   }}
 />{" "}
 </IonText>
 

 {player.usersRating && player.usersRating.averageRating >=0 && <IonBadge className="square" color={voteToColor(player.usersRating.averageRating)}>{player.usersRating.averageRating >= 10 ? 10 : player.usersRating.averageRating.toFixed(1)}</IonBadge>}
 {!player.usersRating && <IonBadge className="square" color={"light"}>-</IonBadge>}


 
</IonItem>}
</IonList>








{/* {(showNoteSelect && !showNotesAverage) && <IonList lines={"none"} style={{
     paddingTop: 0
   }}><IonItem>

<IonLabel>{t('Myrating')}</IonLabel>

{myVote && myVote[player.player_id] ? <IonBadge color={voteToColor(myVote[player.player_id].note)} slot="end" className="position3">{myVote[player.player_id].note}</IonBadge> : <IonBadge className="square" color={"light"}>-</IonBadge>}



</IonItem></IonList>} */}
        </IonCol>
      </IonRow>}

      {data && player.usersRating && player.usersRating.totalNotes && showNotesStat && <>
      
      {Object.keys(player.usersRating.totalNotes).map((note, index) => (
          <IonItem key={index} className="ion-text-center">

            <IonBadge color={note} slot="start" className="position">{note}</IonBadge>
            
            <IonProgressBar slot="end" color="primary" value={(player.usersRating.totalNotes[note] / player.usersRating.totalUsersCount)}></IonProgressBar>
            
            <IonText slot="end" style={{
                    color: "#777777",
                    width: "70px",
                    fontSize: "14px",
                  }}><IonText
                  size={"small"}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "2px",
                  }}
                >
                {player.usersRating.totalNotes[note]}
                </IonText>
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                    paddingRight: "10px",
                  }}
                />
                </IonText>
          </IonItem>
        ))}
        <IonItem>
          
            
            <IonLabel>
            <IonButton
                onClick={() => handleShowNotesMedias()}                
              >{t("Back")}</IonButton>
            </IonLabel>

  
          </IonItem></>}
        

      
      

          {isComponent && player && !showNotesStat && !showStatsSorare && <img  alt="o" src={player.img}  style={{
                    zIndex: "1000",
                    position: "absolute",
                    bottom: "0px",
                    width: "300px",
                    right: "10px",
                  }} />}

      </>

    
  );
};

export default PlayerMatchItem;
